import React, { useEffect, useRef, useState, useCallback } from "react";
import "./CataloguePhoto.scss";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import _http from "../../../../Utils/Api/_http";
import SnackBar from "../../../../Components/SnackBar";
import { Empty, Tooltip } from "antd";
import QuizShare from "../Quiz/QuizSharePopup";
import share from "./../../../../Assets/Quiz/Share.svg";
import download from "../../../../Assets/Quiz/Download.svg";
import searchIcon from "./../../../../Assets/UserIcon/usersearchicon.svg";
import upload from "./../../../../Assets/Quiz/upload.svg";
import VideoIcon from "./../../../../Assets/Quiz/QreCat&Photo/Video.svg";
import leftarrow from "./../../../../Assets/Quiz/QreCat&Photo/cherrot-down.svg";
import FileDelete from "./../../../../Assets/Quiz/QreCat&Photo/FileDelete.svg";
import Close from "./../../../../Assets/Quiz/QreCat&Photo/Close.svg";
import Img from "./../../../../Assets/Quiz/QreCat&Photo/Img.svg";
import LinksIcon from "./../../../../Assets/Quiz/QreCat&Photo/Link.svg";
import addIcon from "./../../../../Assets/Quiz/Add Icon.svg";
import FolderIcon from "./../../../../Assets/Quiz/QreCat&Photo/FolderIcon.svg";
import darkLink from "./../../../../Assets/Quiz/QreCat&Photo/darkLink.svg";
import back_icon from "./../../../../Assets/Quiz/QreCat&Photo/back_icon.svg";
import Edit from "./../../../../Assets/Quiz/QreCat&Photo/Edit.svg";
import CataloguePhotoPopup from "./CataloguePhotoPopup";

export default function CataloguePhoto() {
  const navigate = useNavigate();
  const { section } = useParams();
  const [dataUrl, setDataUrl] = useState();
  const [errors, setError] = useState("");
  const [toast, setToast] = useState({ submit: false, error: false });
  const [qrcode, setQrcode] = useState(false);
  const fileRef = useRef(null);
  const [qrpng, setQrPng] = useState(null);
  const [Value, setValue] = useState();
  const [externalUrl, setExternalUrl] = useState();
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [File, setFiles] = useState();
  const [searchValue, SetSearchValue] = useState("");
  const [apidata, setApidata] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null); // State to track the selected item
  const containerRef = useRef(null); // Ref to the container
  const [MediaLinks, setMediaLinks] = useState();
  const [FolderLinks, setFolderLinks] = useState();
  const [linkUrl, setLinkURl] = useState(false);
  const [Folders, setFolders] = useState(false);
  const [createFolder, setCreateFolder] = useState();
  const [isEditing, setIsEditing] = useState(null);
  const [editText, setEditText] = useState("");
  const [FolderName, setFolderName] = useState("");
  const [oldFolderValue, setOldFolderValue] = useState("");
  const [isFolder, setIsFolder] = useState(false);
  const [isaFolder, setIsaFolder] = useState(false);
  const [NewFolderName, setNewFolderName] = useState("");

  const handleSelect = (index, url, fdnm) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
      setActive(false);
    } else {
      setQrcode(false);
      setActive(true);
      setSelectedIndex(index); // Update the selected index
      if (url) {
        setExternalUrl(url);
        setLinkURl(true);
      } else {
        setExternalUrl("");
        setLinkURl(false);
      }
      if (fdnm !== undefined) {
        setIsaFolder(true);
        setExternalUrl("");
        setLinkURl(false);
        return;
      } else {
        setIsaFolder(false);
      }
    }
  };
  const handleClickOutside = (event) => {
    // Check if the click is outside the container
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSelectedIndex(null); // Reset the selected index
      setActive(false);
      // saveFolderHandler();
    }
  };
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const navigateHandler = (section) => {
    navigate(`/SpiraQRE/${section}`);
    setFolders(false);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setOpen(true);
    const files = e.dataTransfer.files;
    setFiles(files);
  };
  const handleFileUpload = () => {
    fileRef.current.value = "";
    fileRef.current.click();
  };
  const Link_to_File = async (link) => {
    try {
      const formdata = new FormData();
      formdata.append("online_video", link);
      await _http.post("/quiz/uploadVideoS3", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setValue("");
      setToast({ submit: true });
      setError("Submitted ✅");
      FileStoragedData();
    } catch (err) {
      setToast({ error: true });
      setError("Not Submitted ❌");
    }
  };
  const LinkConvertorQr = async (val) => {
    try {
      const response = await _http.post(
        "/quiz/OnlineLink_to_QR",
        {
          links: val,
        },
        { responseType: "blob" }
      );
      const url = URL.createObjectURL(response.data);
      setQrPng(url);
      setQrcode(true);
      setValue("");
      setLinkURl(false);
      setTimeout(() => {
        setToast({ submit: true });
        setError("Qr Code Generated ✅");
      }, 1000);
    } catch (er) {
      setToast({ error: true });
      setError("Qr Generation Failed ⚠");
    }
  };
  const UploadFilesConvertorQR = async (urls) => {
    try {
      const response = await _http.post("/quiz/uploadLink_to_QR", {
        files_names: urls,
      });
      // const qrurl = URL.createObjectURL(response.data);
      // setQrPng(qrurl);
      // setQrcode(true);
      const { image, url } = response.data;
      setDataUrl(url);
      console.log(image);
      console.log(url);
      let base64String = image; // Your Base64 string
      let imageType = "png"; // Or "jpeg"

      let dataUrl = `data:image/${imageType};base64,${base64String}`;
      console.log(dataUrl);

      setQrPng(dataUrl);
      setQrcode(true);
    } catch (err) {
      setToast({ error: true });
      setError("Error Generating Qr ⚠");
    }
  };

  function handleSubmit() {
    if (isaFolder) {
      UploadFilesConvertorQR([FolderLinks]);
    } else {
      if (!linkUrl) {
        UploadFilesConvertorQR(MediaLinks);
      } else {
        LinkConvertorQr(externalUrl);
      }
    }
  }
  const uploadMedia = async (files) => {
    if (!files || files.length === 0) {
      setToast({ error: true });
      setError("Please select a file");
      setLoading(false);
      return; // Exit if no files are selected
    }

    const formData = new FormData();
    const endpoint =
      section === "catalogue" ? "/quiz/uploadVideoS3" : "/quiz/uploadImageS3";
    const fileKey = section === "catalogue" ? "video" : "image";
    formData.append(fileKey, files[0]); // Append the current file
    setLoading(true);
    try {
      await _http.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setToast({ submit: true });
      setError(
        `${
          section === "catalogue"
            ? "Video Uploaded Successfully ✅"
            : "Photo Uploaded Successfully ✅"
        }`
      ); // Clear any previous error messages
      FileStoragedData(); // Refresh the file list
      setFiles(null);
      // Close the modal and reset the state after all uploads
      handleClose();
    } catch (err) {
      setToast({ error: true });
      setError(`Error uploading Files⚠`);
      // Close the modal and reset the state after all uploads
      handleClose();
    }

    setLoading(false); // Ensure loading state is reset
  };

  const folderCreation = async (files) => {
    if (!files || files.length === 0) {
      setToast({ error: true });
      setError("Please select a file");
      setLoading(false);
      return; // Exit if no files are selected
    }
    for (let i = 0; i < files.length; i++) {
      // Loop through each file and upload it
      const formData = new FormData();
      const endpoint =
        section === "catalogue" ? "/quiz/uploadVideoS3" : "/quiz/uploadImageS3";
      formData.append("foldername", FolderName); // Append the current file
      const fileData = section === "catalogue" ? "video" : "image";
      formData.append(fileData, files[i]);
      setLoading(true);
      try {
        await _http.post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setLoading(false);
        setToast({ submit: true });
        setError(
          `${
            section === "catalogue"
              ? "Video Uploaded Successfully ✅"
              : "Photo Uploaded Successfully ✅"
          }`
        ); // Clear any previous error messages
        setTimeout(() => {
          setFolders(false);
          FileStoragedData(); // Refresh the file list
        }, 1000);
        setFiles(null);
        // Close the modal and reset the state after all uploads
        handleClose();
      } catch (err) {
        setToast({ error: true });
        setError(`Error uploading Files ⚠`);
        // Close the modal and reset the state after all uploads
        handleClose();
      }
    }
    setLoading(false); // Ensure loading state is reset
  };
  const DeleteFILE = async (data) => {
    if (Folders) {
      try {
        const mylocation = FolderName + "/" + data;
        await _http.delete("/quiz/DeleteFromS3", {
          data: {
            filenames: [mylocation],
          },
        });
        setFolders(false);
        setToast({ submit: true });
        setError("Deleted Successfully ✅");
        FileStoragedData();
      } catch (er) {
        setToast({ error: true });
        setError("Delete Unsuccessfull ❌");
      }
    } else {
      try {
        await _http.delete("/quiz/DeleteFromS3", {
          data: {
            filenames: [data],
          },
        });
        setToast({ submit: true });
        setError("Deleted Successfully ✅");
        FileStoragedData();
      } catch (er) {
        setToast({ error: true });
        setError("Delete Unsuccessfull ❌");
      }
    }
  };
  const FileStoragedData = useCallback(async () => {
    setLoading(true);
    try {
      const endpoint =
        section === "catalogue" ? "/quiz/ViewVideoS3" : "/quiz/ViewImageS3";
      const resp = await _http.get(endpoint);
      setLoading(false);
      setApidata(resp.data || []);
    } catch (er) {
      setToast({ error: true });
      setLoading(false);
      setError("Error Fetching Data ❌");
    }
  }, [section]);
  useEffect(() => {
    FileStoragedData();
    setCreateFolder([]);
  }, [FileStoragedData]);

  useEffect(() => {
    const filtered = (apidata || []).filter((data) => {
      const searchLower = searchValue?.toLowerCase();

      // Check if the file_name or folder_name includes the search value
      const nameMatches =
        data?.file_name?.toLowerCase().includes(searchLower) ||
        data?.folder_name?.toLowerCase().includes(searchLower);

      // Check if any file in the files array matches the search value
      const filesMatch = data?.files?.some((file) =>
        file?.file_name?.toLowerCase().includes(searchLower)
      );

      return nameMatches || filesMatch;
    });
    setFilteredData(filtered);
  }, [searchValue, apidata]);
  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Clean up on unmount
    };
  }, []);

  function handleDoubleClick(foldnm, foldfiles) {
    if (foldnm !== undefined) {
      setFolders(true);
      if (Folders) {
        folderCreation(foldnm, File);
      }
    }
  }

  const handleSave = async () => {
    try {
      await _http.post("/quiz/CreateFolderS3", {
        foldername: NewFolderName || "MyFolder",
      });
      FileStoragedData();
      setCreateFolder([]);
      setToast({ submit: true });
      setError("Folder Created Successfully ✅");
    } catch (error) {
      console.log(error);
      setToast({ error: true });
      setError("Something Went Wrong ❌");
    } finally {
      setIsFolder(false);
    }
  };

  const backHandler = () => {
    setFolders(false);
  };

  const addNewFolderHandler = () => {
    const newFolder = {
      id: createFolder?.length + 1,
      folderName: "",
      isEditing: true,
    };
    setCreateFolder([newFolder]);
    setIsFolder(true);
  };

  const handleFolderNameChange = (id, newName) => {
    setNewFolderName(newName);
    setCreateFolder((prevFolders) =>
      prevFolders.map((folder) =>
        folder.id === id ? { ...folder, folderName: newName } : folder
      )
    );
  };

  const editHandler = (index, currentName) => {
    setIsEditing(index);
    // setEditText(currentName);
    setOldFolderValue(currentName);
  };

  const cancelHandler = () => {
    setIsEditing(null);
    setEditText("");
  };

  const renameFolderHandler = async () => {
    try {
      await _http.post("/quiz/RenameFolder", {
        old_folder_name: oldFolderValue,
        new_folder_name: editText,
      });
      FileStoragedData();
      setToast({ submit: true });
      setError("Folder name updated Successfully ✅");
    } catch (error) {
      setToast({ error: true });
      setError("UnSuccessfully of updating folder ❌");
    } finally {
      setIsEditing(null);
      setEditText("");
    }
  };
  function SearchValidate(val, event) {
    const regex = /^[a-zA-Z0-9]*$/;
    const isBackspaceOrDelete =
      event.key === "Backspace" || event.key === "Delete";
    if (!regex.test(val) && !isBackspaceOrDelete) {
      return;
    }
    SetSearchValue(val);
  }

  return (
    <section className="parent_container">
      <div className="container_Quiz">
        <div className="df container-head1 w100 Qre_title">
          <span className="sales">Qre /</span>
          <span className="sales-title">Qre Configure</span>
        </div>
        <div className="upper_one">
          <div className="left_upper">
            <div className="container-head df w100">
              <div style={{ width: "40%" }}>
                <span className="title">QRE Configure</span>
              </div>
            </div>
            <span className="q-btns">
              <button
                className="q-btn1"
                onClick={() => navigate("/SpiraQRE/Quiz")}
              >
                Quiz
              </button>
              <button
                className={`q-btn1 ${
                  section === "catalogue" ? "main_btn" : ""
                }`}
                onClick={() => {
                  navigateHandler("catalogue");
                }}
              >
                Catalogue
              </button>
              <button
                className={`q-btn1 ${section === "photo" ? "main_btn" : ""}`}
                onClick={() => {
                  navigateHandler("photo");
                }}
              >
                Photo
              </button>
            </span>
          </div>
          <div className="middle_upper">
            <p
              className="dropdown_sty"
              onDragEnter={handleDragEnter}
              onDragOver={handleDragEnter}
              onDrop={handleDrop}
              onClick={handleFileUpload}
            >
              <input
                type="file"
                style={{ display: "none" }}
                accept={`${section === "photo" ? ".png,.jpeg" : ".mp4"}`}
                onChange={(e) => {
                  setFiles(e.target.files);
                  setOpen(true);
                }}
                ref={fileRef}
                multiple={Folders}
              />
              <img src={upload} alt="up-svg" className="up-svg" />
              <span className="file-name">
                Drop or Browser Files{" "}
                <span>
                  Supports {section === "photo" ? ".png,.jpeg" : ".mp4"} files
                </span>
              </span>
            </p>

            <p className="file_size">
              {` Upto ${
                section === "catalogue" ? "125MB" : "25MB"
              }. File name without and special character `}
            </p>
          </div>
          {!Folders ? (
            <div className="right_upper">
              {section === "catalogue" && (
                <>
                  <p>Video Link</p>
                  <div className="video_input">
                    <div className="video-items">
                      <img src={LinksIcon} alt="" />
                      <input
                        type="text"
                        placeholder="Paste the link here"
                        value={Value}
                        className="inp_vid"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </div>
                    <img
                      src={leftarrow}
                      alt="leftarrow"
                      className="leftarrowquizdocs"
                      style={{ cursor: "pointer" }}
                      onClick={() => Link_to_File(Value)}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="right_upper"></div>
          )}
        </div>
        <div className="below_one">
          <div className="left">
            <div className="nav">
              <div className="left_nav">
                {!isFolder && (
                  <>
                    <img
                      src={!Folders ? addIcon : back_icon}
                      alt="error"
                      className="addIcon"
                      onClick={!Folders ? addNewFolderHandler : backHandler}
                    />
                    <span className="folder_title">
                      {!Folders ? "Add new folder" : FolderName}
                    </span>
                  </>
                )}
              </div>
              {!Folders ? (
                <div className="right_nav">
                  <div className="user-search1 df">
                    <input
                      type="text"
                      placeholder="Search"
                      name="user"
                      value={searchValue}
                      onChange={(e) => SearchValidate(e.target.value, e)}
                    />
                    <img src={searchIcon} alt="icon" />
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>

            {createFolder?.length > 0 || filteredData?.length > 0 ? (
              <>
                {loading ? (
                  <div className="spinners">
                    {/* <img src={Animation} draggable="false" alt="" /> */}
                  </div>
                ) : (
                  <>
                    {!Folders ? (
                      <div className="file_section" ref={containerRef}>
                        {createFolder?.map((folder) => (
                          <div className="file_details" key={folder.id}>
                            <div className="folder_left_file">
                              <img src={FolderIcon} alt="error" />
                              <div>
                                {folder.isEditing ? (
                                  <input
                                    type="text"
                                    placeholder="Enter the folder name"
                                    className="folder_file_title"
                                    value={folder.folderChange}
                                    onChange={(e) =>
                                      handleFolderNameChange(
                                        folder.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <div className="folder_file_title">
                                    {folder.folderName || "Unnamed Folder"}
                                  </div>
                                )}
                                <p
                                  className="folder_file_size"
                                  style={{ color: "red" }}
                                >
                                  no file in the folder
                                </p>
                              </div>
                            </div>
                            <div className="right_file">
                              <button className="closeBtn" onClick={handleSave}>
                                Save
                              </button>
                              <img
                                src={Close}
                                alt="error"
                                onClick={() => {
                                  setIsFolder(false);
                                  setCreateFolder([]);
                                }}
                                className="close_icon"
                              />
                            </div>
                          </div>
                        ))}
                        {filteredData?.map((item, index) => (
                          <div
                            className={`file_details ${
                              selectedIndex === index ? "selected" : ""
                            }`}
                            onClick={() => {
                              handleSelect(
                                index,
                                item?.linkUrl,
                                item?.folder_name
                              );
                              setMediaLinks([item?.file_name]);
                              setFolderLinks(item?.folder_name + "/");
                            }}
                            onDoubleClick={() => {
                              setFolderName(item?.folder_name);

                              handleDoubleClick(item?.folder_name, item?.files);
                            }}
                            // className="file_details selected"
                          >
                            <div className="left_file">
                              {item?.folder_name ? (
                                <img
                                  src={`${item?.folder_name && FolderIcon}`}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={`${
                                    section === "catalogue"
                                      ? item.linkUrl
                                        ? darkLink
                                        : VideoIcon
                                      : Img
                                  }`}
                                  alt="error"
                                />
                              )}
                              <div>
                                <div className="file_title">
                                  {isEditing === index ? (
                                    <div className="fols_pty">
                                      <input
                                        type="text"
                                        value={editText}
                                        placeholder="Enter the Folder Name"
                                        onChange={(e) => {
                                          setEditText(e.target.value);
                                        }}
                                        className="file_title"
                                      />
                                    </div>
                                  ) : item?.file_name ? (
                                    item.file_name
                                  ) : (
                                    <>
                                      {item?.folder_name}
                                      <img
                                        src={Edit}
                                        alt="edit"
                                        className="edit_icon"
                                        onClick={() =>
                                          editHandler(index, item.folder_name)
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                                <p className="file_size">
                                  {item?.size_mb ||
                                    `${item?.files ? item.files.length : " "} ${
                                      item?.files
                                        ? section === "catalogue"
                                          ? "Videos"
                                          : "Images"
                                        : " "
                                    }`}
                                </p>
                              </div>
                            </div>
                            <div className="right_file">
                              <div>
                                <p className="file_title">
                                  {item.last_modified
                                    ? new Date(
                                        item?.last_modified
                                      )?.toLocaleDateString()
                                    : ""}
                                </p>
                                <p className="file_size">
                                  {item.last_modified
                                    ? new Date(
                                        item?.last_modified
                                      )?.toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })
                                    : ""}
                                </p>
                              </div>
                              {isEditing === index ? (
                                <div className="btns">
                                  <button
                                    className="rename_btn"
                                    onClick={renameFolderHandler}
                                  >
                                    Rename
                                  </button>
                                  <img
                                    src={Close}
                                    alt="error"
                                    onClick={cancelHandler}
                                    className="close_icon"
                                  />
                                </div>
                              ) : (
                                <img
                                  src={FileDelete}
                                  alt="error"
                                  onClick={() =>
                                    DeleteFILE(
                                      item?.file_name || item.folder_name
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        {filteredData?.map((item) => item.files)?.length > 0 ? (
                          <div className="file_section">
                            {filteredData?.map((item) =>
                              item.files?.map((item, index) => (
                                <div
                                  className={`file_details ${
                                    selectedIndex === index ? "selected" : ""
                                  }`}
                                  onClick={() => {
                                    handleSelect(index);
                                    setMediaLinks([item?.file_name]);
                                    setFolderLinks(item?.folder_name + "/");
                                  }}
                                  onDoubleClick={() => {
                                    setFolderName(item?.folder_name);

                                    handleDoubleClick(
                                      item?.folder_name,
                                      item?.files
                                    );
                                  }}

                                  // className="file_details selected"
                                >
                                  <div className="left_file">
                                    <img
                                      src={`${
                                        section === "catalogue"
                                          ? VideoIcon
                                          : Img
                                      }`}
                                      alt="error"
                                    />

                                    <div>
                                      <p className="file_title">
                                        {item?.file_name}
                                      </p>
                                      <p className="file_size">
                                        {item?.size_mb ||
                                          `${
                                            item?.files
                                              ? item.files.length
                                              : " "
                                          } ${
                                            item?.files
                                              ? section === "catalogue"
                                                ? "Videos"
                                                : "Images"
                                              : " "
                                          }`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right_file">
                                    <div>
                                      <p className="file_title">
                                        {new Date(
                                          item?.last_modified
                                        )?.toLocaleDateString()}
                                      </p>
                                      <p className="file_size">
                                        {new Date(
                                          item?.last_modified
                                        )?.toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </p>
                                    </div>
                                    <img
                                      src={FileDelete}
                                      alt="error"
                                      onClick={() =>
                                        DeleteFILE(item?.file_name)
                                      }
                                    />
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        ) : (
                          <div className="empty">
                            <Empty>
                              <button
                                className="addMediasbtns"
                                onDragEnter={handleDragEnter}
                                onDragOver={handleDragEnter}
                                onDrop={handleDrop}
                                onClick={handleFileUpload}
                              >
                                {section === "catalogue"
                                  ? "Add Video"
                                  : "Add Photos"}
                              </button>
                            </Empty>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="empty">
                <Empty>
                  <button
                    className="addMediasbtns"
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDrop={handleDrop}
                    onClick={handleFileUpload}
                  >
                    {section === "catalogue" ? "Add Video" : "Add Photos"}
                  </button>
                </Empty>
              </div>
            )}
          </div>
          {!Folders && (
            <div className="right">
              <button
                className={`${active ? "active_generate_qr" : "generate_qr"}`}
                onClick={handleSubmit}
              >
                Generate QR Code
              </button>
              {qrcode && qrpng && (
                <div className="qrcatlogmain">
                  <img src={qrpng} alt="QR Code" className="qrpngsclass" />
                  <div className="quizsocialcatlog">
                    <Tooltip
                      title={
                        <QuizShare
                          linkUrl={linkUrl}
                          externalUrl={externalUrl}
                          dataUrl={dataUrl}
                        />
                      }
                      placement={"bottom"}
                      color="#fff"
                    >
                      <img src={share} alt="" className="socialbtncatolog" />
                    </Tooltip>
                    <a
                      href={qrpng}
                      download={`QRCode_Link.png`}
                      onClick={(e) => {
                        const linker = document.createElement("a");
                        linker.href = qrpng;
                        linker.download = "QRCode_Link.png";
                        linker.click();
                      }}
                    >
                      <img src={download} alt="" className="socialbtncatolog" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {open && (
        <CataloguePhotoPopup
          open={open}
          section={section}
          setOpen={setOpen}
          handleClose={handleClose}
          fileTitle={section === "catalogue" ? "Video" : "Image"}
          File={File}
          uploadMedia={uploadMedia}
          setToast={setToast}
          setError={setError}
          loading={loading}
          folderCreation={folderCreation}
          Folders={Folders}
          handleFileUpload={handleFileUpload}
        />
      )}

      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </section>
  );
}
