import React, { useState, useEffect, useCallback } from "react";
import DraggableTable from "../../../Components/TableDrag_func";
import { rem } from "../../../Components/Rem_func";
import { setdatacount } from "../../../Redux/Reducers";
import { useDispatch } from "react-redux";
import _http from "../../../Utils/Api/_http";

const QreReportTable = ({ defaultPageSize }) => {
  const renderEmptyOrValue = (text, TT, cln, Type) => {
    const dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

    // Check if text matches the date-time format
    const isValidDateTime = dateTimePattern.test(text);

    // Return 'DataNR' if text is falsy
    if (!text) {
      return <span className="empty">DataNR</span>;
    }

    // Handle date or time types
    if (Type === "date" || Type === "time") {
      if (isValidDateTime) {
        const [datePart, timePart] = text.split(" ");
        return <span>{Type === "date" ? datePart : timePart}</span>;
      } else {
        return <span>MismatchF</span>; // Return this if the format does not match
      }
    }

    // Default case: Return the text with optional class and capitalization
    return (
      <span
        className={cln}
        style={{ textTransform: TT ? "capitalize" : "none" }}
      >
        {text}
      </span>
    );
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const fetchData = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const response = await _http.post(`/quiz/get-participants`, {
          id: id,
        });
        setReports(response?.data);
        dispatch(setdatacount(response.data.length));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    fetchData(id);
  }, [fetchData]);
  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: rem(94),
      className: "",
      render: (text, record, rowIndex) => <span>{rowIndex + 1}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "tl",
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      width: rem(136),
      render: (text, record) => renderEmptyOrValue(text?.toLowerCase(), true),
    },
    {
      title: "No. of Questions",
      dataIndex: "status",
      key: "status",
      width: rem(146),
      render: (text, record) => (
        <span>
          {record.attempted_questions + record.not_attempted_questions}
        </span>
      ),
      sorter: (a, b) => {
        const aValue =
          (a?.attempted_questions || 0) + (a?.not_attempted_questions || 0);
        const bValue =
          (b?.attempted_questions || 0) + (b?.not_attempted_questions || 0);
        return aValue - bValue;
      },
      className: "",
    },
    {
      title: "Attempted",
      dataIndex: "attempted_questions",
      key: "attempted_questions",
      sorter: (a, b) => {
        const aValue = parseInt(a?.attempted_questions || 0);
        const bValue = parseInt(b?.attempted_questions || 0);
        return aValue - bValue;
      },
      width: rem(104),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Correct",
      dataIndex: "correct_answers",
      key: "correct_answers",
      sorter: (a, b) => {
        const aValue = parseInt(a?.correct_answers || 0);
        const bValue = parseInt(b?.correct_answers || 0);
        return aValue - bValue;
      },
      width: rem(94),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Wrong",
      dataIndex: "wrong_answers",
      key: "wrong_answers",
      sorter: (a, b) => {
        const aValue = parseInt(a?.wrong_answers || 0);
        const bValue = parseInt(b?.wrong_answers || 0);
        return aValue - bValue;
      },
      width: rem(94),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Not Attempted",
      dataIndex: "not_attempted_questions",
      key: "not_attempted_questions",
      width: rem(104),
      className: "",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => {
        const aValue = parseInt(a?.not_attempted_questions || 0);
        const bValue = parseInt(b?.not_attempted_questions || 0);
        return aValue - bValue;
      },
    },
    {
      title: "Time Taken",
      dataIndex: "time_taken",
      key: "time_taken",
      width: rem(94),

      render: (text, record) => `${text} mins`,
      sorter: (a, b) => {
        const aValue = parseInt(a?.time_taken || 0);
        const bValue = parseInt(b?.time_taken || 0);
        return aValue - bValue;
      },
    },
    {
      title: `Score`,
      dataIndex: "correct_answers",
      key: "correct_answers",
      className: "",

      render: (text, record) => text,
      width: rem(94),
      sorter: (a, b) => {
        const aValue = parseInt(a?.correct_answers || 0);
        const bValue = parseInt(b?.correct_answers || 0);
        return aValue - bValue;
      },
    },
    {
      title: `Rank`,
      dataIndex: "current_rank",
      key: "current_rank",
      className: "",
      render: (text) => <span>{text}</span>,
      width: rem(94),
    },
  ];

  return (
    <DraggableTable
      filteredUsers={reports}
      loading={loading}
      baseColumns={columns}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default QreReportTable;
