import React, { useState, useCallback } from "react";
import FaEye from "../Svg/FaEye";
import FaEyeSlash from "../Svg/FaEyeSlash";

const patternMap = {
  numberOnly: /^[0-9]*$/,
  numberWithFloat: /^\d*\.?\d*$/,
  mobile: /^[0-9]*$/,
  password: /^[^\s]+$/,
  text: /^[A-Za-z0-9\s]*$/,
  email: /^[a-z0-9.@]*$/,
  textwithNospace: /^[A-Za-z0-9]*$/,
  onlyAlpha: /^[A-Za-z\s]*$/,
};

export default function InputField({
  label,
  labelName,
  type,
  placeholder,
  fieldData,
  maxLength,
  setFormData,
  hasError,
  height,
  onChange,
  readOnly,
  pattern,
  styles,
  minLength,
}) {
  const [focusedField, setFocusedField] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);

  const handleFocus = useCallback(() => {
    setFocusedField(label);
  }, [label]);

  const handleBlur = useCallback(() => {
    setFocusedField(null);
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === " " && e.target.value === "") {
      e.preventDefault();
    }
  }, []);

  const handleInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      let newValue = value?.replace(/\s+/g, " ");
      let val = value
        ?.replace(/\s/g, "")
        ?.replace(/[^a-z0-9.@ ]/g, "")
        ?.replace(/([,.@])\1+/g, "$1");

      if (type === "date") {
        const enteredDate = new Date(value);
        const maxDate = new Date();
        enteredDate.setHours(0, 0, 0, 0);
        maxDate.setHours(0, 0, 0, 0);

        if (enteredDate > maxDate) return;
      }

      if (pattern) {
        const regex = patternMap[pattern] || /.*/;

        if (value === "" || regex.test(value)) {
          if (onChange) {
            setFormData(value?.replace(/\s+/g, " "));
            return;
          }
          setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
          }));
        }
      } else {
        if (onChange) {
          setFormData(value?.replace(/\s+/g, " "));
          return;
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: newValue,
        }));
      }

      if (pattern === "email") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: val,
        }));
      }
    },
    [type, pattern, setFormData, onChange]
  );

  return (
    <div className="frm-group">
      <div className="sub-head">
        {labelName}
        {type === "range" && <span className="range-value">{fieldData}</span>}
      </div>
      <div
        className={`frm-field ${
          (focusedField === label || hasError) && !fieldData
            ? "draw-border"
            : ""
        }`}
        style={{ height: height }}
        onClick={(e) =>
          type === "date" ? document?.getElementById(label)?.showPicker() : ""
        }
      >
        <input
          value={fieldData || ""}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          id={label}
          name={label}
          placeholder={placeholder}
          className="frm-control"
          maxLength={maxLength}
          max={maxLength}
          minLength={minLength}
          min={minLength}
          style={{
            caretColor: "#1e6641",
            cursor: type === "date" ? "pointer" : "text",
            ...styles,
            // pointerEvents: readOnly ? "none" : "auto",
          }}
          pattern={pattern}
          readOnly={readOnly}
          autoComplete={type === "password" ? "new-password" : "off"}
        />
        {type === "password" && (
          <span
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          >
            {!showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        )}
      </div>
    </div>
  );
}
