import React from "react";
import "./Home.scss";
import UserHome from "./UserHome/UserHome";
import QualityHome from "./QualityHome/QualityHome";
import QuoteHome from "./QuoteHome/QuoteHome";
import SalesHome from "./SalesHome/SalesHome";
import FeedbackStats from "./FeedbackStats/FeedbackStats";
import { useSelector } from "react-redux";
import { selectUserData } from "../../Redux/Reducers";

function Home() {
  const access = useSelector(selectUserData);

  return (
    <section className="home_container flexColumn df">
      <div className="df box">
        {access?.sales_tracker && <SalesHome access={access} />}
        {access?.feedback_form && <FeedbackStats />}{" "}
      </div>
      {/* {access.role_name !== "employee" && ( */}
      <div className="df box">
        {access?.quote_generator && <QuoteHome access={access} />}
        {access?.quality && <QualityHome />}
        {access.role_name !== "employee" && (
          <>{access?.user_management && <UserHome />}</>
        )}
      </div>
      {/* )} */}
    </section>
  );
}

export default Home;
