import React, { useEffect, useMemo, useState } from "react";
import "./QuoteHome.scss";
import _http from "../../../Utils/Api/_http";
import { rem } from "../../../Components/Rem_func";
import SnackBar from "../../../Components/SnackBar";

const QuoteHome = ({ access }) => {
  const [quote, setQuote] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [visibleYearIndex, setVisibleYearIndex] = useState(null);

  const requiredBranches = useMemo(
    () =>
      access?.location === "all"
        ? ["Oman", "Dubai", "Abu Dhabi"]
        : [access?.location],
    [access]
  );
  const currencyOptions = ["AED", "USD", "OMR"];
  const months = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const handletoast = () => setToast({ submit: false, error: false });

  const getAllYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2021; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const filteredData = useMemo(() => {
    if (!quote)
      return { branchTotals: [], monthlySummary: [], yearlySummary: {} };

    const branchTotals = requiredBranches.reduce((acc, branch) => {
      acc[branch] = {
        branch,
        quotes: 0,
        amount: 0,
        earliestUploadedAt: null,
        latestUploadedAt: null,
        monthlyData: {},
        yearlyData: {},
      };
      return acc;
    }, {});

    const selectedData = selectedCurrency
      ? quote?.filter((item) =>
          item?.currency
            ?.toLowerCase()
            ?.trim()
            ?.includes(selectedCurrency?.toLowerCase())
        )
      : quote;

    selectedData?.forEach((item) => {
      const { branch, uploaded_at, amount = 0, quotes = 1 } = item;
      if (!requiredBranches?.includes(branch)) return;

      const uploadedDate = new Date(uploaded_at);
      const year = uploadedDate?.getFullYear();
      const month = months[uploadedDate.getMonth()];

      // Update branch totals
      const branchData = branchTotals[branch];
      branchData.quotes += quotes;
      branchData.amount += amount;
      branchData.earliestUploadedAt =
        !branchData.earliestUploadedAt ||
        uploadedDate < new Date(branchData.earliestUploadedAt)
          ? uploaded_at
          : branchData.earliestUploadedAt;
      branchData.latestUploadedAt =
        !branchData.latestUploadedAt ||
        uploadedDate > new Date(branchData.latestUploadedAt)
          ? uploaded_at
          : branchData.latestUploadedAt;

      // Update monthly data
      const monthKey = `${year}-${month}`;
      if (!branchData.monthlyData[monthKey]) {
        branchData.monthlyData[monthKey] = {
          year,
          month,
          totalQuotes: 0,
          totalAmount: 0,
        };
      }
      branchData.monthlyData[monthKey].totalQuotes += quotes;
      branchData.monthlyData[monthKey].totalAmount += amount;

      // Update yearly data
      if (!branchData.yearlyData[year]) {
        branchData.yearlyData[year] = { year, totalQuotes: 0, totalAmount: 0 };
      }
      branchData.yearlyData[year].totalQuotes += quotes;
      branchData.yearlyData[year].totalAmount += amount;
    });

    const years = getAllYears();

    const branchTotalsArray = Object.values(branchTotals).map((branch) => {
      // Ensure all years are present
      years.forEach((year) => {
        if (!branch.yearlyData[year]) {
          branch.yearlyData[year] = { year, totalQuotes: 0, totalAmount: 0 };
        }
      });
      branch.yearlyData = Object.values(branch.yearlyData).sort(
        (a, b) => b.year - a.year
      );

      // Ensure all months are present for each year
      branch.yearlyData.forEach((yearData) => {
        yearData.monthlyData = months.map((month) => {
          const monthKey = `${yearData.year}-${month}`;
          return (
            branch.monthlyData[monthKey] || {
              year: yearData.year,
              month,
              totalQuotes: 0,
              totalAmount: 0,
            }
          );
        });
      });

      return branch;
    });

    return {
      branchTotals: branchTotalsArray,
      monthlySummary: branchTotalsArray.flatMap((branch) => branch.monthlyData),
      yearlySummary: branchTotalsArray.reduce((acc, branch) => {
        acc[branch.branch] = branch.yearlyData;
        return acc;
      }, {}),
    };
  }, [quote, selectedCurrency, months, requiredBranches]);

  const fetchData = async () => {
    try {
      const response = await _http.get("/api/fetching-generated-quotes");
      setQuote(response.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching Quote Generated Data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + "B";
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "K";
    return num?.toString();
  };

  const handleCurrency = (currency) => {
    setSelectedCurrency((prev) => (prev === currency ? null : currency));
  };

  const handleDropDown = (item, index) => {
    setDropdownIndex(index === dropdownIndex ? null : index);
    setVisibleYearIndex(null);
  };

  const handleMonth = (e, item, yearIndex) => {
    e.stopPropagation();
    if (item?.totalAmount !== 0) {
      setVisibleYearIndex(visibleYearIndex === yearIndex ? null : yearIndex);
    }
  };
  function getDate() {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}\t ${months[month]} - ${year}`;
  }
  return (
    <div className="quote_Home">
      <div className="quote-Data-TAble">
        <div className="df space-between align-center">
          <span className="userM">Quote Generator</span>
          <div className="df justify-end" style={{ gap: rem(8) }}>
            {currencyOptions.map((item, id) => (
              <div
                key={id}
                className={`currency df ${
                  selectedCurrency === item
                    ? "currency-select"
                    : "currency-notSelect"
                }`}
                onClick={() => handleCurrency(item)}
                style={{ cursor: "pointer" }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className="quote-home-datas">
          {filteredData.branchTotals.reverse().map((item, index) => (
            <div
              key={index}
              className="quote-home-items-content"
              onClick={() => handleDropDown(item, index)}
            >
              <div
                className={
                  dropdownIndex === index
                    ? "quote-home-items-title  quote-dropdown"
                    : "quote-home-items-title"
                }
              >
                <span>{item.branch || "--"}</span>
              </div>

              <div className="flexColumn quote-home-items">
                <div
                  className="flexBox space-between"
                  style={{ padding: `${rem(8)} ${rem(6)}` }}
                >
                  <div className="df quoteitems">
                    <span>Periods</span>
                    <span>{getDate()}</span>
                  </div>
                  <div className="df quoteitems">
                    <span>Quotes</span>
                    <span>{item.quotes}</span>
                  </div>

                  <div className="df quoteitems">
                    <span>Amount</span>
                    <span>{formatNumber(item.amount)}</span>
                  </div>
                </div>

                {dropdownIndex === index &&
                  item.yearlyData.map((yearItem, yearIndex) => (
                    <div key={yearIndex} className="yearData-items">
                      <div
                        className="df quote-sub-home-items year-row"
                        onClick={(e) => handleMonth(e, yearItem, yearIndex)}
                      >
                        <div
                          className="df quotesubitems"
                          style={{ width: "30%" }}
                        >
                          <span>{yearItem.year}</span>
                        </div>
                        <div
                          className="df quotesubitems"
                          style={{
                            width: "30%",
                            paddingLeft: rem(30),
                            // border: "2px solid black",
                          }}
                        >
                          <span>{yearItem.totalQuotes}</span>
                        </div>
                        <div
                          className="df quotesubitems"
                          style={{ width: "30%" }}
                        >
                          <span>{formatNumber(yearItem.totalAmount)}</span>
                        </div>
                      </div>
                      {visibleYearIndex === yearIndex &&
                        yearItem.monthlyData.map((monthData, monthIndex) => (
                          <div
                            key={monthIndex}
                            className="df quote-sub-home-items month-row"
                            style={{ paddingLeft: rem(2) }}
                          >
                            <div
                              className="df quotesubitems"
                              style={{ width: "30%" }}
                            >
                              <span>{monthData.month}</span>
                            </div>
                            <div
                              className="df quotesubitems"
                              style={{ width: "30%", paddingLeft: rem(30) }}
                            >
                              <span>{monthData.totalQuotes}</span>
                            </div>
                            <div
                              className="df quotesubitems"
                              style={{ width: "30%" }}
                            >
                              <span>{formatNumber(monthData.totalAmount)}</span>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
    </div>
  );
};

export default QuoteHome;
