import React, { useState, useEffect } from "react";
import "./UserFile.scss";
import { rem } from "../../Components/Rem_func";
import { selectUserData, setUserdata } from "../../Redux/Reducers";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import SnackBar from "../../Components/SnackBar";
import { toggleImage } from "../../Redux/Reducers";
import { useUserImage } from "../../Components/UserImageProvider";
import _http from "../../Utils/Api/_http";
import EditProfileModal from "./EditProfileModal";
import fallbackProfileImage from "../../Assets/SideBarIcon/fallback-profile-img.jpeg";

const UserProfile = () => {
  const [access, setAccess] = useState();
  const navigate = useNavigate();
  const [openmodel, setOpenModel] = React.useState(false);
  const [errors, setError] = useState("");
  const [toast, setToast] = useState({ Submit: false, error: false });
  const [editOpen, setEditOpen] = useState(false);

  const isAccess = useSelector(selectUserData);
  const dispatch = useDispatch();
  const { userImage } = useUserImage();

  // Effect to set access state from sessionStorage
  useEffect(() => {
    setAccess(isAccess);
  }, [isAccess]);

  const handletoast = () => {
    setToast({ Submit: false, error: false });
  };

  const handleSaveToast = (submit, error, message) => {
    setToast({ Submit: submit, error: error });
    setError(message);
  };
  const handleEditToast = (submit, error, message) => {
    setToast({ Submit: submit, error: error });
    setError(message);
  };

  const handleLogout = async () => {
    const refreshToken = sessionStorage.getItem("refreshToken");
    const logoutData = new FormData();
    logoutData.append("refresh_token", refreshToken);
    try {
      await _http.post("api/logout", logoutData);
      sessionStorage.removeItem("SpirajwtToken");
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("dropdownState");
      dispatch(setUserdata(""));
      navigate("/Login");
    } catch (err) {
      setToast({ error: true });
      setError("Logout Failed ❌");
    }
  };
  const handleOpen = () => {
    setOpenModel(true);
  };
  const handleClose = () => setOpenModel(false);

  const handleImageChange = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await _http.post("/api/image-upload", formData);

      if (response.data) {
        dispatch(toggleImage());
        setToast({ Submit: true });
        setError("Image uploaded successfully");
      } else {
        setToast({ error: true });
        setError("Image upload failed");
      }
    } catch (error) {
      setToast({ error: true });
      setError("Error updating user image");
    }
  };
  const handleremovepicture = async () => {
    try {
      await _http.delete("/api/remove-image");
      dispatch(toggleImage());
      setToast({ Submit: true });
      setError("Image removed successfully");
    } catch (error) {
      setToast({ error: true });
      setError("Error removing user image");
    }
  };

  const handleEdit = () => {
    setEditOpen(true);
  };

  return (
    <section className="df align-start flexColumn">
      <div className="user-profile">
        <p className="user-title">User Details</p>
        <div className="user_container">
          <div className="profile-pic">
            <div className="picture">
              <img
                src={userImage ? userImage : fallbackProfileImage}
                alt="profile"
              />
              <div className="editing_container df" onClick={handleEdit}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={rem(17.3)}
                  height={rem(17.3)}
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.35719 5.26758H4.61833C4.22642 5.26758 3.85056 5.42326 3.57344 5.70039C3.29631 5.97751 3.14063 6.35337 3.14062 6.74529V13.395C3.14063 13.7869 3.29631 14.1627 3.57344 14.4399C3.85056 14.717 4.22642 14.8727 4.61833 14.8727H11.268C11.6599 14.8727 12.0358 14.717 12.3129 14.4399C12.59 14.1627 12.7457 13.7869 12.7457 13.395V12.6561"
                    stroke="#454545"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.006 3.79029L14.2225 6.00685M15.2458 4.96137C15.5368 4.67037 15.7003 4.2757 15.7003 3.86417C15.7003 3.45264 15.5368 3.05797 15.2458 2.76697C14.9548 2.47598 14.5602 2.3125 14.1486 2.3125C13.7371 2.3125 13.3424 2.47598 13.0514 2.76697L6.83398 8.96226V11.1788H9.05055L15.2458 4.96137Z"
                    stroke="#454545"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="user_details">
              <div className="users textCaps">
                <label htmlFor="">Username</label>
                <p>{access?.name?.toLowerCase()}</p>
              </div>
              <div className="users">
                <label htmlFor="">Email ID</label>
                <p>{access?.user_email}</p>
              </div>
            </div>
          </div>
          <div className="df below_btns">
            <button className="changePassword" onClick={handleOpen}>
              Change Password
            </button>
            <button className="logout df" onClick={handleLogout}>
              Logout
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(16)}
                height={rem(16)}
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M2.116 16C1.65533 16 1.271 15.846 0.963 15.538C0.655 15.23 0.500667 14.8453 0.5 14.384V1.616C0.5 1.15533 0.654333 0.771 0.963 0.463C1.27167 0.155 1.656 0.000666667 2.116 0H8.519V1H2.116C1.962 1 1.82067 1.064 1.692 1.192C1.56333 1.32 1.49933 1.46133 1.5 1.616V14.385C1.5 14.5383 1.564 14.6793 1.692 14.808C1.82 14.9367 1.961 15.0007 2.115 15H8.519V16H2.116ZM12.962 11.539L12.26 10.819L14.579 8.5H5.692V7.5H14.579L12.259 5.18L12.961 4.462L16.5 8L12.962 11.539Z"
                  fill="#D80621"
                />
              </svg>
            </button>
          </div>
          {openmodel && (
            <ChangePassword
              openmodel={openmodel}
              handleClose={handleClose}
              handleSaveToast={handleSaveToast}
            />
          )}

          {editOpen && (
            <EditProfileModal
              editOpen={editOpen}
              setEditOpen={setEditOpen}
              handleImageChange={handleImageChange}
              handleremovepicture={handleremovepicture}
              handleEditToast={handleEditToast}
            />
          )}

          <SnackBar
            message={errors}
            severity={"success"}
            handleClose={handletoast}
            Open={toast.Submit}
          />
          <SnackBar
            message={errors}
            severity={"error"}
            handleClose={handletoast}
            Open={toast.error}
          />
        </div>
      </div>
    </section>
  );
};

export default UserProfile;
