import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { rem } from "../../Components/Rem_func";
import { useUserImage } from "../../Components/UserImageProvider";
import "./UserFile.scss";
import Button from "../../Components/Button/Button";
import fallbackProfileImage from "../../Assets/SideBarIcon/fallback-profile-img.jpeg";

export default function EditProfileModal({
  editOpen,
  setEditOpen,
  handleImageChange,
  handleremovepicture,
  handleEditToast,
}) {
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const handlePreviewChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));
      setIsDelete(true);
    }
  };

  const handleUpdate = () => {
    if (selectedFile) {
      handleImageChange(selectedFile);
      setSelectedFile(null);
      setPreviewImage(null);
      setEditOpen(false);
    } else {
      handleEditToast(false, true, "No File Selected");
    }
  };

  const handleCancel = () => {
    setPreviewImage(null);
    setSelectedFile(null);
    setEditOpen(false);
  };

  const { userImage, isImg } = useUserImage();

  useEffect(() => {
    if (isImg === undefined) {
      setIsDelete(true);
    }
  }, [isImg]);

  const removePictureHandler = () => {
    if (selectedFile || previewImage) {
      setSelectedFile(null);
      setPreviewImage(null);
      handleEditToast(true, false, "Profile Image is Removed");
    } else {
      handleremovepicture();
    }
    setIsDelete(false);
  };

  return (
    <Modal
      centered
      open={editOpen}
      onCancel={handleCancel}
      width={rem(370)}
      title={"Edit Profile Picture"}
      // height={rem(619)}
      closable={true}
      className="edit-popup user-profile-edit"
    >
      <div className="edit_user_details">
        <div className="user-pic">
          <img
            src={
              previewImage || userImage
                ? previewImage || userImage
                : fallbackProfileImage
            }
            alt="profile"
          />
        </div>
        <div className="edit_section df space-between">
          <div className="df upload_one">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(24)}
              height={rem(24)}
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_4511_41679"
                masktype="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4511_41679)">
                <path
                  d="M5.6155 20C5.15517 20 4.77083 19.8458 4.4625 19.5375C4.15417 19.2292 4 18.8448 4 18.3845V5.6155C4 5.15517 4.15417 4.77083 4.4625 4.4625C4.77083 4.15417 5.15517 4 5.6155 4H12.6155V5H5.6155C5.436 5 5.2885 5.05767 5.173 5.173C5.05767 5.2885 5 5.436 5 5.6155V18.3845C5 18.564 5.05767 18.7115 5.173 18.827C5.2885 18.9423 5.436 19 5.6155 19H18.3845C18.564 19 18.7115 18.9423 18.827 18.827C18.9423 18.7115 19 18.564 19 18.3845V11.3845H20V18.3845C20 18.8448 19.8458 19.2292 19.5375 19.5375C19.2292 19.8458 18.8448 20 18.3845 20H5.6155ZM6.76925 16.3845H17.2308L14 12.077L11 15.8655L9 13.4615L6.76925 16.3845ZM17 9V7H15V6H17V4H18V6H20V7H18V9H17Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            <label htmlFor="formId">
              <input
                className="upload-input"
                name=""
                type="file"
                id="formId"
                accept="image/*"
                // multiple
                hidden
                onChange={handlePreviewChange}
              />
              <p> Upload an Image</p>
            </label>
          </div>
          {isDelete && (
            <div className="delete_one df">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(15)}
                height={rem(17)}
                viewBox="0 0 15 17"
                fill="none"
                onClick={removePictureHandler}
              >
                <path
                  d="M2.8125 17C2.29688 17 1.85562 16.8152 1.48875 16.4456C1.12187 16.076 0.938125 15.6312 0.9375 15.1111V2.83333H0V0.944444H4.6875V0H10.3125V0.944444H15V2.83333H14.0625V15.1111C14.0625 15.6306 13.8791 16.0754 13.5122 16.4456C13.1453 16.8158 12.7038 17.0006 12.1875 17H2.8125ZM12.1875 2.83333H2.8125V15.1111H12.1875V2.83333ZM4.6875 13.2222H6.5625V4.72222H4.6875V13.2222ZM8.4375 13.2222H10.3125V4.72222H8.4375V13.2222Z"
                  fill="#1E6641"
                />
              </svg>
            </div>
          )}
        </div>
        <Button
          btnname={"Update"}
          height={38}
          fs={15}
          fw={500}
          width={338}
          br={5}
          cl="#fff"
          bg="#1e6641"
          border={`${rem(1)} solid #1e6641`}
          box_s={"none"}
          onclick={handleUpdate}
        />
      </div>
    </Modal>
  );
}
