import React, { useState, useEffect, useCallback } from "react";
import "chart.js/auto";
import { rem } from "../../../Components/Rem_func";
import { Bar } from "react-chartjs-2";

const getMonthAbbreviation = (date) => {
  if (!(date instanceof Date) || isNaN(date)) {
    console.error("Invalid date provided:", date);
    return "Invalid Month";
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[date.getMonth()];
};
const SalesBieChart = ({ users }) => {
  const handle = useCallback(
    (prop) => {
      getLastSixMonthsData();

      const calculate =
        (users || [])?.filter((pending) =>
          pending?.reminder_status?.includes(prop)
        ) || [];
      return calculate?.length || 0;
    },
    [users] // Add 'users' as a dependency
  );

  const getLastSixMonthsData = () => {
    const today = new Date();

    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    const twelveMonthsAgo = new Date(today);
    twelveMonthsAgo.setMonth(today.getMonth() - 12);

    // Initialize an object to hold the counts and total order value
    const monthlyData = {};
    let totalOrderValueAEDFirstSixMonths = 0;
    let totalOrderValueUSDFirstSixMonths = 0;
    let totalOrderValueOMRFirstSixMonths = 0;
    let totalOrderValueAEDSecondSixMonths = 0;
    let totalOrderValueUSDSecondSixMonths = 0;
    let totalOrderValueOMRSecondSixMonths = 0;
    // Filter users with `client_email_time_utc` within the last six months
    const filteredUsersByClientEmailTime = users.filter((user) => {
      const clientEmailTimeUTC = new Date(user.client_email_time_utc);
      const clientEmailLocalTime = new Date(
        clientEmailTimeUTC.getTime() -
          clientEmailTimeUTC.getTimezoneOffset() * 60000
      );

      // Check if the client email time falls within the last six months
      return (
        clientEmailLocalTime >= sixMonthsAgo && clientEmailLocalTime <= today
      );
    });

    const filteredUsersByClientEmailTimeby12 = users.filter((user) => {
      const clientEmailTimeUTC = new Date(user.client_email_time_utc);
      const clientEmailLocalTime = new Date(
        clientEmailTimeUTC.getTime() -
          clientEmailTimeUTC.getTimezoneOffset() * 60000
      );

      // Check if the client email time falls within the last six months
      return (
        clientEmailLocalTime >= twelveMonthsAgo &&
        clientEmailLocalTime <= sixMonthsAgo
      );
    });
    // Initialize total counters for each currency

    filteredUsersByClientEmailTimeby12.forEach((user) => {
      const orderDateTime = new Date(user.client_email_time_utc);
      const orderValue = parseFloat(
        user.final_order_currency_resolved?.replace(/[^0-9.-]+/g, "")
      );
      const currency = user.currency; // Assuming this field holds the currency type

      // Check if order value is a valid number and the order date is within the last 12 months
      if (
        !isNaN(orderValue) &&
        orderDateTime >= twelveMonthsAgo
        // orderDateTime <= today
      ) {
        // Add to the appropriate currency total
        if (currency === "AED") {
          totalOrderValueAEDSecondSixMonths += orderValue;
        } else if (currency === "USD") {
          totalOrderValueUSDSecondSixMonths += orderValue;
        } else if (currency === "OMR") {
          totalOrderValueOMRSecondSixMonths += orderValue;
        }
      }
    });

    // Process each user in the filtered list
    filteredUsersByClientEmailTime.forEach((user) => {
      const clientEmailTime = new Date(user.client_email_time_utc);
      const monthKey = getMonthAbbreviation(clientEmailTime);

      // Initialize the data object for the month if it doesn't exist
      monthlyData[monthKey] = monthlyData[monthKey] || {
        totalCount: 0,
        pending: 0,
        success: 0,
        orders: 0,
      };

      // Increment total count for client emails
      monthlyData[monthKey].totalCount += 1;

      // Increment count based on reminder_status
      if (user.reminder_status === "pending") {
        monthlyData[monthKey].pending += 1;
      } else if (user.reminder_status === "success") {
        monthlyData[monthKey].success += 1;
      } else if (user.reminder_status === "order_placed") {
        monthlyData[monthKey].orders += 1;
      }
      const orderDateTime = new Date(user.client_email_time_utc);
      const orderValue = parseFloat(
        user.final_order_currency_resolved?.replace(/[^0-9.-]+/g, "")
      );
      const currency = user.currency;

      if (!isNaN(orderValue)) {
        if (orderDateTime >= sixMonthsAgo && orderDateTime <= today) {
          if (currency === "AED") {
            totalOrderValueAEDFirstSixMonths += orderValue;
          } else if (currency === "USD") {
            totalOrderValueUSDFirstSixMonths += orderValue;
          } else if (currency === "OMR") {
            totalOrderValueOMRFirstSixMonths += orderValue;
          }
        }
      }
    });
    let percentageChangeAED = 0;

    if (totalOrderValueAEDSecondSixMonths > 0) {
      percentageChangeAED =
        ((totalOrderValueAEDFirstSixMonths -
          totalOrderValueAEDSecondSixMonths) /
          totalOrderValueAEDSecondSixMonths) *
        100;
    }

    let percentageChangeUSD = 0;
    if (totalOrderValueUSDSecondSixMonths > 0) {
      percentageChangeUSD =
        ((totalOrderValueUSDFirstSixMonths -
          totalOrderValueUSDSecondSixMonths) /
          totalOrderValueUSDSecondSixMonths) *
        100;
    }

    let percentageChangeOMR = 0;
    if (totalOrderValueOMRSecondSixMonths > 0) {
      percentageChangeOMR =
        ((totalOrderValueOMRFirstSixMonths -
          totalOrderValueOMRSecondSixMonths) /
          totalOrderValueOMRSecondSixMonths) *
        100;
    }
    const percentageChange = {
      percentageChangeAED,
      percentageChangeOMR,
      percentageChangeUSD,
    };
    const totalOrderValue = {
      totalOrderValueAEDFirstSixMonths,
      totalOrderValueOMRFirstSixMonths,
      totalOrderValueUSDFirstSixMonths,
    };
    return { monthlyData, percentageChange, totalOrderValue };
  };

  const { monthlyData: filteredData } = getLastSixMonthsData();

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Convert to billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num?.toString(); // If less than a thousand, return as is
    }
  };

  // Get current date
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Generate last 12 months in "MMM" format
  const last6Months = [];
  for (let i = 5; i >= 0; i--) {
    const date = new Date(currentYear, currentMonthIndex - i, 1); // Move backwards month by month
    last6Months.push(date.toLocaleString("default", { month: "short" }));
  }
  // Sort the months and map to their respective counts
  const availableMonths = last6Months;

  const pendingData = availableMonths?.map(
    (month) => filteredData[month]?.pending || 0
  );

  const successData = availableMonths?.map(
    (month) => filteredData[month]?.success || 0
  );
  const orderPlacedData = availableMonths.map(
    (month) => filteredData[month]?.orders || 0
  );

  const [conversion, setConversion] = useState({
    total: users?.length || 0,
    pending: handle("pending") || 0,
    success: handle("success") || 0,
    order_placed: handle("order_placed") || 0,
  });

  useEffect(() => {
    setConversion({
      total: users?.length || 0,
      pending: handle("pending") || 0,
      success: handle("success") || 0,
      order_placed: handle("order_placed") || 0,
    });
  }, [users, handle]);
  const datas = [
    {
      name: "Successful conversion",
      value: conversion?.order_placed || 0,

      color: "#1E6641",
      percentage: (conversion?.order_placed / conversion?.total) * 100 || 0,
    },
    {
      name: "Unsuccessful conversion",
      value: conversion?.success || 0,
      color: "#558B70",
      percentage: (conversion?.success / conversion?.total) * 100 || 0,
    },
    {
      name: "Quoted value Missing",
      value: conversion?.pending || 0,
      color: "#CEDDD5",
      percentage: (conversion?.pending / conversion?.total) * 100 || 0,
    },
    // {
    //   name: "Total Enquiries",
    //   value: conversion?.total || 0,
    //   color: "#BED2C8",
    //   percentage: 100,
    // },
  ];
  const data = {
    labels: availableMonths,
    datasets: [
      {
        type: "bar",
        label: "Quote Value Missing",
        barPercentage: 0.5,
        borderRadius: 10,
        data: pendingData,
        backgroundColor: "#CEDDD5",
        hoverBackgroundColor: "#CEDDD5",
        yAxisID: "y",
      },
      {
        type: "bar",
        label: "Unsuccessful Conversion",
        barPercentage: 0.5,
        borderRadius: 10,
        data: successData,
        backgroundColor: "#558B70",
        hoverBackgroundColor: "#558B70",
        yAxisID: "y",
      },
      {
        type: "bar",
        barPercentage: 0.5,
        label: "Successful Conversion",

        borderRadius: 10,
        data: orderPlacedData,
        backgroundColor: "#1E6641",
        hoverBackgroundColor: "#1E6641",
        yAxisID: "y",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          font: {
            size: 12,
          },
          color: "#454545",
        },
        border: {
          display: true,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          font: {
            size: 12,
          },
          color: "#454545",
        },
        border: {
          display: false,
        },
        title: {
          display: true,
          text: "Total Enquiries",
          color: "#878787", // Optional: set color for the title
          font: {
            size: 8, // Optional: set font size for the title
            // weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            // Get the dataset label (e.g., "Quote Value Missing", "Unsuccessful Conversion")
            const datasetLabel = tooltipItem.dataset.label || "";
            // Get the value of the current data point
            const value = tooltipItem.raw;
            // Return the formatted label for each tooltip entry
            return `${datasetLabel}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div
      className="sales-bie-chart revenue-graph df flexColumn"
      style={{ width: rem(360), height: rem(300) }}
    >
      <div className="chart-title">By TAT Bucket</div>
      {/* <div className="chart-wrapper"> */}
      <div style={{ width: "100%", height: rem(195) }}>
        <Bar data={data} options={options} />{" "}
      </div>
      <div className="legend-wrapper">
        {datas.map((entry, index) => (
          <div key={`legend-${index}`} style={{ paddingTop: rem(5) }}>
            <div className="legend-item">
              <div
                className="legend-color"
                style={{ backgroundColor: entry.color }}
              />
              <p>{entry.name}</p>
            </div>
            <p className="ktg">{`${entry?.value} (${entry?.percentage?.toFixed(
              2
            )}%)`}</p>
          </div>
        ))}
      </div>
      {/* </div> */}
    </div>
  );
};

export default SalesBieChart;
