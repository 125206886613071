import React, { useState, useEffect, useCallback } from "react";
import "../STcomponents/Filter/FilterEnquires.scss";
import { rem } from "../../../Components/Rem_func";
import Button from "../../../Components/Button/Button";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
import CrossIcon from "../../../Components/Svg/CrossIcon";

const DashBoardFilter = ({
  hide,
  userList,
  filteredData,
  setFilteredData,
  setFilters,
  setFilterEmployeeName,
}) => {
  const access = useSelector(selectUserData);
  const [managersArray, setManagersArray] = useState([]);
  const [teamLeadsArray, setTeamLeadsArray] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [localData, setLocalData] = useState(filteredData);

  const removeThisFilter = (key, index = null) => {
    setLocalData((prev) => {
      const updatedData = { ...prev };

      if (Array.isArray(prev[key])) {
        if (index !== null) {
          const updatedArray = prev[key].filter((_, i) => i !== index);
          updatedData[key] = updatedArray;
        } else {
          updatedData[key] = [];
        }
      } else {
        updatedData[key] = "";
      }

      // Validate against available options and remove invalid selections
      updatedData.managers = updatedData.managers.filter((manager) =>
        managersArray.includes(manager)
      );
      updatedData.teamleads = updatedData.teamleads.filter((teamLead) =>
        teamLeadsArray.includes(teamLead)
      );
      updatedData.employees = updatedData.employees.filter((employee) =>
        employeesArray.includes(employee)
      );

      return updatedData;
    });
    // setFilteredData(localData);
    // setFilterEmployeeName(employeesArray);
  };

  const roleTypes = useCallback(() => {
    // Function to filter users by role and who they report to
    const filterUsersByRole = (role, reportingToArray = []) => {
      return userList
        .filter(
          (user) =>
            user.role_name.toLowerCase() === role.toLowerCase() &&
            (reportingToArray.length === 0 ||
              reportingToArray.includes(user.reporting_to))
        )
        .map((user) => user.user);
    };

    const selectedManagers = localData?.managers || []; // Multiple managers
    const selectedTeamLeads = localData?.teamleads || []; // Multiple team leads

    // Filter managers dynamically
    setManagersArray(filterUsersByRole("Manager"));

    // Filter team leads reporting to selected managers
    const availableTeamLeads = filterUsersByRole("Teamlead", selectedManagers);
    setTeamLeadsArray(availableTeamLeads);

    // Determine employees to show
    let employeesToShow = [];
    if (availableTeamLeads.length > 0) {
      employeesToShow =
        selectedTeamLeads.length > 0
          ? filterUsersByRole("Employee", selectedTeamLeads)
          : filterUsersByRole("Employee", availableTeamLeads);
    }

    // Update employees array
    setEmployeesArray(employeesToShow);
  }, [userList, localData]);

  useEffect(() => {
    roleTypes();
  }, [localData, roleTypes]);

  const handleSelectChange = (name, values) => {
    setLocalData((prevData) => ({
      ...prevData,
      [name]: values,
    }));
    setFilters((prevFilter) => ({
      ...prevFilter,
      location: "",
    }));
  };

  const handleFilter = () => {
    setFilteredData(localData);
    hide();
    setFilterEmployeeName(employeesArray);
  };
  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  const ResetFilter = () => {
    const resetData = {
      managers: [],
      teamleads: [],
      employees: [],
    };

    setFilteredData(resetData);
    setLocalData(resetData);
    setFilterEmployeeName("");
  };

  return (
    <div className="filter-box">
      <div className="filter-body">
        <div className="df filter-section w100">
          {localData && (
            <div className="filtered-data-item flexBox">
              {Object.entries(localData).map(
                ([key, value]) =>
                  Array.isArray(value) &&
                  value.length > 0 && (
                    <div
                      className="flexBox filtered-array df justify-start"
                      key={key}
                    >
                      {key
                        ?.replace(/_/g, " ")
                        ?.replace(/\b\w/g, (l) => l.toUpperCase())}
                      :
                      {value.map((item, index) => (
                        <span
                          key={`${key}-${index}`}
                          className="df filtered-option"
                        >
                          {" "}
                          <span
                            className="filtered-value"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.toLowerCase() || "N/A"}
                          </span>
                          <span
                            onClick={() => removeThisFilter(key, index)}
                            style={{ cursor: "pointer" }}
                          >
                            <CrossIcon />
                          </span>
                        </span>
                      ))}
                    </div>
                  )
              )}
            </div>
          )}
        </div>
        {access?.role_name === "admin" && (
          <SelectSearch
            options={managersArray}
            label="Managers"
            name="managers"
            Placeholder="Select Options"
            value={localData?.managers}
            onChange={(values) => handleSelectChange("managers", values)}
            height={36}
            readOnly={false}
            multiple={true}
            checkbox={true}
          />
        )}

        {["admin", "Manager"].includes(access?.role_name) && (
          <SelectSearch
            options={teamLeadsArray}
            label="Teamlead"
            name="teamleads"
            Placeholder="Select Options"
            value={localData?.teamleads}
            onChange={(values) => handleSelectChange("teamleads", values)}
            height={36}
            readOnly={false}
            multiple={true}
            checkbox={true}
          />
        )}

        {["admin", "Manager", "Teamlead"].includes(access?.role_name) && (
          <SelectSearch
            options={employeesArray}
            label="Employee"
            name="employees"
            Placeholder="Select Options"
            value={localData?.employees}
            onChange={(values) => handleSelectChange("employees", values)}
            height={36}
            readOnly={false}
            multiple={true}
            checkbox={true}
            // dropdownPositionProp={"top"}
          />
        )}
      </div>
      <div className="filter-btn space-between">
        <div>
          <Button
            onclick={ResetFilter}
            btnname={"Clear"}
            cl={"#1e6641"}
            br={4}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            box_s="none"
            fs={15}
          />
        </div>
        <div>
          <Button
            btnname={"Apply Filter"}
            onclick={handleFilter}
            fs={15}
            br={4}
          />
        </div>
      </div>
    </div>
  );
};

export default DashBoardFilter;
