import React from "react";
import Facebook from "../../../../Assets/Quiz/Facebook.svg";
import Twitter from "../../../../Assets/Quiz/TwitterX.svg";
// import Instagram from "../../Assets/Quiz/Instagram.svg";
// import Telegram from "../../Assets/Quiz/Telegram.svg";
import LinkedIn from "../../../../Assets/Quiz/LinkedIn.svg";
import Whatsapp from "../../../../Assets/Quiz/Whatsapp.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const QuizShare = ({ linkUrl, externalUrl, dataUrl }) => {
  const id = sessionStorage.getItem("QuizId");
  const domain = window.location.origin;
  console.log(domain);
  const exurl = linkUrl ? externalUrl : dataUrl;
  const url = domain + "/SpiraQuiz/?pool=" + id;

  return (
    <div className="share-this-text">
      <FacebookShareButton url={exurl || url}>
        <img src={Facebook} alt="fb" draggable="false" />
      </FacebookShareButton>
      <LinkedinShareButton url={exurl || url} source="spira.com">
        <img src={LinkedIn} alt="linkedIn" draggable="false" />
      </LinkedinShareButton>
      <WhatsappShareButton url={exurl || url}>
        <img src={Whatsapp} alt="whatsapp" draggable="false" />
      </WhatsappShareButton>
      <TwitterShareButton url={exurl || url}>
        <img src={Twitter} alt="X" draggable="false" />
      </TwitterShareButton>
    </div>
  );
};

export default QuizShare;
