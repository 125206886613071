import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { rem } from "../../../Components/Rem_func";

Chart.register(ArcElement, Tooltip, Legend);
const Conversion = ({ percentage, outerValue, labelName, innerValue }) => {
  const getBackgroundColor = (percentage) => {
    if (percentage <= 49) {
      return "#d72222"; // Red
    } else if (percentage <= 69) {
      return "#DD7E0D"; // Orange
    } else {
      return "#1E6641"; // Green
    }
  };
  const data = {
    datasets: [
      {
        label: labelName,
        data: [outerValue],
        backgroundColor: ["#1E6641", "#E0E0E0"],
        borderWidth: 0,
        borderRadius: 5,
        hoverOffset: 0,
        cutout: "75%", // Inner circle with smaller cutout to create gap
        radius: "100%",
      },
      {
        labels: ["Awarded", "Pending"],
        data: [percentage, 100 - percentage],
        cutout: "75%", // Defines inner radius to create spacing
        radius: "90%", // Thickness for inner circle
        backgroundColor: [getBackgroundColor(percentage), "#E0E0E0"],
        borderWidth: 0,
        borderRadius: 5,
        hoverOffset: 0,
      },
    ],
  };

  const options = {
    rotation: 185,
    circumference: 350,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const dataIndex = context.dataIndex;
            const label = dataset.labels
              ? dataset.labels[dataIndex]
              : dataset.label;
            const value = context.raw;
            return `${label}: ${value} ${dataset.labels ? "%" : ""}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };
  return (
    <div className="conversion">
      <div className="conv-title">Conversion % Against {labelName}</div>
      <div style={{ width: rem(172), height: rem(172) }} className="conv-graph">
        <Doughnut data={data} options={options} />
        <span className="conv-per">
          {percentage}
          <span>%</span>
        </span>
      </div>
    </div>
  );
};

export default Conversion;
