import React, { useCallback, useEffect, useState } from "react";
import QuizAdmin from "./QuizAdmin";
import StartQuiz from "./StartQuiz";
import QuizQuestions from "./QuizQuestions";
import RankingPage from "./RankingPage";
import { useNavigate } from "react-router-dom";
import _nonAuthHttp from "./../../../../../Utils/Api/_nonAuthHttp";
import TimerPage from "./TimerPage";
import SnackBar from "./../../../../../Components/MobSnackBar";
import ScheduleError from "./ScheduleError";

export default function MainQuiz() {
  const [regData, setRegData] = useState("");
  const [getPool, setGetPool] = useState(0);
  const [quizs, setQuizs] = useState([]);
  const [isFunc, setIsFunc] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [scheduleGetNull, setScheduleGetNull] = useState(false);
  const [quizState, setQuizState] = useState(() => {
    const storedState = {
      register: sessionStorage.getItem("register") === "true",
      quiz: sessionStorage.getItem("quiz") === "true",
      question: sessionStorage.getItem("question") === "true",
      ranking: sessionStorage.getItem("ranking") === "true",
    };

    if (
      !storedState.register &&
      !storedState.quiz &&
      !storedState.question &&
      !storedState.ranking
    ) {
      storedState.register = true;
    }

    return storedState;
  });

  const navigate = useNavigate();

  // const poolNameId = sessionStorage.getItem("poolNameId");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const poolNameId = searchParams.get("pool");

    if (poolNameId) {
      // sessionStorage.setItem("poolNameId", poolNameId);
      setGetPool(poolNameId);
    } else {
      navigate("/access-denied");
    }
  }, [navigate]);

  const fetchQuizData = useCallback(async () => {
    try {
      const res = await _nonAuthHttp.get("/quiz/Get-question-pools");
      setQuizs(res.data);
    } catch (error) {
      console.log(error);
      setError("Internal Server Error");
      setToast({ error: true });
    }
  }, []); // Empty dependency array means this function won't change between renders

  useEffect(() => {
    fetchQuizData();
  }, [fetchQuizData]);

  useEffect(() => {
    sessionStorage.setItem("register", quizState.register);
    sessionStorage.setItem("quiz", quizState.quiz);
    sessionStorage.setItem("question", quizState.question);
    sessionStorage.setItem("ranking", quizState.ranking);
  }, [quizState]);

  useEffect(() => {
    if (quizs.length > 0 && getPool) {
      const findIdHandler = quizs.find((x) => x.id === Number(getPool));

      if (findIdHandler) {
        if (findIdHandler.schedule_time === null) {
          setScheduleGetNull(true);
        }
        const scheduleDate = new Date(findIdHandler.schedule_time); // Scheduled date from API
        const currentTime = new Date(); // Current date

        const timeDifference = scheduleDate.getTime() - currentTime.getTime();
        setTimeLeft(timeDifference);

        const isFutureDate = scheduleDate.getTime() > currentTime.getTime(); // Compare time values directly
        setIsFunc(isFutureDate);
      } else {
        console.error("No quiz found with the specified pool ID.");
      }
    }
  }, [quizs, getPool]); // Only run when quizs and getPool are available

  useEffect(() => {
    if (timeLeft > 0) {
      const timerInterval = setInterval(() => {
        setTimeLeft((prev) => prev - 1000); // Reduce the time left by 1 second (1000 ms)
      }, 1000);

      return () => clearInterval(timerInterval); // Clear the interval when the component unmounts
    } else {
      // Time left is zero or below, navigate to the quiz page
      setIsFunc(false);
    }
  }, [timeLeft]);

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const moveToNextStage = (nextStage) => {
    const newQuizState = {
      register: false,
      quiz: false,
      question: false,
      ranking: false,
      [nextStage]: true,
    };
    setQuizState(newQuizState);
  };

  return (
    <div>
      <>
        {isFunc ? (
          <TimerPage timeLeft={timeLeft} />
        ) : scheduleGetNull ? (
          <ScheduleError />
        ) : (
          quizState.register && (
            <QuizAdmin
              setQuizState={moveToNextStage}
              setRegData={setRegData}
              quizState={quizState}
              getPool={getPool}
            />
          )
        )}
      </>
      {quizState.quiz && (
        <StartQuiz
          setQuizState={moveToNextStage}
          regData={regData}
          quizState={quizState}
          getPool={getPool}
        />
      )}
      {quizState.question && (
        <QuizQuestions setQuizState={moveToNextStage} quizState={quizState} />
      )}
      {quizState.ranking && (
        <RankingPage setQuizState={moveToNextStage} quizState={quizState} />
      )}

      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
}
