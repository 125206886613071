import { React, useEffect, useState } from "react";
import Button from "../../../../Components/Button/Button";
import Delete from "../../../../Assets/Quiz/Delete.svg";
// import { rem } from "../../Components/Rem_func";
import _http from "../../../../Utils/Api/_http";
// import Animation from "../../Assets/Quiz/Animation.gif";
import share from "../../../../Assets/Quiz/Share.svg";
import download from "../../../../Assets/Quiz/Download.svg";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import QuizShare from "./QuizSharePopup";
import SnackBar from "../../../../Components/SnackBar";

const QuizUpload = () => {
  //Question Data
  const navigate = useNavigate();
  const formDataFromStorage = JSON.parse(sessionStorage.getItem("formData"));
  const Type = sessionStorage.getItem("Type");
  //Quiz Uploaded Filename
  //const QuizFileName = sessionStorage.getItem("QuizUpload");
  const [Submitted, setSubmitted] = useState(false);
  const [id, setId] = useState();
  const [qrcode, setQrcode] = useState(false);
  const [qrpng, setQrPng] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [active, setActive] = useState(false);
  const title = formDataFromStorage?.[0].title; // assuming the title is in the first item of the array
  const [PoolData, setPoolData] = useState({
    pool_name: title,
    duration_in_minutes: 30,
    source: Type,
  });
  const [schedule_time, setSchedule_time] = useState("");
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  //formated Questions
  console.log(title);
  useEffect(() => {
    if (title === undefined) {
      navigate("/NotAccess");
    }
  }, [title, navigate]);
  function handleDurationKeyDown(e) {
    // Prevent non-numeric characters except for "Backspace" and "Delete"
    if (!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
      e.preventDefault();
    }
  }

  function handleDurationChange(e) {
    const value = e.target.value;
    const pattern = /^(0[1-9]|[1-9][0-9]?)$/; // allows 01-09, 10-99, but no "00"

    // Check if the input matches the pattern
    if (pattern.test(value)) {
      // Input is valid, update the state
      setPoolData((prevState) => ({
        ...prevState,
        duration_in_minutes: parseInt(value, 10), // Parse the value as an integer
      }));

      // Clear any previous errors
      setError("");
      setToast({ error: false });
    } else if (value === "") {
      // Handle empty input (user clears the input)
      setPoolData((prevState) => ({
        ...prevState,
        duration_in_minutes: "",
      }));

      setError("");
    } else {
      // Input is invalid, show an error message
      setToast({ error: true });
      setError("Invalid duration");
    }
  }

  const Generate_QRCode = async (id) => {
    setLoading(true);
    try {
      const response = await _http.post(
        "/quiz/generate-qr-code",
        {
          id: id,
        },
        {
          responseType: "blob",
        }
      );
      setActive(true);
      const url = URL.createObjectURL(response.data);
      setQrPng(url);
      setTimeout(() => {
        setError("Qr Code Generated");
        setToast({ submit: true });
      }, 3000);
      setLoading(false);
    } catch (err) {
      setActive(false);
      setToast({ error: true });
      setError(err.data);
      setLoading(false);
    }
  };

  const Create_question_Pool = async () => {
    try {
      const response = await _http.post("/quiz/question-pools", PoolData);
      let id = response.data.id;
      sessionStorage.setItem("QuizId", id);
      setId(response.data.id);
      // let pool_name = response.data.pool_name;
      Generate_QRCode(id);
      create_questions(id);
      setQrcode(true);
      sessionStorage.removeItem("Type");
      setError("Quiz Submitted Successfully✅");
      setToast({ submit: true });
    } catch (err) {
      setToast({ error: true });
      setError("Quiz Name Already Exist ❌");
      setTimeout(() => {
        const replacetitle = prompt("Enter Differnent Title");
        if (replacetitle === " ") {
          const prev = PoolData.pool_name;
          setPoolData({ ...PoolData, pool_name: prev });
          alert("Enter valid Quiz Name");
        } else {
          setPoolData({ ...PoolData, pool_name: replacetitle });
        }
      }, 3000);
    }
  };
  async function create_questions(id) {
    // Array to store the formatted questions
    let formattedData = [];

    // Loop through each question object and format it
    for (let i = 0; i < formDataFromStorage.length; i++) {
      let currentQuestion = formDataFromStorage[i];

      let formattedQuestion = {
        pool: id,
        question_text: currentQuestion.question_text,
        choices: [],
      };

      // Loop through the choices array and format them
      for (let j = 0; j < currentQuestion.choices.length; j++) {
        let choice = currentQuestion.choices[j];

        formattedQuestion.choices.push({
          choice_text: choice.choice_text,
          is_correct: choice.is_correct,
        });
      }

      // Push the formatted question into the formattedData array
      formattedData.push(formattedQuestion);
    }
    try {
      await _http.post("/quiz/questions", formattedData);
    } catch (err) {
      setToast({ error: true });
      setError(err.data);
    }
  }

  const HandleChangeData = async () => {
    if (!schedule_time) {
      setToast({ error: true });
      setError("Please Enter Schedule time...");
      return;
    }
    setSubmitted(true);
    try {
      await _http.put("/quiz/question-pools", {
        id: id,
        ...PoolData,
        schedule_time: new Date(schedule_time).toISOString(),
      });
      setToast({ submit: true });
      setError("Quiz Scheduled Successfully✅");

      setTimeout(() => {
        navigate("/SpiraQRE/Quiz");
      }, 2000);
    } catch (err) {
      setToast({ error: true });
      setError("Schedule Time Error");
    }
    setSubmitted(false);
  };
  const getCurrentDateWithTime = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0"); // Get hours in 24-hour format
    const minutes = String(today.getMinutes()).padStart(2, "0"); // Get minutes

    // Format the date and time to 'YYYY-MM-DDTHH:mm'
    return `${yyyy}-${mm}-${dd}T${hours}:${minutes}`;
  };

  const maxDate = getCurrentDateWithTime();
  const deleteQuestionPool = async (id) => {
    setLoading(true);
    try {
      await _http.delete("quiz/question-pools", {
        data: {
          id: id,
        },
      });
      setLoading(false);
      setError("Question Pool Deleted");
      setToast({ submit: true });
      setTimeout(() => {
        navigate("/SpiraQRE/Quiz");
      }, 1000);
    } catch (er) {
      setLoading(false);
    }
  };
  function handleDelete() {
    if (qrcode) {
      deleteQuestionPool(id);
    } else {
      setError("Deleted Successfully ✅");
      setToast({ submit: true });
      setTimeout(() => {
        navigate("/SpiraQRE/Quiz");
      }, 1000);
    }
  }
  return (
    <>
      <div className="uploadcontainer">
        {/* <div className="uploadcreateimg">
        <img src={Create} alt="" />
      </div> */}
        <p className="qre_title">
          QRE / <span> QRE Confiqure</span>
        </p>
        <div className="out_uptitle">
          <div className="upltiltle">
            <h1>
              {PoolData.pool_name}
              {/* <span>C: Laura\My Documents\Welding Quiz 1.pdf</span> */}
            </h1>

            <div className="uplbtns">
              <label htmlFor="duration">
                Duration:
                <input
                  type="text"
                  name="duration"
                  id="duration"
                  placeholder="30"
                  maxLength={2}
                  minLength={2}
                  onKeyDown={handleDurationKeyDown}
                  onChange={handleDurationChange}
                  inputMode="numeric"
                  pattern="[0-9]{1,2}"
                  required
                  readOnly={active ? true : false}
                />
                mins
              </label>
              <label htmlFor="NoQuestions">
                No of Questions
                <input
                  type="text"
                  name="NoQuestions"
                  id="NoQuestions"
                  readOnly={true}
                  value={formDataFromStorage?.length}
                />
              </label>
              <img src={Delete} alt="" onClick={handleDelete} />
            </div>
          </div>
          <div className="qr_bt">
            {!qrcode && (
              <Button
                btnname={!loading ? "Generate QR Code" : "Generating..."}
                height={38}
                width={158}
                onclick={() => Create_question_Pool()}
              />
            )}
          </div>
        </div>
        {loading ? (
          <div className="spinners">
            {/* // <img src={Animation} draggable="false" alt="" /> */}
          </div>
        ) : (
          <div className={qrcode ? "quizuplbtton" : ""}>
            {qrcode && (
              <div className="qrcodes">
                {qrpng && (
                  <div className="qrmain">
                    <img src={qrpng} alt="QR Code" className="qrpngsclass" />
                    <div className="quizsocial">
                      <Tooltip
                        title={<QuizShare />}
                        placement={"bottom"}
                        color="#fff"
                      >
                        <img src={share} alt="" className="sharepng" />
                      </Tooltip>
                      <a
                        href={qrpng}
                        download={`QRCode_${title}.png`}
                        onClick={(e) => {
                          const linker = document.createElement("a");
                          linker.href = qrpng;
                          linker.download = `QRCode_${title}.png`;
                          linker.click();
                        }}
                      >
                        <img src={download} alt="" />
                      </a>
                    </div>
                  </div>
                )}
                <div className="date_field">
                  <label>schedule</label>
                  <div className="datetime-wrapper">
                    <input
                      type="datetime-local"
                      name="date-time"
                      id="custom-datetime"
                      value={schedule_time}
                      min={maxDate}
                      onChange={(e) => {
                        setSchedule_time(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="qrcodesDates">
                  <Button
                    btnname={Submitted ? "Submiting..." : "Submit"}
                    height={38}
                    fs={15}
                    fw={500}
                    br={5}
                    onclick={HandleChangeData}
                    disabled={Submitted}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handletoast}
          Open={toast?.error}
        />
        <SnackBar
          message={errors}
          severity={"success"}
          handleClose={handletoast}
          Open={toast?.submit}
        />
      </div>
    </>
  );
};
export default QuizUpload;
