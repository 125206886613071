import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./QualityHome.scss";
import _http from "../../../Utils/Api/_http";
import SnackBar from "../../../Components/SnackBar";
import { Doughnut } from "react-chartjs-2";
import { rem } from "../../../Components/Rem_func";
import { Dropdown, Space, Menu } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const QualityHome = () => {
  const [quality, setQuality] = useState([]);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [branch, setBranch] = useState("");

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const fetchData = async () => {
    try {
      const response = await _http.get("/api/mtc-file-count");
      setQuality(response.data.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching MTC Generated Data");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const last12Months = Array.from({ length: 12 }, (_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i); // Go back i months
    return {
      full: `${date.toLocaleString("default", {
        month: "short",
      })} ${date.getFullYear()}`, // Month + Year for matching
      label: date.toLocaleString("default", { month: "short" }), // Month for display
    };
  }).reverse();

  const calculatePeriodMetrics = (data, periodType) => {
    const today = new Date();
    let currentTotal = 0;
    let previousTotal = 0;

    switch (periodType) {
      case "Week": {
        const currentDay = today.getDay();
        const thisWeekMonday = new Date(today);
        thisWeekMonday.setDate(
          today.getDate() - (currentDay === 0 ? 6 : currentDay - 1)
        );
        thisWeekMonday.setHours(0, 0, 0, 0);

        const previousWeekSunday = new Date(thisWeekMonday);
        previousWeekSunday.setDate(thisWeekMonday.getDate() - 1);
        const previousWeekMonday = new Date(previousWeekSunday);
        previousWeekMonday.setDate(previousWeekSunday.getDate() - 6);

        currentTotal =
          data
            ?.filter((entry) => {
              const entryDate = new Date(entry.mtc_date);
              return entryDate >= thisWeekMonday && entryDate <= today;
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;

        previousTotal =
          data
            ?.filter((entry) => {
              const entryDate = new Date(entry.mtc_date);
              return (
                entryDate >= previousWeekMonday &&
                entryDate <= previousWeekSunday
              );
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;
        break;
      }

      case "Month": {
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;

        currentTotal =
          data
            ?.filter((entry) => {
              const entryDate = new Date(entry.mtc_date);
              return (
                entryDate.getMonth() === currentMonth &&
                entryDate.getFullYear() === currentYear
              );
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;

        previousTotal =
          data
            ?.filter((entry) => {
              const entryDate = new Date(entry.mtc_date);
              return (
                entryDate.getMonth() === previousMonth &&
                entryDate.getFullYear() === previousYear
              );
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;
        break;
      }
      case "Year": {
        const getMonthDifference = (date) => {
          const targetDate = new Date(date);
          return (
            (today.getFullYear() - targetDate.getFullYear()) * 12 +
            (today.getMonth() - targetDate.getMonth())
          );
        };

        currentTotal =
          data
            ?.filter((entry) => {
              if (!entry.mtc_date) return false;
              const monthsAgo = getMonthDifference(entry?.mtc_date);
              return monthsAgo <= 12 && monthsAgo >= 0;
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;
        previousTotal =
          data
            ?.filter((entry) => {
              if (!entry.mtc_date) return false;
              const monthsAgo = getMonthDifference(entry.mtc_date);
              return monthsAgo > 12 && monthsAgo <= 24;
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;
        break;
      }
      default: {
        const getMonthDifference = (date) => {
          const targetDate = new Date(date);
          return (
            (today.getFullYear() - targetDate.getFullYear()) * 12 +
            (today.getMonth() - targetDate.getMonth())
          );
        };

        currentTotal =
          data
            ?.filter((entry) => {
              if (!entry.mtc_date) return false;
              const monthsAgo = getMonthDifference(entry?.mtc_date);
              return monthsAgo <= 12 && monthsAgo >= 0;
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;
        previousTotal =
          data
            ?.filter((entry) => {
              if (!entry.mtc_date) return false;
              const monthsAgo = getMonthDifference(entry.mtc_date);
              return monthsAgo > 12 && monthsAgo <= 24;
            })
            ?.reduce((sum, entry) => sum + entry.heat_count, 0) || 0;
        break;
      }
    }
    // const percentageChange =
    //   previousTotal === 0 && currentTotal === 0
    //     ? 0
    //     : previousTotal === currentTotal
    //     ? 100
    //     : previousTotal === 0 && currentTotal > 0
    //     ? 100
    //     : previousTotal === 0 && currentTotal === 1
    //     ? 100
    //     : Math.round(((currentTotal - previousTotal) / previousTotal) * 100, 2)
    // ;
    // const totalPreviousPercentage = (previousTotal / currentTotal) * 100;
    // const totalCurrentPercentage = 100;
    // const percentageChanged = previousTotal === 0 && currentTotal === 0;
    const percentageChange =
      previousTotal === 0 && currentTotal === 0
        ? 0
        : previousTotal === currentTotal
        ? 100
        : previousTotal === 0 && currentTotal > 0
        ? 0
        : Math.max(
            -100,
            Math.min(
              100,
              Math.round(((currentTotal - previousTotal) / previousTotal) * 100)
            )
          );
    return {
      currentTotal,
      previousTotal,
      percentageChange,
    };
  };

  const DataMetrics = calculatePeriodMetrics(quality, branch);

  const dynamicData = last12Months.map(({ full }) => {
    const match = quality?.filter((item) => {
      if (!item.mtc_date) return false;

      const itemDate = new Date(item.mtc_date);
      const itemMonthYear = `${itemDate.toLocaleString("default", {
        month: "short",
      })} ${itemDate.getFullYear()}`;

      return itemMonthYear === full; // Match by full key (month and year)
    });

    return match?.reduce((total, item) => total + item.heat_count, 0) || 0;
  });
  const labels = last12Months.map(({ label }) => label);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "MTC's Generated",
        data: dynamicData,
        backgroundColor: "rgba(30, 102, 65, 0.28)",
        hoverBackgroundColor: "#1e6641",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.3,
        categoryPercentage: 0.9,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        align: "start",
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#454545",
          font: {
            size: 7,
          },
          maxRotation: 0,
          minRotation: 0,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#454545",
          font: {
            size: 6,
          },
          maxTicksLimit: 4,
        },
        border: {
          display: false,
        },
      },
    },
  };

  const data1 = {
    datasets: [
      {
        labels:
          branch === "Year"
            ? ["This year", "Last Year"]
            : branch === "Month"
            ? ["This Month", "Last Month"]
            : branch === "Week"
            ? ["This Week", "Last Week"]
            : ["This Year", "Last Year"],
        data: [DataMetrics.currentTotal, DataMetrics.previousTotal],

        backgroundColor: ["#1E6641", "#E0E0E0"],

        borderWidth: 0,
        hoverOffset: 0,
        cutout: "75%", // Inner circle with smaller cutout to create gap
        radius: "100%",
      },
    ],
  };

  const options1 = {
    rotation: 180, // Start from bottom center
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const dataIndex = context.dataIndex;
            const label = dataset.labels
              ? dataset.labels[dataIndex]
              : dataset.label;
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  const handleSelectBranch = ({ key }) => {
    // If "All" is selected, clear the branch selection
    if (key === branch) {
      setBranch("");
    } else {
      setBranch(key);
    }
  };

  const branchMenu = (
    <Menu
      onClick={handleSelectBranch}
      items={[
        { key: "Week", label: "Week" },
        { key: "Month", label: "Month" },
        { key: "Year", label: "Year" },
      ]}
    />
  );

  return (
    <div className="quality_home">
      <div className="upper_quality">
        <p className="userM">Quality</p>
        <p className="mtc_title">MTC's Generated</p>
      </div>
      <div className="df justify-end">
        {/* {access.role_name === "admin" && ( */}
        <div className="branch">
          <Dropdown overlay={branchMenu}>
            <Space>
              <span className="branch">{branch || "Filter"}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(19)}
                height={rem(19)}
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                  fill="#AAAAAA"
                />
              </svg>
            </Space>
          </Dropdown>
        </div>
        {/* )} */}
      </div>

      <div className="percentage_change">
        <div className="conv-graph">
          <Doughnut data={data1} options={options1} />
          <span
            className="conv-per"
            style={
              DataMetrics.percentageChange < 0
                ? { color: "red" }
                : { color: "#1E6641" }
            }
          >
            {DataMetrics.percentageChange < 0 ? "" : "+"}
            {DataMetrics.percentageChange}%
          </span>
        </div>
      </div>

      <div
        className="barChat"
        // style={{ height: rem(230), width: "100%", overflowX: "auto" }}
      >
        <Bar data={data} options={options} />
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
};

export default QualityHome;
