import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
import spiraLogo from "../../Assets/NavbarIcon/spira 1.svg";
import SnackBar from "../../Components/SnackBar";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState();
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState("");
  const [open, setOpen] = useState({ submit: false, error: false });

  const navigate = useNavigate();

  const handleClose = (state) => {
    setOpen({ ...state, open: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the regex patterns based on input type
    let pattern;
    if (name === "spiraEmail") {
      pattern = /^[a-z0-9.@]*$/;
    }

    // Allow empty value to let the user delete input
    if (!pattern || value === "" || pattern.test(value)) {
      setEmail(value.replace(/\s+/g, " "));
    }
  };

  const ForgetPasswordHandler = async (e) => {
    e.preventDefault();
    if (!email) {
      setOpen({ error: true });
      setErrors("Please enter email");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setOpen({ error: true });
      setErrors("Please enter a valid email");
      return;
    }

    if (email?.split("@")[1] !== "spirapower.com") {
      setOpen({ error: true });
      setErrors("Please enter a valid email");
      return;
    }

    const formData = new FormData();
    formData.append("operation", "forgot_password");
    formData.append("email", email.toLowerCase());
    setloading(true);

    try {
      const res = await _nonAuthHttp.post(
        "/login-register-forgot-password",
        formData
      );
      if (res.status === 200) {
        setOpen({ submit: true });
        setErrors(res?.data?.message);
        setTimeout(() => {
          navigate("/Login");
        }, [3000]);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        setOpen({ error: true });
        setErrors(error.response.data.error);
      } else {
        setOpen({ error: true });
        setErrors("Server error please try again");
      }
    }
    setloading(false);
  };

  return (
    <div className="newLogin flexBox login">
      {/* <div className="left flexBox">
        <img src={LoginImage} alt="CompanyLogo"></img>
      </div> */}
      <div className="login-logo login-title">
        <img src={spiraLogo} alt="img" />
      </div>
      <div className={`right flexBox`}>
        <div className={`login-card `}>
          <form className="login-card-form">
            <h1> Generate New Password </h1>

            <label>Spira Email ID</label>
            <div className="password-input txtBox barSty">
              <input
                type="text"
                name="spiraEmail"
                id="spiraEmail"
                placeholder="johndoe@spirapower.com"
                value={email}
                onChange={handleChange}
                // className="txtBox"
                readOnly={loading}
              />
            </div>
            <SnackBar
              message={errors}
              severity={"error"}
              handleClose={handleClose}
              Open={open.error}
            />
            <SnackBar
              message={errors}
              severity={"success"}
              handleClose={handleClose}
              Open={open.submit}
            />

            <div className="login-btn">
              {/* Login button component with loading state handling */}
              <Button
                disabled={loading}
                onclick={ForgetPasswordHandler}
                btnname={loading ? "Sending..." : "Create New Password"}
                width={"100%"}
                height={48}
                fs={14}
                fw={600}
                br={4}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    ForgetPasswordHandler();
                  }
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
