import { Modal } from "antd";
import Button from "../../../../Components/Button/Button";
import React, { useEffect, useRef, useState } from "react";
import { rem } from "../../../../Components/Rem_func";
import Addicon from "./../../../../Assets/Quiz/Add Icon.svg";
import Delete from "../../../../Assets/Quiz/Delete.svg";
import SnackBar from "../../../../Components/SnackBar";
import { useNavigate } from "react-router-dom";
import _http from "../../../../Utils/Api/_http";

const QuizPopup = ({
  formdata,
  setFormdata,
  recent,
  open,
  setOpen,
  handleClose,
  poolname,
  Get_Recent_Question,
  RecentQuestion,
  Pools,
}) => {
  // const title = sessionStorage.getItem("QuestionTitle")
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [Submit, SetSubmit] = useState(false);
  const [edit, setEdit] = useState(true);
  const [editableIndexes, setEditableIndexes] = useState([]); // Track which questions are editable
  const MAX_VALUE = 350;
  const textareaRef = useRef(null);
  const [schedule_timed, setSchedule_time] = useState("");
  const [duration, setDuration] = useState();
  const [changes, setChanges] = useState(false);
  const [schchanges, setSchchanges] = useState(false);
  const id = Pools?.id;

  const [PoolData, setPoolData] = useState({
    pool_name: Pools?.pool_name,
    duration_in_minutes: duration,
    schedule_time: schedule_timed,
  });

  useEffect(() => {
    if (Pools?.schedule_time) {
      // Parse the UTC date and convert it to local time
      const utcDate = new Date(Pools.schedule_time);
      // Check if the date is valid
      if (!isNaN(utcDate.getTime())) {
        // Format the date to YYYY-MM-DDTHH:MM (ISO format for datetime-local input)
        const localISOTime = new Date(
          utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16);
        setSchedule_time(localISOTime);
      }
    }
    setDuration(Pools?.duration_in_minutes);
  }, [Pools, open]);
  const navigate = useNavigate();
  //add question
  function addQuestionModel() {
    const newQuestion = {
      question_text: "", // New question placeholder
      choices: [
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
      ], // Default options
    };
    setFormdata([...formdata, newQuestion]); // Add the new question to the state
  }
  //delete question
  const deleteQuestionModel = async (index, questionid) => {
    if (recent && edit) {
      try {
        await _http.delete("/quiz/questions", {
          data: { id: questionid },
        });
        setToast({ submit: true });
        setError("Deleted Successfully");
        Get_Recent_Question(formdata[0].pool);
      } catch (err) {
        setToast({ error: true });
        setError("Delete Failed");
      }
    } else {
      const updatedQuestions = formdata.filter((_, i) => i !== index); // Remove the question by index
      setFormdata([...updatedQuestions]); // Update the state with the remaining questions
    }
  };
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const textvalidate = (e, index) => {
    const textarea = e.target; // Reference to the textarea
    const value = textarea.value; // Get the current value from the textarea
    const newFormData = [...formdata]; // Create a copy of the existing formdata

    // Limit the value to 250 characters
    const maxLength = 250;
    if (value.length > maxLength) {
      // Trim the value to the maximum length if it exceeds
      textarea.value = value.substring(0, maxLength);
      setToast({ error: true });
      setError(
        `The question exceeds the maximum limit of ${maxLength} characters`
      );
      // Set error message
      return; // Exit if the limit is exceeded
    } else {
      setError(""); // Clear error message if within limit
    }

    // Logic for breaking text into lines
    const words = textarea.value.split(" ");
    let currentLine = "";
    const lines = [];

    for (const word of words) {
      if ((currentLine + word).length <= MAX_VALUE) {
        currentLine += (currentLine ? " " : "") + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    if (currentLine) {
      lines.push(currentLine);
    }

    const finalText = lines.join("\n");
    textarea.value = finalText; // Update the textarea value
    newFormData[index].question_text = finalText;
    setFormdata(newFormData);
    adjustTextareaHeight(textarea); // Adjust height after updating value
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto"; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scrollHeight
  };
  //whole data
  function questionvalidate(e, index) {
    const Max_title = 30;
    const value = e.target.value;
    if (value.length <= Max_title) {
      const values = [...formdata];
      values[index].title = value;
      setFormdata(values);
    } else {
      setToast({ error: true });
      setError("Title exceeds the maximum limit of 40 characters ❌");
    }
  }

  //handle Options
  function handleOption(e, index, choiceIndex) {
    const newFormData = [...formdata];
    newFormData[index].choices = newFormData[index].choices.map(
      (choice, i) => ({
        ...choice,
        is_correct: i === choiceIndex,
      })
    );

    setFormdata(newFormData);
  }
  const getCurrentDateWithTime = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0"); // Get hours in 24-hour format
    const minutes = String(today.getMinutes()).padStart(2, "0"); // Get minutes

    // Format the date and time to 'YYYY-MM-DDTHH:mm'
    return `${yyyy}-${mm}-${dd}T${hours}:${minutes}`;
  };

  const maxDate = getCurrentDateWithTime();

  const HandleSubmit = async () => {
    const MinTitleLength = 5;
    const MinQuestionLength = 10;
    const MinChoiceLength = 1;

    // Validate title if `recent` is false
    if (
      !recent &&
      (!formdata[0]?.title || formdata[0]?.title.trim().length < MinTitleLength)
    ) {
      setToast({ error: true });
      setError(`Title must Consist of ${MinTitleLength} Characters ❌`);
      return;
    }

    // Validate that all questions and choices meet the criteria
    const allQuestionsAnswered = formdata.every((question, questionIndex) => {
      // Validate question text
      if (
        !question.question_text ||
        question.question_text.trim().length < MinQuestionLength
      ) {
        setToast({ error: true });
        setError(
          `Question ${
            questionIndex + 1
          } must Consist of ${MinQuestionLength} Character ❌`
        );
        return false;
      }

      // Validate that at least one choice is marked as correct
      const hasAnswer = question.choices.some((choice) => choice.is_correct);
      if (!hasAnswer) {
        setToast({ error: true });
        setError(
          ` Question ${
            questionIndex + 1
          } Choices must Contain one Correct Answer ❌`
        );
        return false;
      }

      // Validate choice text
      const allChoicesValid = question.choices.every((choice, choiceIndex) => {
        if (
          !choice.choice_text ||
          choice.choice_text.trim().length < MinChoiceLength
        ) {
          setToast({ error: true });
          setError(
            `Choices in Question ${
              questionIndex + 1
            } must Consists of ${MinChoiceLength} characters ❌`
          );
          return false;
        }
        return true;
      });

      return allChoicesValid;
    });

    if (!allQuestionsAnswered) {
      return;
    }

    // If all validations pass, proceed with submission logic
    try {
      if (!recent) {
        // Navigate after delay if not recent
        setTimeout(() => {
          navigate("/SpiraQRE/Upload");
        }, 1000);
      } else {
        if (schchanges) {
          SetSubmit(true);
          duration_ScheduleChange();
          SetSubmit(false);
        }

        if (edit) {
          if (changes) {
            SetSubmit(true);
            await handleEditSubmit();
            SetSubmit(false);
          }
        } else {
          await handleNewSubmit();
        }
      }

      // Save form data in sessionStorage
      sessionStorage.setItem("formData", JSON.stringify(formdata));
    } catch (err) {
      setToast({ error: true });
      setError("Submission failed");
    }
  };

  // Helper function for handling edit submissions
  async function handleEditSubmit() {
    try {
      await _http.put("/quiz/questions", formdata);
      setError("✅Changes Submitted");
      setToast({ submit: true, error: false });
      textareaRef.current.rows = 1;
      setChanges(false);
    } catch (err) {
      setToast({ error: true });
      setError("Data Not Updated");
    }
  }

  // Helper function for handling new submissions
  async function handleNewSubmit() {
    const filteredFormdata = formdata.filter((item) => item?.id === undefined);
    const updatedFormdata = filteredFormdata.map((item) => ({
      ...item,
      pool: Pools?.id,
    }));

    if (updatedFormdata.length === 0) return;

    SetSubmit(true);
    try {
      await _http.post("/quiz/questions", updatedFormdata);
      setError("✅Added Changes");
      setToast({ submit: true, error: false });
      textareaRef.current.rows = 1;
      setChanges(false);
    } catch (err) {
      setToast({ error: true });
      setError("Data Not Updated");
    } finally {
      SetSubmit(false);
    }
  }

  function handleOptionTextChange(e, index, choiceIndex) {
    const Max_choice = 100;
    const value = e.target.value;
    if (value.length <= Max_choice) {
      const newFormData = [...formdata];
      newFormData[index].choices[choiceIndex].choice_text = e.target.value;
      setFormdata(newFormData);
    } else {
      setToast({
        error: true,
      });
      setError("The choice exceeds the maximum limit of 100 characters ❌ ");
    }
  }

  function addrecentQuestions(index) {
    if (recent) {
      // Only allow adding questions if recent is true
      const newQuestion = {
        question_text: "", // New question placeholder
        choices: [
          { choice_text: "", is_correct: false },
          { choice_text: "", is_correct: false },
          { choice_text: "", is_correct: false },
          { choice_text: "", is_correct: false },
        ], // Default options
      };
      setFormdata([...formdata, newQuestion]); // Add the new question to the state
      setEditableIndexes([...editableIndexes, formdata.length]); // Add new index to editableIndexes
    }
  }
  const checker = (para) => {
    if (recent) {
      // Only apply if recent is true
      if (para === "edit") {
        setEdit(true);

        if (para === "edit") {
          // If edit is true, make all questions editable
          setEditableIndexes(formdata.map((_, index) => index));
        }
      } else {
        // If edit is false, only new questions will be editable
        setEditableIndexes([]);
        setEdit(false);
        if (changes) {
          // eslint-disable-next-line no-restricted-globals
          const QuizChanges = confirm("Do You Want Submit Changes!");
          if (QuizChanges) {
            setError("Submitted Changes");
            setToast({ submit: true });
            HandleSubmit();
            duration_ScheduleChange();
            setChanges(false);
          } else {
            Get_Recent_Question(formdata[0].pool);
          }
        } else {
          setToast({ submit: true });
          setError("No Changes Made ❌");
        }
      }
      setToast({ submit: true, error: false });
      setError(para === "edit" ? "Edit Mode Enabled" : "Add Mode Enabled");
    }
  };

  // function handleDurationKeyDown(e) {
  //   // Prevent non-numeric characters except for "Backspace" and "Delete"
  //   if (!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
  //     e.preventDefault();
  //   }
  // }

  function handleDurationChange(e) {
    const value = e.target.value;

    // Check if the input is empty first
    if (value === "") {
      setPoolData((prevState) => ({
        ...prevState,
        duration_in_minutes: "",
      }));
      setDuration(""); // Clear the duration state as well
      setError("");
      setToast({ error: false }); // Clear any errors/toasts
      return; // Exit the function early
    }

    const pattern = /^(0?[1-9]|[1-9][0-9])$/; // Allows 01-09, 10-99, but no "00"

    // Now check if the input matches the pattern
    if (pattern.test(value)) {
      // Input is valid, update the state
      setPoolData((prevState) => ({
        ...prevState,
        duration_in_minutes: parseInt(value, 10), // Parse the value as an integer
      }));
      setDuration(parseInt(value, 10));

      // Clear any previous errors
      setError("");
      setToast({ error: false });
    } else {
      // Input is invalid, show an error message
      setToast({ error: true });
      setError("Invalid duration");
    }
  }

  const duration_ScheduleChange = async () => {
    try {
      await _http.put("/quiz/question-pools", {
        id: id,
        ...PoolData,
        duration_in_minutes: duration,
        schedule_time: new Date(schedule_timed).toISOString(),
      });
      Get_Recent_Question(id);
      setSchchanges(false);
      RecentQuestion();
      setToast({ submit: true });
      setError("Changes Saved ✅");
    } catch (err) {
      setToast({ error: true });
      setError("Schedule_time Error");
    }
  };
  useEffect(() => {
    if (open) {
      const firstFocusableElement = document.querySelector(".edit-popup input");
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [open]);
  return (
    <Modal
      centered
      open={open}
      onCancel={handleClose}
      className="edit-quiz-popup edit-popup"
      width={rem(1163)}
      height={rem(608)}
    >
      <div className="model-down">
        <div className="model-title">
          <div className="quiz-title">
            <div className="quiz-titleHeader">
              <label>
                <input
                  type="text"
                  name="title"
                  className="Question-Title"
                  placeholder="Enter the Quiz title here"
                  value={
                    poolname && poolname.trim() !== ""
                      ? poolname
                      : formdata[0]?.title
                  }
                  onChange={(e) => {
                    questionvalidate(e, 0);
                  }}
                  readOnly={recent ? true : false}
                />
              </label>
              {/* {recent && <Toggle onchange={(e) => checker(e.target.checked)} />} */}
              {recent && (
                <div className="checkbox">
                  <label>
                    <input
                      type="radio"
                      name="togglebtns"
                      id="edit"
                      checked={edit}
                      onChange={(e) => checker("edit")}
                    />
                    Edit
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="togglebtns"
                      id="add"
                      onChange={(e) => checker("add")}
                    />
                    Add
                  </label>
                </div>
              )}
              {!recent && (
                <div className="Noquestions">
                  <span>No of Questions</span>
                  <span>{formdata?.length}</span>
                </div>
              )}
            </div>
          </div>
          <div className="model-duration">
            {recent && (
              <>
                <div className="model-durmain">
                  {" "}
                  Scheduled On
                  {Pools && (
                    <span
                      className="custom-icon1"
                      onClick={() => {
                        edit &&
                          document
                            .getElementById("custom-datetimeModel")
                            .showPicker();
                      }}
                    >
                      <input
                        type="datetime-local"
                        name="date-time"
                        id="custom-datetimeModel"
                        min={maxDate}
                        style={{ pointerEvents: "none" }}
                        value={schedule_timed}
                        onChange={(e) => {
                          setSchedule_time(e.target.value);
                          setSchchanges(true);
                          setChanges(true);
                        }}
                        readOnly={!edit ? true : false}
                        className="scheduletime"
                      />
                    </span>
                  )}
                </div>

                <span className="model-durmain">
                  Duration
                  <span className="model-dur">
                    <input
                      type="text"
                      name="duration"
                      placeholder={duration}
                      value={duration}
                      maxLength={2}
                      minLength={1}
                      // onKeyDown={handleDurationKeyDown}
                      onChange={(e) => {
                        handleDurationChange(e);
                        setSchchanges(true);
                        setChanges(true);
                      }}
                      inputMode="numeric"
                      pattern="[0-9]{1,2}"
                      required
                      readOnly={!edit ? true : false}
                    />
                  </span>
                  <p>mins</p>
                </span>

                <span className="model-durmain">
                  No. of questions
                  <span className="model-dur">
                    <input
                      type="text"
                      contentEditable="false"
                      placeholder="X/X"
                      value={formdata?.length}
                      className="Noquestions"
                      readOnly={true}
                      suppressContentEditableWarning={true}
                    />
                  </span>
                </span>
                <span>
                  {/* <CheckBox
                    direction={"vertical"}
                    checked={"Edit"}
                    unchecked={"Add"}
                    handleBg={"#1e6641"}
                    onchange={checker}
                    width={rem(55)}
                    height={rem(20)}
                  /> */}
                </span>
              </>
            )}
          </div>
        </div>

        {/* model-section  */}
        <div className="model-form">
          {formdata?.map((info, index) => (
            <span key={index}>
              <form className="model-data" key={info?.id}>
                <label htmlFor="questionname">
                  {index + 1 + "."}
                  <textarea
                    type="textarea"
                    name="questionname"
                    id="questionname"
                    ref={textareaRef}
                    className="testSty"
                    rows={1}
                    cols={2}
                    maxrows={5}
                    placeholder="Enter the question here"
                    value={info?.question_text}
                    onChange={(e) => {
                      textvalidate(e, index);
                      setChanges(true);
                    }}
                    readOnly={
                      recent
                        ? !edit
                          ? !editableIndexes.includes(index)
                          : false
                        : false
                    } // Apply recent condition
                  />
                </label>
                {info?.choices.map((op, id) => (
                  <div className="model-radio" key={id}>
                    <label key={id}>
                      <input
                        type="radio"
                        value={op?.choice_text}
                        name={`quizoption_${index}`}
                        contentEditable="true"
                        checked={op?.is_correct}
                        onChange={(e) => {
                          handleOption(e, index, id);
                          setChanges(true);
                        }}
                        disabled={
                          recent
                            ? !edit
                              ? !editableIndexes.includes(index)
                              : false
                            : false
                        }
                      />

                      <input
                        type="text"
                        value={op?.choice_text}
                        placeholder={`${
                          String.fromCharCode(97 + id).toUpperCase() + ".  "
                        } Enter the option here.`}
                        onChange={(e) => {
                          handleOptionTextChange(e, index, id);
                          setChanges(true);
                        }}
                        autoComplete="off"
                        readOnly={
                          recent
                            ? !edit
                              ? !editableIndexes.includes(index)
                              : false
                            : false
                        }
                      />
                    </label>
                  </div>
                ))}
              </form>
              {recent ? (
                !edit && info.id ? (
                  ""
                ) : (
                  <span className="quizmoddel">
                    <img
                      src={Delete}
                      alt=""
                      onClick={() => deleteQuestionModel(index, info?.id)}
                    />
                  </span>
                )
              ) : (
                formdata?.length > 1 && (
                  <span className="quizmoddel">
                    <img
                      src={Delete}
                      alt=""
                      onClick={() => deleteQuestionModel(index, info?.id)}
                    />
                  </span>
                )
              )}
            </span>
          ))}
          {recent ? (
            <>
              {!edit && (
                <span className="quizmoddel">
                  <img
                    src={Addicon}
                    alt=""
                    onClick={(index) => addrecentQuestions(index)}
                  />
                </span>
              )}
            </>
          ) : (
            <span className="quizmoddel">
              <img src={Addicon} alt="" onClick={addQuestionModel} />
            </span>
          )}
        </div>
        <div className="quizmodelbtns">
          {/* <Button
            btnname="Preview"
            bg="#FFFFFF"
            cl="#1E6641"
            width={130}
            height={50}
            fs={14}
            fw={500}
            border={`${rem(2)} solid #1E6641`}
          /> */}
          <Button
            btnname={Submit ? "Submitting..." : "Submit"}
            width={119}
            height={38}
            border={`${rem(1)} solid #1E6641`}
            br={5}
            fs={15}
            fw={500}
            disabled={Submit}
            onclick={() => HandleSubmit()}
          />
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </Modal>
  );
};

export default QuizPopup;
