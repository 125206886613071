// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  font-family: "Inter";
}

html {
  scroll-behavior: smooth;
}

body {
  /* height: 100%; */
  /* width: 100vw; */
  overflow: auto;
  cursor: default;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EACA,qBAAA;EACA,qBAAA;EACA,oBAAA;AAAF;;AAIA;EACE,uBAAA;AADF;;AAIA;EACE,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,uBAAA;EACA,mCAAA;EACA,kCAAA;AADF;;AAIA;EACE,qBAAA;AADF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n// @import url(\"https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap\");\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  text-decoration: none;\n  list-style-type: none;\n  font-family: \"Inter\";\n  // font-family: \"Outfit\";\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\nbody {\n  /* height: 100%; */\n  /* width: 100vw; */\n  overflow: auto;\n  cursor: default;\n  scroll-behavior: smooth;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\na {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
