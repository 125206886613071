const SalesTable = ({ employeesArray, formatNumber }) => {
  // Sort employeesArray based on the count in descending order
  const sortedEmployees = employeesArray?.name
    ?.map((employeeName, index) => ({
      name: employeeName,
      count: employeesArray.count[index] || 0,
      orderCount: employeesArray.order_count[index] || 0,
      quoteCount: employeesArray.quote_count[index] || 0,
      orderValueSum: employeesArray.orderValueSums[index] || {
        USD: 0,
        AED: 0,
        OMR: 0,
      },
    }))
    .sort((a, b) => b.count - a.count);

  return (
    <div className="quote_home sales-dashboard-table ">
      <div className="sales-table">
        <table>
          <thead>
            <tr>
              <th className="tl">Sales Team Member</th>
              <th>Enquiries</th>
              <th>Conversion %(Enquiries)</th>
              <th>Conversion %(Quoted)</th>
              <th>Awarded-AED</th>
              <th>Awarded-USD</th>
              <th>Awarded-OMR</th>
            </tr>
          </thead>
          <tbody>
            {sortedEmployees?.length ? (
              sortedEmployees.map((employee, index) => {
                const { name, count, orderCount, quoteCount, orderValueSum } =
                  employee;
                const { USD, AED, OMR } = orderValueSum;
                return (
                  <tr key={index}>
                    <td className="textCaps tl">{name || "DataNR"}</td>
                    <td>{count}</td>
                    <td>
                      {orderCount
                        ? `${((orderCount / count) * 100)?.toFixed(2)}%`
                        : "0.00%"}
                    </td>
                    <td>
                      {quoteCount || orderCount
                        ? `${(
                            (orderCount / (quoteCount + orderCount)) *
                            100
                          )?.toFixed(2)}%`
                        : "0.00%"}
                    </td>
                    <td>{formatNumber(AED.toFixed(2))}</td>
                    {/* Order Value Sum in AED */}
                    <td>{formatNumber(USD.toFixed(2))}</td>
                    {/* Order Value Sum in USD */}
                    <td>{formatNumber(OMR.toFixed(2))}</td>
                    {/* Order Value Sum in OMR */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">No Sales Person found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesTable;
