import React, { useState, useEffect } from "react";
import "./FilterEnquires.scss";
import Button from "../../../../Components/Button/Button";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";

const FilterEnquires = ({
  hide,
  filteredData,
  setFilteredData,
  title,
  setFilters,
}) => {
  // Function to handle filter application
  const [localData, setLocalData] = useState(filteredData);

  const handleFilter = () => {
    const dateFields = [
      "client_email_from",
      "client_email_to",
      "sales_email_from",
      "sales_email_to",
      "ack_email_from",
      "ack_email_to",
      "quotation_email_from",
      "quotation_email_to",
      "order_date_from",
      "order_date_to",
      "reject_date_from",
      "reject_date_to",
    ];

    // Check if any date-related field is filled
    const isDateFieldFilled = dateFields.some(
      (field) => localData[field]?.trim() !== ""
    );

    if (isDateFieldFilled) {
      // Reset specific filters if any date-related field is filled
      setFilters((prevFilters) => ({
        ...prevFilters,
        quick_filter: "",
        year: "",
        quarter: "",
      }));
    }

    // Apply the filtered data logic
    setFilteredData(localData);
    hide();
  };
  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  // Function to reset filters
  const ResetFilter = () => {
    // Reset filtered data
    const resetData = {
      ack_number: "",
      sales_person_name: "",
      client_person_name: "",
      client_subject: "",
      client_email_from: "",
      client_email_to: "",
      sales_email_from: "",
      sales_email_to: "",
      ack_email_from: "",
      ack_email_to: "",
      quotation_email_from: "",
      quotation_email_to: "",
      order_date_from: "",
      order_date_to: "",
      max_quote_value: "",
      min_quote_value: "",
      min_order_value: "",
      max_order_value: "",
      reject_date_from: "",
      reject_date_to: "",
    };
    setLocalData(resetData);
    setFilteredData(resetData);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const maxDate = getCurrentDate();
  // Handle removing a filter
  const removeThisFilter = (key) => {
    setLocalData((prev) => {
      const updatedFilters = { ...prev, [key]: "" }; // Clear the filter
      return updatedFilters;
    });
  };
  return (
    <div className="filter-box">
      <div className="filter-body tablefilter">
        <div className="df filter-section">
          {localData && (
            <div className="filtered-data-item df flexBox">
              {Object.entries(localData).map(
                ([key, value]) =>
                  !Array.isArray(value) &&
                  value && (
                    <span key={key} className="df filtered-option">
                      <span className="filtered-value">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                        :{" "}
                        <span className="filtered-data">{value || "N/A"}</span>
                      </span>
                      <span
                        onClick={() => removeThisFilter(key)}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={rem(12)}
                          height={rem(12)}
                          viewBox="0 0 11 12"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.2927 8.56042C2.11373 8.73944 2.11375 9.02962 2.29276 9.2086C2.47177 9.38757 2.76196 9.38757 2.94094 9.20855L5.50084 6.64821L8.06095 9.20832C8.23993 9.3873 8.53015 9.3873 8.70913 9.20832C8.88811 9.02934 8.88811 8.73912 8.70913 8.56015L6.14897 5.99999L8.70894 3.43956C8.88788 3.26056 8.88788 2.97036 8.70885 2.79138C8.52987 2.6124 8.23966 2.61243 8.06068 2.79144L5.50075 5.35181L2.94063 2.79166C2.76164 2.61267 2.47144 2.61267 2.29245 2.79166C2.11345 2.97065 2.11345 3.26085 2.29245 3.43984L4.85267 6.00003L2.2927 8.56042Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </span>
                  )
              )}
            </div>
          )}
        </div>
        {/* Filter input fields */}
        <InputField
          label={"ack_number"}
          labelName={"ACK Number"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.ack_number}
          setFormData={setLocalData}
          maxLength={50}
          styles={{ textTransform: "uppercase" }}
          pattern={"textwithNospace"}
        />
        {/* <InputField
          label={"sales_person_name"}
          labelName={"Sales Team Member"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.sales_person_name}
          setFormData={setLocalData}
          maxLength={50}
          pattern={"onlyAlpha"}
          // styles={{ textTransform: "capitalize" }}
          className="df space-between"
        /> */}
        <InputField
          label={"client_person_name"}
          labelName={"Buyer Seller POC"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.client_person_name}
          setFormData={setLocalData}
          maxLength={50}
          // styles={{ textTransform: "capitalize" }}
          pattern={"text"}
        />
        <InputField
          label={"client_subject"}
          labelName={"Subject"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.client_subject}
          setFormData={setLocalData}
        />

        <>
          <div className="FilerDatasheader">
            <div className="FilterEmptyheader"></div>
            <div className="Filertitleheader">
              <p>From</p>
              <p>To</p>
            </div>
          </div>
          <div className="sub-head1">
            <div className="sub-head-title-header">Buyer / Seller Time</div>
            <div className="date-filter">
              <div style={{ width: rem(169) }}>
                <InputField
                  label={"client_email_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.client_email_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.client_email_to
                      ? localData?.client_email_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: rem(169) }}>
                <InputField
                  label={"client_email_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.client_email_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={localData?.client_email_from?.split(" ")[0] || ""}
                />
              </div>
            </div>
          </div>

          <div className="sub-head1">
            <div className="sub-head-title-header">Sales to AI</div>
            <div className="date-filter">
              <div style={{ width: rem(169) }}>
                <InputField
                  label={"sales_email_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.sales_email_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.sales_email_to
                      ? localData?.sales_email_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: rem(169) }}>
                <InputField
                  label={"sales_email_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.sales_email_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={localData?.sales_email_from?.split(" ")[0] || ""}
                />
              </div>
            </div>
          </div>

          <div className="sub-head1">
            <div className="sub-head-title-header">AI Ack</div>
            <div className="date-filter">
              <div style={{ width: rem(169) }}>
                <InputField
                  label={"ack_email_from"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.ack_email_from}
                  setFormData={setLocalData}
                  maxLength={
                    localData.ack_email_to
                      ? localData?.ack_email_to?.split(" ")[0]
                      : maxDate
                  }
                />
              </div>
              <div style={{ width: rem(169) }}>
                <InputField
                  label={"ack_email_to"}
                  type={"date"}
                  placeholder={""}
                  fieldData={localData.ack_email_to}
                  setFormData={setLocalData}
                  maxLength={maxDate}
                  minLength={localData?.ack_email_from?.split(" ")[0] || ""}
                />
              </div>
            </div>
          </div>
        </>

        {!["Awaiting Quote"].includes(title) && (
          <>
            <div className="sub-head1">
              <div className="sub-head-title-header">Quotated Date</div>
              <div className="date-filter">
                <div style={{ width: rem(169) }}>
                  <InputField
                    label={"quotation_email_from"}
                    type={"date"}
                    placeholder={""}
                    fieldData={localData.quotation_email_from}
                    setFormData={setLocalData}
                    maxLength={
                      localData.quotation_email_to
                        ? localData?.quotation_email_to?.split(" ")[0]
                        : maxDate
                    }
                  />
                </div>
                <div style={{ width: rem(169) }}>
                  <InputField
                    label={"quotation_email_to"}
                    type={"date"}
                    placeholder={""}
                    fieldData={localData.quotation_email_to}
                    setFormData={setLocalData}
                    maxLength={maxDate}
                    minLength={
                      localData?.quotation_email_from?.split(" ")[0] || ""
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {!["Awaiting Quote"].includes(title) && (
          <>
            {" "}
            <div
              className="sub-head1 range-filter"
              style={{ marginBottom: "0" }}
            >
              <div className="sub-head-title-header">Quote Value</div>
              <div className="date-filter">
                <div className="date-filter2" style={{ width: rem(169) }}>
                  <InputField
                    label={"min_quote_value"}
                    type={"range"}
                    placeholder={""}
                    fieldData={localData.min_quote_value}
                    setFormData={setLocalData}
                    maxLength={
                      localData?.max_quote_value
                        ? localData?.max_quote_value
                        : 1000000
                    }
                    minLength={100}
                    styles={{ cursor: "pointer" }}
                  />
                </div>
                <div className="date-filter2" style={{ width: rem(169) }}>
                  <InputField
                    label={"max_quote_value"}
                    type={"range"}
                    placeholder={""}
                    fieldData={localData.max_quote_value}
                    setFormData={setLocalData}
                    minLength={
                      localData?.min_quote_value
                        ? localData?.min_quote_value
                        : 100
                    }
                    maxLength={1000000}
                    styles={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {!["Awaiting Quote", "Awaiting Order", "Order Rejected"].includes(
          title
        ) && (
          <>
            <div className="sub-head1">
              <div className="sub-head-title-header">Ordered Date</div>
              <div className="date-filter">
                <div style={{ width: rem(169) }}>
                  <InputField
                    label={"order_date_from"}
                    type={"date"}
                    placeholder={""}
                    fieldData={localData.order_date_from}
                    setFormData={setLocalData}
                    maxLength={
                      localData.order_date_to
                        ? localData?.order_date_to?.split(" ")[0]
                        : maxDate
                    }
                  />
                </div>
                <div style={{ width: rem(169) }}>
                  <InputField
                    label={"order_date_to"}
                    type={"date"}
                    placeholder={""}
                    fieldData={localData.order_date_to}
                    setFormData={setLocalData}
                    maxLength={maxDate}
                    minLength={localData?.order_date_from?.split(" ")[0] || ""}
                  />
                </div>
              </div>
            </div>
            <>
              <div
                className="sub-head1 range-filter"
                style={{ marginBottom: "0" }}
              >
                <div className="sub-head-title-header">Order Value</div>
                <div className="date-filter">
                  <div className="date-filter2" style={{ width: rem(169) }}>
                    <InputField
                      label={"min_order_value"}
                      type={"range"}
                      fieldData={localData.min_order_value}
                      setFormData={setLocalData}
                      maxLength={
                        localData?.max_order_value
                          ? localData?.max_order_value
                          : 1000000
                      }
                      minLength={100}
                      styles={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="date-filter2" style={{ width: rem(169) }}>
                    <InputField
                      label={"max_order_value"}
                      type={"range"}
                      placeholder={""}
                      fieldData={localData.max_order_value}
                      setFormData={setLocalData}
                      // max={}
                      minLength={
                        localData?.min_order_value
                          ? localData?.min_order_value
                          : 100
                      }
                      maxLength={1000000}
                      styles={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </>
          </>
        )}

        {!["Order Placed", "Awaiting Quote", "Awaiting Order"].includes(
          title
        ) && (
          <>
            <div className="sub-head1">
              <div className="sub-head-title-header">Rejected Date</div>
              <div className="date-filter">
                <div style={{ width: rem(169) }}>
                  <InputField
                    label={"reject_date_from"}
                    type={"date"}
                    placeholder={""}
                    fieldData={localData.reject_date_from}
                    setFormData={setLocalData}
                    maxLength={
                      localData.reject_date_to
                        ? localData?.reject_date_to?.split(" ")[0]
                        : maxDate
                    }
                  />
                </div>
                <div style={{ width: rem(169) }}>
                  <InputField
                    label={"reject_date_to"}
                    type={"date"}
                    placeholder={""}
                    fieldData={localData.reject_date_to}
                    setFormData={setLocalData}
                    maxLength={maxDate}
                    minLength={localData?.reject_date_from?.split(" ")[0] || ""}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* Filter buttons */}
      <div className="filter-btn">
        <div>
          <Button
            onclick={ResetFilter}
            btnname={"Reset"}
            cl={"#1e6641"}
            br={4}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            box_s="none"
            fs={16}
          />
        </div>
        <div>
          <Button btnname={"Apply Now"} onclick={handleFilter} fs={16} br={4} />
        </div>
      </div>
    </div>
  );
};

export default FilterEnquires;
