import React from "react";
import { Modal } from "antd";
import { rem } from "../../../../Components/Rem_func";
import Button from "../../../../Components/Button/Button";

export default function RejectConfirmPopup({
  confirmReject,
  setConfirmReject,
  rejectOrderApi,
  title,
  setReason,
}) {
  const handleReject = () => {
    rejectOrderApi();
    setConfirmReject(false);
  };

  return (
    <Modal
      centered
      open={confirmReject}
      onCancel={() => setConfirmReject(false)}
      width={rem(495)}
      height={rem(231)}
      footer={null}
      closable={false}
      className="edit-popup reject-modal"
    >
      <div className="confirm-reject-order df flexColumn space-between h100">
        <div className="confirm-reject w100">
          {title === "Awaiting Order" || title === "Awaiting Quote" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(45)}
              height={rem(42)}
              viewBox="0 0 24 25"
              fill="none"
              style={{
                background: "#F53649",
                borderRadius: "50%",
                padding: `${rem(8)} ${rem(10)}`,
              }}
            >
              <path
                d="M5.82348 4.34216L11.9995 10.5182L18.1435 4.37416C18.2792 4.2297 18.4427 4.11415 18.6241 4.03442C18.8056 3.95468 19.0013 3.91242 19.1995 3.91016C19.6238 3.91016 20.0308 4.07873 20.3309 4.37879C20.6309 4.67884 20.7995 5.08581 20.7995 5.51016C20.8032 5.70632 20.7668 5.90118 20.6925 6.08277C20.6182 6.26436 20.5076 6.42885 20.3675 6.56616L14.1435 12.7102L20.3675 18.9342C20.6312 19.1921 20.7858 19.5415 20.7995 19.9102C20.7995 20.3345 20.6309 20.7415 20.3309 21.0415C20.0308 21.3416 19.6238 21.5102 19.1995 21.5102C18.9956 21.5186 18.7921 21.4846 18.6021 21.4102C18.412 21.3358 18.2395 21.2228 18.0955 21.0782L11.9995 14.9022L5.83948 21.0622C5.70429 21.2018 5.54278 21.3133 5.36428 21.3902C5.18578 21.4671 4.99382 21.5078 4.79948 21.5102C4.37513 21.5102 3.96817 21.3416 3.66811 21.0415C3.36805 20.7415 3.19948 20.3345 3.19948 19.9102C3.19575 19.714 3.23214 19.5191 3.30643 19.3375C3.38072 19.156 3.49133 18.9915 3.63148 18.8542L9.85548 12.7102L3.63148 6.48616C3.36777 6.22817 3.21314 5.87882 3.19948 5.51016C3.19948 5.08581 3.36805 4.67884 3.66811 4.37879C3.96817 4.07873 4.37513 3.91016 4.79948 3.91016C5.18348 3.91496 5.55148 4.07016 5.82348 4.34216Z"
                fill="white"
              />
            </svg>
          ) : title === "User Management" ? (
            <span
              className="df"
              style={{
                background: "#F53649",
                borderRadius: "50%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(45)}
                height={rem(42)}
                viewBox="0 0 20 23"
                fill="none"
                className="df"
                style={{
                  padding: `${rem(8.516)} ${rem(10.645)}`,
                }}
              >
                <path
                  d="M3.79039 22.8888C3.1073 22.8888 2.52274 22.6458 2.03672 22.1598C1.55069 21.6738 1.30726 21.0888 1.30643 20.4049V4.25914H0.0644531V1.77518H6.27435V0.533203H13.7262V1.77518H19.9361V4.25914H18.6941V20.4049C18.6941 21.088 18.4511 21.6729 17.9651 22.1598C17.4791 22.6466 16.8941 22.8897 16.2102 22.8888H3.79039ZM16.2102 4.25914H3.79039V20.4049H16.2102V4.25914ZM6.27435 17.9209H8.7583V6.7431H6.27435V17.9209ZM11.2423 17.9209H13.7262V6.7431H11.2423V17.9209Z"
                  fill="white"
                />
              </svg>
            </span>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(45)}
              height={rem(42)}
              viewBox="0 0 24 25"
              fill="none"
              style={{
                background: "#BEEF8D",
                borderRadius: "50%",
                padding: `${rem(8)} ${rem(10)}`,
              }}
            >
              <path
                d="M21.86 6.10188C22.288 7.20588 21.689 7.96188 20.53 8.70788C19.595 9.30788 18.404 9.95988 17.142 11.0729C15.904 12.1639 14.697 13.4789 13.624 14.7729C12.706 15.8838 11.832 17.0303 11.004 18.2099C10.59 18.8009 10.011 19.6829 10.011 19.6829C9.80322 20.0039 9.51707 20.2666 9.17956 20.4463C8.84205 20.626 8.46431 20.7167 8.082 20.7099C7.69991 20.7076 7.32474 20.6077 6.99218 20.4195C6.65962 20.2313 6.38072 19.9612 6.182 19.6349C5.183 17.9579 4.413 17.2949 4.059 17.0579C3.112 16.4199 2 16.3279 2 14.8439C2 13.6649 2.995 12.7099 4.222 12.7099C5.089 12.7419 5.894 13.0829 6.608 13.5629C7.064 13.8689 7.547 14.2749 8.049 14.8079C8.72176 13.8898 9.4214 12.9918 10.147 12.1149C11.304 10.7199 12.67 9.22288 14.135 7.93088C15.575 6.66088 17.24 5.47188 19.005 4.84388C20.155 4.43388 21.433 4.99688 21.86 6.10188Z"
                fill="white"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}

          <div className="list_sty-reject">
            {title === "User Management" ? (
              <p>{title === "User Management" && "Delete Account?"}</p>
            ) : (
              <p>
                {["Awaiting Order", "Awaiting Quote"].includes(title)
                  ? "Reject Order?"
                  : "Revert Order?"}
              </p>
            )}

            {["Awaiting Order", "Awaiting Quote"].includes(title) ? (
              <ul className="custom-list">
                <li>Order details will be pushed to Order Rejection List.</li>
                <li>Only Management can retrieve it back.</li>
              </ul>
            ) : title === "User Management" ? (
              <ul className="custom-list">
                <li style={{ listStyleType: "none" }}>
                  <li>Permanently the User information will be Deleted ❌</li>
                  <li>Once Deleted Information can’t be Retrieved.</li>
                </li>
              </ul>
            ) : (
              <ul className="custom-list" style={{ paddingLeft: 0 }}>
                <li style={{ listStyleType: "none" }}>
                  Order details will be pushed to Awaiting Order List.
                </li>
              </ul>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: rem(18),
          }}
        >
          <Button
            btnname={"Cancel"}
            height={38}
            fs={15}
            fw={500}
            width={119}
            br={5}
            cl="#1e6641"
            bg="#fff"
            border={`${rem(1)} solid #1e6641`}
            box_s={"none"}
            onclick={() => {
              setConfirmReject(false);
              ["Awaiting Order", "Awaiting Quote", "Order Rejected"].includes(
                title
              ) && setReason("");
            }}
          />
          <Button
            btnname={
              ["Awaiting Order", "Awaiting Quote"].includes(title)
                ? "Reject"
                : title === "User Management"
                ? "Delete Account"
                : "Revert"
            }
            height={38}
            fs={15}
            fw={500}
            width={title === "User Management" ? rem(135) : rem(119)}
            br={5}
            cl="#fff"
            bg={
              ["Awaiting Order", "Awaiting Quote", "User Management"].includes(
                title
              )
                ? "#F53649"
                : "#1E6641"
            }
            border={
              ["Awaiting Order", "Awaiting Quote", "User Management"].includes(
                title
              )
                ? `${rem(1)} solid #F53649`
                : " "
            }
            box_s={"none"}
            onclick={handleReject}
          />
        </div>
      </div>
    </Modal>
  );
}
