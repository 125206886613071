import React from "react";
import { Dropdown, Space } from "antd";
import { rem } from "../Rem_func";
const SizeChanger = ({ count, items }) => {
  return (
    <div className="df pageSize">
      {" "}
      Show{" "}
      <span className="df">
        <Space wrap>
          <Dropdown menu={{ items }} placement="bottomLeft">
            <span
              className="filter-head pagecount-head"
              //   data-tooltip-id="tooltip-arrow"
              //   data-tooltip-content="Filter"
            >
              {count}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(7)}
                height={rem(7)}
                viewBox="0 0 6 4"
                fill="none"
              >
                <path d="M0 0.5H6L2.99996 3.49992L0 0.5Z" fill="#666666" />
              </svg>
            </span>
          </Dropdown>
        </Space>
      </span>
      Entries
    </div>
  );
};

export default SizeChanger;
