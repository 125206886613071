import React, { useState } from "react";
import { Modal } from "antd";
import { rem } from "../../../../Components/Rem_func";
import InputField from "../../../../Components/InputField/InputField";
import Button from "../../../../Components/Button/Button";
import TextField from "../../../../Components/TextField/TextField";

export default function RejectPopUp({
  rejectOpen,
  setRejectOpen,
  selectedData,
  reason,
  setReason,
  setConfirmReject,
  title,
}) {
  const [erring, setErring] = useState({});
  const reasonData = [
    "Technical issue",
    "Price issue",
    "Delivery time issues",
    "ICV Value is not matched",
    "Quality Issue From Spira",
    "Others",
  ];
  const [others, setOthers] = useState(false);
  const handleRejectPop = () => {
    if (!reason.rejectReason) {
      setErring({ rejectReason: true });
    } else {
      setErring({ rejectReason: false });
      setRejectOpen(false);
      setConfirmReject(true);
    }
  };
  const cancelHandler = () => {
    setRejectOpen(false);
    setReason({ rejectReason: "" });
  };
  const UpdateReason = (item) => {
    if (item !== "Others") {
      setReason({ rejectReason: item });
    } else {
      setOthers(true);
      setReason({ rejectReason: "" });
    }
  };
  return (
    <Modal
      className="reject-modal edit-popup reject-edit-popup"
      title={
        title === "Awaiting Order" || title === "Awaiting Quote"
          ? "Rejection Order Details"
          : "Revert Order Details"
      }
      open={rejectOpen}
      onCancel={cancelHandler}
      width={rem(1021)}
      // height={rem(280)}
      style={{
        top: 20,
      }}
      footer={null}
    >
      <div className="reject-popup">
        <div className="tablefilter">
          <InputField
            label={"ACK Number"}
            labelName={"ACK Number"}
            type={"text"}
            placeholder={""}
            fieldData={selectedData[0]?.new_ref_num}
            readOnly={true}
            styles={{ textTransform: "capitalize" }}
          />
          {["Awaiting Order", "Awaiting Quote"].includes(title) && !others ? (
            <div className="rejreasons">
              <div>Reason*</div>
              <div className="reasonscont">
                {reasonData?.map((item, index) => (
                  <span
                    className={`reasonscont-items ${
                      reason.rejectReason === item ? "active" : ""
                    } `}
                    key={index}
                    onClick={() => UpdateReason(item)}
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <TextField
              label={`rejectReason`}
              labelName="Reason*"
              type="text"
              fieldData={reason?.rejectReason}
              setFormData={setReason}
              hasError={erring.rejectReason}
              // readOnly={mtc}
              height={rem(144)}
              placeholder={"Enter reason"}
            />
          )}
        </div>
        <div className="clientSty">
          <div className="tablefilter">
            <InputField
              label={"Client Person Name"}
              labelName={"Client Person Name"}
              type={"text"}
              placeholder={""}
              fieldData={selectedData[0]?.client_person_name}
              readOnly={true}
              styles={{ textTransform: "capitalize" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: rem(18),
            }}
          >
            <Button
              btnname={"Cancel"}
              height={38}
              fs={15}
              fw={500}
              width={119}
              br={5}
              cl="#1e6641"
              bg="#fff"
              border={`${rem(1)} solid #1e6641`}
              box_s={"none"}
              onclick={() => cancelHandler()}
            />
            <Button
              btnname={
                ["Awaiting Order", "Awaiting Quote"].includes(title)
                  ? "Reject"
                  : "Revert"
              }
              height={38}
              fs={15}
              fw={500}
              width={119}
              br={5}
              cl="#fff"
              bg="#1e6641"
              border={`${rem(1)} solid #1e6641`}
              box_s={"none"}
              onclick={handleRejectPop}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
