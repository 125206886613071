import React, { useState, useEffect, useCallback, useRef } from "react";
import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/InputField/InputField";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { rem } from "../../../Components/Rem_func";
import "./Filter.scss";

const FilterUser = ({
  onFilter,
  hide,
  roles,
  userList,
  names,
  setFilteredData,
  filteredData,
}) => {
  // Function to handle filter application
  const [localData, setLocalData] = useState(filteredData);

  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  // State variable to store reportingTo options based on user roles
  const [reportingTo, setReportingTo] = useState();

  // State variable to store available roles based on user roles
  const [Roles, setRoles] = useState([]);
  const RoleType = useCallback(() => {
    if (roles === "admin") {
      setRoles(["Manager", "Teamlead", "employee"]);
    } else if (roles === "Manager") {
      setRoles(["Teamlead", "employee"]);
    } else if (roles === "Teamlead") {
      setRoles(["employee"]);
    } else {
      return;
    }

    setReportingTo([]); // Reset reportingTo on RoleType change
  }, [roles]);
  console.log(filteredData);
  const getReportingToOptions = useCallback(
    (role_names, names) => {
      // if (!filterOn) {
      //   setLocalData((prevData) => ({
      //     ...prevData,
      //     reporting_to: "",
      //   }));
      // }
      let reportingToOptions = [];

      if (role_names === "Manager") {
        reportingToOptions = [names];
      } else if (role_names === "Teamlead") {
        const managers = userList
          .filter((user) => user?.role_name === "Manager")
          .map((user) => user?.user);

        reportingToOptions = managers.length > 0 ? managers : [names];
      } else if (role_names === "employee") {
        const teamleads = userList
          .filter((user) => user?.role_name === "Teamlead")
          .map((user) => user?.user);

        reportingToOptions = teamleads.length > 0 ? teamleads : [names];
      } else if (role_names === "admin") {
        const teamleads = userList
          .filter((user) => user?.role_name !== "employee")
          .map((user) => user?.user);

        reportingToOptions = teamleads.length > 0 ? teamleads : [names];
      }

      setReportingTo(reportingToOptions);
    },
    [userList]
  );

  const getReportingToOptions1 = useCallback(() => {
    let reportingToOptions = [];

    if (roles === "admin") {
      const admins = userList
        .filter((user) => user?.role_name !== "employee")
        .map((user) => user?.user);

      reportingToOptions = admins.length > 0 ? admins : [names];
    } else if (roles === "Manager") {
      const managers = userList
        .filter((user) => user?.role_name === "Teamlead")
        .map((user) => user?.user);

      reportingToOptions = managers.length > 0 ? managers : [names];
    } else if (roles === "Teamlead") {
      const teamleads = userList
        .filter((user) => user?.role_name === "employee")
        .map((user) => user?.user);

      reportingToOptions = teamleads.length > 0 ? teamleads : [names];
    }

    setReportingTo(reportingToOptions);
  }, [roles, userList, names]);

  const prevRoleNameRef = useRef(null);

  useEffect(() => {
    RoleType();

    // Only reset reporting_to when role_name is not empty and changes
    if (
      localData?.role_name &&
      prevRoleNameRef.current !== localData?.role_name
    ) {
      setLocalData((prevData) => ({
        ...prevData,
        reporting_to: "", // Reset reporting_to
      }));
    }

    // Update the ref for the next render to track the current role_name
    prevRoleNameRef.current = localData?.role_name;

    // Fetch reporting options based on role_name
    if (localData?.role_name) {
      getReportingToOptions(localData?.role_name, names);
    } else {
      getReportingToOptions1();
    }
  }, [
    localData?.role_name,
    RoleType,
    getReportingToOptions,
    getReportingToOptions1,
    names,
  ]);

  // Function to handle filter application
  const handleFilter = () => {
    // onFilter(filteredData);
    setFilteredData(localData);
    hide();
  };

  // Function to reset filters
  const ResetFilter = () => {
    const resetData = {
      user: "",
      role_name: "",
      reporting_to: "",
      created_date_from: "",
      created_date_to: "",
      last_login_from: "",
      last_login_to: "",
    };

    setFilteredData(resetData);
    // onFilter(resetData);
  };
  const handleSelectChange = (name, value) => {
    setLocalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const removeThisFilter = (key) => {
    setLocalData((prev) => ({ ...prev, [key]: "" }));
  };

  const maxDate = getCurrentDate();
  return (
    <div className="filter-box main-userflt">
      <div className="filter-body user-management">
        <div className="df filter-section ">
          {filteredData && (
            <div
              className="filtered-data-item df flexBox justify-end"
              style={{ paddingBottom: rem(6) }}
            >
              {Object.entries(localData).map(
                ([key, value]) =>
                  value && (
                    <span key={key} className="df filtered-option">
                      <span className="filtered-value">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                        :{value || "N/A"}
                      </span>

                      <span
                        onClick={() => removeThisFilter(key)}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={rem(12)}
                          height={rem(12)}
                          viewBox="0 0 11 12"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.2927 8.56042C2.11373 8.73944 2.11375 9.02962 2.29276 9.2086C2.47177 9.38757 2.76196 9.38757 2.94094 9.20855L5.50084 6.64821L8.06095 9.20832C8.23993 9.3873 8.53015 9.3873 8.70913 9.20832C8.88811 9.02934 8.88811 8.73912 8.70913 8.56015L6.14897 5.99999L8.70894 3.43956C8.88788 3.26056 8.88788 2.97036 8.70885 2.79138C8.52987 2.6124 8.23966 2.61243 8.06068 2.79144L5.50075 5.35181L2.94063 2.79166C2.76164 2.61267 2.47144 2.61267 2.29245 2.79166C2.11345 2.97065 2.11345 3.26085 2.29245 3.43984L4.85267 6.00003L2.2927 8.56042Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </span>
                  )
              )}
            </div>
          )}
        </div>
        {/* <InputField
          label={"user"}
          labelName={"User"}
          type={"text"}
          placeholder={""}
          fieldData={localData.user}
          setFormData={setLocalData}
          maxLength={50}
          pattern={"text"}
        /> */}

        <div
          className="usermanagement-box df space-between"
          style={{ width: "100%", paddingBottom: rem(12) }}
        >
          <div>User Role</div>
          <div style={{ width: rem(358) }}>
            <SelectSearch
              // label="User Role"
              name="role_name"
              Placeholder="Select option"
              options={Roles}
              value={localData?.role_name}
              onChange={(value) => handleSelectChange("role_name", value)}
              width={356}
              height={36}
            />
          </div>
        </div>
        <div
          className="usermanagement-box df space-between"
          style={{ paddingBottom: rem(8) }}
        >
          <div>Reporting to</div>
          <div style={{ width: rem(358) }}>
            <SelectSearch
              label=""
              name="reporting_to"
              Placeholder="Select option"
              options={reportingTo}
              value={localData?.reporting_to}
              onChange={(value) => handleSelectChange("reporting_to", value)}
              height={36}
            />
          </div>
        </div>
        <div className="FilerDatasheader">
          <div className="FilterEmptyheader"></div>
          <div className="Filertitleheader">
            <span>From</span>
            <span>To</span>
          </div>
        </div>
        <div className="usermanagement-box df space-between">
          <div
            className="sub-usertitle"
            style={{
              marginBottom: "0",
              paddingBottom: rem(12),
              paddingTop: rem(15),
            }}
          >
            Created Date
          </div>
          <div className="date-filter">
            <div style={{ width: rem(169), paddingBottom: rem(12) }}>
              <InputField
                labelName={""}
                label={"created_date_from"}
                type={"date"}
                placeholder={""}
                fieldData={localData?.created_date_from}
                setFormData={setLocalData}
                maxLength={
                  localData?.created_date_to
                    ? localData?.created_date_to.split("T")[0]
                    : localData?.last_login_to
                    ? localData?.last_login_to.split("T")[0]
                    : maxDate
                }
                minLength={localData?.last_login_from}
              />
            </div>
            <div style={{ width: rem(169) }}>
              <InputField
                labelName={""}
                label={"created_date_to"}
                type={"date"}
                placeholder={""}
                fieldData={localData?.created_date_to}
                setFormData={setLocalData}
                minLength={
                  localData?.created_date_from
                    ? localData?.created_date_from?.split("T")[0]
                    : ""
                }
                maxLength={
                  localData?.last_login_to
                    ? localData?.last_login_to?.split("T")[0]
                    : maxDate
                }
              />
            </div>
          </div>
        </div>
        <div className="usermanagement-box df space-between">
          <div
            className="sub-usertitle"
            style={{ marginBottom: "0", paddingTop: rem(15) }}
          >
            Last Login
          </div>
          <div className="date-filter">
            <div style={{ width: rem(169) }}>
              <InputField
                labelName={""}
                label={"last_login_from"}
                type={"Date"}
                placeholder={""}
                fieldData={localData?.last_login_from}
                setFormData={setLocalData}
                maxLength={
                  localData?.last_login_to
                    ? localData?.last_login_to?.split("T")[0]
                    : maxDate
                }
                minLength={
                  localData?.created_date_from
                    ? localData?.created_date_from?.split("T")[0]
                    : ""
                }
              />
            </div>
            <div style={{ width: rem(169) }}>
              <InputField
                labelName={""}
                label={"last_login_to"}
                type={"Date"}
                placeholder={""}
                fieldData={localData?.last_login_to}
                setFormData={setLocalData}
                minLength={
                  localData?.last_login_from
                    ? localData?.last_login_from?.split("T")[0]
                    : localData?.created_date_to?.split("T")[0]
                }
                maxLength={maxDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="filter-btn">
        <div>
          <Button
            btnname={"Reset"}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            onclick={ResetFilter}
            box_s={"none"}
            cl={"#1e6641"}
            width={119}
            fs={15}
            br={5}
          />
        </div>
        <div>
          <Button
            btnname={"Apply"}
            onclick={handleFilter}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleFilter();
              }
            }}
            width={119}
            fs={15}
            br={5}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterUser;
