import React, { useState, useEffect } from "react";
import "../SalesTracker/STcomponents/Filter/FilterEnquires.scss";
import Button from "../../Components/Button/Button";
import InputField from "../../Components/InputField/InputField";
import { rem } from "../../Components/Rem_func";

const EmailFilter = ({
  hide,
  filteredData,
  setFilteredData,
  title,
  setFilters,
}) => {
  // Function to handle filter application
  const [localData, setLocalData] = useState({
    from_email: "",
    from_email_time: "",
    from_subject: "",
  });
  const handleFilter = () => {
    setFilteredData(localData);
    hide();
  };

  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);

  // Function to reset filters
  const ResetFilter = () => {
    // Reset filtered data
    const resetData = {
      from_email: "",
      from_email_time: "",
      from_subject: "",
    };
    setLocalData(resetData);
    setFilteredData(resetData);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  // Handle removing a filter
  const removeThisFilter = (key) => {
    setLocalData((prev) => {
      const updatedFilters = { ...prev, [key]: "" }; // Clear the filter
      return updatedFilters;
    });
  };
  // const minDate = new Date().getFullYear - 4;
  const maxDate = getCurrentDate();
  return (
    <div className="filter-box">
      <div className="filter-body">
        <div className="df filter-section">
          {localData && (
            <div className="filtered-data-item df flexBox">
              {Object.entries(localData).map(
                ([key, value]) =>
                  !Array.isArray(value) &&
                  value && (
                    <span key={key} className="df filtered-option">
                      <span className="filtered-value">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                        :{" "}
                        <span className="filtered-data">{value || "N/A"}</span>
                      </span>
                      <span
                        onClick={() => removeThisFilter(key)}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={rem(12)}
                          height={rem(12)}
                          viewBox="0 0 11 12"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.2927 8.56042C2.11373 8.73944 2.11375 9.02962 2.29276 9.2086C2.47177 9.38757 2.76196 9.38757 2.94094 9.20855L5.50084 6.64821L8.06095 9.20832C8.23993 9.3873 8.53015 9.3873 8.70913 9.20832C8.88811 9.02934 8.88811 8.73912 8.70913 8.56015L6.14897 5.99999L8.70894 3.43956C8.88788 3.26056 8.88788 2.97036 8.70885 2.79138C8.52987 2.6124 8.23966 2.61243 8.06068 2.79144L5.50075 5.35181L2.94063 2.79166C2.76164 2.61267 2.47144 2.61267 2.29245 2.79166C2.11345 2.97065 2.11345 3.26085 2.29245 3.43984L4.85267 6.00003L2.2927 8.56042Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </span>
                  )
              )}
            </div>
          )}
        </div>
        <InputField
          label={"from_email"}
          labelName={"Email From"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.from_email}
          setFormData={setLocalData}
          maxLength={50}
        />
        <InputField
          label={"from_subject"}
          labelName={"Email Subject"}
          type={"text"}
          placeholder={""}
          fieldData={localData?.from_subject}
          setFormData={setLocalData}
          maxLength={50}
        />
        <InputField
          label={"from_email_time"}
          labelName={"Email Date"}
          type={"date"}
          placeholder={""}
          fieldData={localData?.from_email_time}
          setFormData={setLocalData}
          maxLength={maxDate}
          minLength={localData?.from_email_time || ""}
        />
      </div>
      <div className="filter-btn space-between">
        <div>
          <Button
            onclick={ResetFilter}
            btnname={"Clear"}
            cl={"#1e6641"}
            br={4}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            box_s="none"
            fs={15}
          />
        </div>
        <div>
          <Button
            btnname={"Apply Filter"}
            onclick={handleFilter}
            fs={15}
            br={4}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailFilter;
