import axios from "axios";

const _nonAuthHttp = axios.create({
  // baseURL: "http://127.0.0.1:8000",
  // baseURL: "https://spira.admin.dtskill.com",
  baseURL: "https://spira-qaapi.dtskill.com",
  //baseURL: "https://spira-dev-api.dtskill.com",
  //baseURL: "https://spira-api.dtskill.com",
});

export default _nonAuthHttp;
