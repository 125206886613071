import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const OPtable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  defaultPageSize,
  totalCount,
  DateSorter,
  searchData,
  filters,
}) => {
  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const convertToLocalDate = (utcDateString) => {
          if (!utcDateString) return null;
          const utcDate = new Date(utcDateString);
          return new Date(
            utcDate.getFullYear(),
            utcDate.getMonth(),
            utcDate.getDate(),
            utcDate.getHours(),
            utcDate.getMinutes(),
            utcDate.getSeconds()
          );
        };
        const matchesFilteredData =
          (user?.new_ref_num || "")
            .toLowerCase()
            .includes((filteredData?.ack_number || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData?.client_email_from
            ? convertToLocalDate(user?.client_email_time_utc) >=
              new Date(filteredData?.client_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.client_email_to
            ? convertToLocalDate(user?.client_email_time_utc) <=
              new Date(filteredData?.client_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.sales_email_from
            ? convertToLocalDate(user?.sales_email_time_utc) >=
              new Date(filteredData?.sales_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.sales_email_to
            ? convertToLocalDate(user?.sales_email_time_utc) <=
              new Date(filteredData?.sales_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.ack_email_from
            ? convertToLocalDate(user?.ack_time_utc) >=
              new Date(filteredData?.ack_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.ack_email_to
            ? convertToLocalDate(user?.ack_time_utc) <=
              new Date(filteredData?.ack_email_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.quotation_email_from
            ? convertToLocalDate(user?.quotation_time_utc) >=
              new Date(filteredData?.quotation_email_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData?.quotation_email_to
            ? convertToLocalDate(user?.quotation_time_utc) <=
              new Date(filteredData?.quotation_email_to).setHours(
                23,
                59,
                59,
                999
              )
            : true) &&
          (filteredData.order_date_from
            ? convertToLocalDate(user?.final_order_time) >=
              new Date(filteredData?.order_date_from).setHours(0, 0, 0, 0)
            : true) &&
          (filteredData.order_date_to
            ? convertToLocalDate(user?.final_order_time) <=
              new Date(filteredData?.order_date_to).setHours(23, 59, 59, 999)
            : true) &&
          (filteredData?.max_quote_value
            ? user?.currency_value_resolved
              ? Number(filteredData?.max_quote_value) >=
                Number(user?.currency_value_resolved?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.min_quote_value
            ? user?.currency_value_resolved
              ? Number(filteredData?.min_quote_value) <=
                Number(user?.currency_value_resolved?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.max_order_value
            ? user?.final_order_currency_resolved
              ? Number(filteredData?.max_order_value) >=
                Number(
                  user?.final_order_currency_resolved?.replace(/,/g, "") || 0
                )
              : false
            : true) &&
          (filteredData?.min_order_value
            ? user?.final_order_currency_resolved
              ? Number(filteredData?.min_order_value) <=
                Number(user?.final_order_currency_resolved?.replace(/,/g, ""))
              : false
            : true);
        const matchesSearchData = searchData
          ? (user?.new_ref_num || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_email || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;

        const SalesPersonName =
          filters?.sales_team.length > 0
            ? filters.sales_team.some((team) =>
                user?.sales_person_name?.includes(team)
              )
            : true;
        return matchesFilteredData && matchesSearchData && SalesPersonName;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user.new_ref_num?.toLowerCase().includes(searchData.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user.sales_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchClientEmail =
          searchData &&
          user.client_email?.toLowerCase().includes(searchData.toLowerCase());

        return {
          ...user, // Return all original user data
          new_ref_num_class: matchReferenceNumber ? "highlighted-text" : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_email_class: matchClientEmail ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData, filters]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "ACK Number",
      dataIndex: "new_ref_num",
      key: "new_ref_num",
      width: rem(124),

      className: "",
      sorter: (a, b) =>
        (a.new_ref_num || "").localeCompare(b.new_ref_num || ""),
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.new_ref_num_class),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
      width: rem(134),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(134),
      render: (text, record) => {
        return <span className="op status">Order Placed</span>;
      },
      className: "",
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value_resolved",
      key: "currency_value_resolved",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.currency_value_resolved?.replace(
          /[^0-9.-]+/g,
          ""
        ); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.currency_value_resolved?.replace(
          /[^0-9.-]+/g,
          ""
        );

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },
      width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },

    {
      title: "Order Value",
      dataIndex: "final_order_currency_resolved",
      key: "final_order_currency_resolved",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.final_order_currency_resolved?.replace(
          /[^0-9.-]+/g,
          ""
        ); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.final_order_currency_resolved?.replace(
          /[^0-9.-]+/g,
          ""
        );

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },
      width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      width: rem(84),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Buyer Email",
      dataIndex: "client_email",
      key: "client_email",
      width: rem(134),
      className: "tl",

      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.client_email_class
        ),
      sorter: (a, b) =>
        (a.client_email || "").localeCompare(b.client_email || ""),
    },
    {
      title: `Order Date Time`,
      dataIndex: "final_order_time",
      key: "final_order_time",
      sorter: DateSorter("final_order_time"),
      className: "noPadding",
      render: (text) => {
        return (
          <span className="df justify-center">
            <span
              className="df flexColumn mAuto"
              style={{ alignItems: "baseline" }}
            >
              {renderEmptyOrValue(text, "", "", "date")}

              {renderEmptyOrValue(text, "", "", "time")}
            </span>
          </span>
        );
      },
      width: rem(114),
    },
    {
      title: "Order Closure Days",
      dataIndex: "client_email_time_utc",
      sorter: (a, b) => {
        const orderDateA = a.final_order_time
          ? new Date(a.final_order_time)
          : null;
        const clientEmailA = a.client_email_time_utc
          ? new Date(a.client_email_time_utc)
          : null;
        const orderClosureDaysA =
          orderDateA && clientEmailA
            ? Math.floor((orderDateA - clientEmailA) / (1000 * 60 * 60 * 24))
            : null;

        const orderDateB = b.final_order_time
          ? new Date(b.final_order_time)
          : null;
        const clientEmailB = b.client_email_time_utc
          ? new Date(b.client_email_time_utc)
          : null;
        const orderClosureDaysB =
          orderDateB && clientEmailB
            ? Math.floor((orderDateB - clientEmailB) / (1000 * 60 * 60 * 24))
            : null;

        if (orderClosureDaysA === null && orderClosureDaysB === null) return 0;
        if (orderClosureDaysA === null) return -1;
        if (orderClosureDaysB === null) return 1;

        return orderClosureDaysA - orderClosureDaysB;
      },
      key: "client_email_time_utc",
      render: (text, record) => {
        const orderDate = record.final_order_time
          ? new Date(record.final_order_time)
          : null;
        const clientEmail = record.client_email_time_utc
          ? new Date(record.client_email_time_utc)
          : null;

        const orderClosureDays =
          orderDate && clientEmail
            ? (orderDate - clientEmail) / (1000 * 60 * 60 * 24)
            : null;
        return (
          <span>
            {orderClosureDays !== null ? (
              `${Math.ceil(orderClosureDays)} days`
            ) : (
              <span className="empty">--</span>
            )}
          </span>
        );
      },
      width: rem(134),
    },
  ];

  useEffect(() => {
    const total_Count = filteredUsers.length;
    const order_placed = filteredUsers?.filter(
      (item) => item.reminder_status === "order_placed"
    ).length;
    const total_Pending = filteredUsers?.filter(
      (item) => item?.reminder_status === "pending"
    ).length;

    const total_success = filteredUsers?.filter(
      (item) => item?.reminder_status === "success"
    ).length;

    const total_rejected = filteredUsers?.filter(
      (item) => item?.reminder_status === "rejected"
    ).length;

    totalCount({
      total: total_Count,
      page_count: order_placed,
      pending: total_Pending,
      success: total_success,
      order_placed: order_placed,
      rejected: total_rejected,
    });
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers?.filter(
        (item) => item?.reminder_status === "order_placed"
      )}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default OPtable;
