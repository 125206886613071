import React, { useState, useEffect, useCallback, useMemo } from "react";
import SalesTable from "./SalesTable";
import SalesRange from "./SalesRange";
import RevenueChart from "./RevenueGraph";
import "./Dashboard.scss";
import { rem } from "../../../Components/Rem_func";
import QuotedResponse from "./QuotedResponse";
import BranchBarChart from "./BranchBarChart";
import SalesBieChart from "./SalesBieChart";
import SalesHeader from "./SalesHeader";
import _http from "../../../Utils/Api/_http";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
import { Space } from "antd";
import down from "../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../Assets/EnquiresIcon/filteron.svg";
import green_down from "../../../Assets/EnquiresIcon/green_down.svg";
import DashBoardFilter from "./DashBoardFilter";
import Conversion from "./Conversion";
import FilterSlider from "../../../Components/FilterSlider/FilterSlider";
import DeclineReason from "./DeclineReason";
const DashBoard = () => {
  const [employeesArray, setEmployeesArray] = useState({
    name: [],
    count: [],
    order_count: [],
    quote_count: [],
    orderValueSums: [],
  });
  const [employeesArray1, setEmployeesArray1] = useState({
    name: [],
    count: [],
    totalSalesPerson: [],
  });
  const [filterEmployeeName, setFilterEmployeeName] = useState([]);
  const [salesrange, setSalesRange] = useState({
    quote: [],
    awarded: [],
    enquiries: [],
    conversion: [],
  });
  const [userList, setUserList] = useState([]);
  const [users, setusers] = useState([]);
  const [filteredData, setFilteredData] = useState({
    managers: [],
    teamleads: [],
    employees: [],
  });
  const [role, setRole] = useState("");
  const access = useSelector(selectUserData);
  const [openfilter, setOpenfilter] = useState(false);
  // const currency = ["AED", "USD", "OMR"];

  const [filters, setFilters] = useState({
    location: "",
    quick_filter: "Last week",
    year: [],
    quarter: [],
    start_date: "",
    end_date: "",
    currency: "",
    sales_team: "",
  });
  const [filterOn, setFilterOn] = useState(false);
  const hide = () => {
    setOpenfilter(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };
  const fetchData1 = useCallback(async () => {
    try {
      const response = await _http.get("/api/users_db");
      if (Array.isArray(response?.data) && response.data.every(Array.isArray)) {
        const flattenedData = response?.data?.flat();
        setusers(flattenedData.flat());
      } else {
        setusers(response?.data);
        // dispatch(setdatacount(response.data.length));
      }
    } catch (error) {
      // setToast({ error: true });
      // setError("Error fetching data:", error?.message);
      console.log(error);
    }
    // setLoading(false);
  }, []);
  const isWithinQuarter = (date, quarter) => {
    const month = date.getMonth() + 1; // JavaScript months are 0-based, so add 1

    switch (quarter) {
      case "Q1":
        return month >= 1 && month <= 3; // January to March
      case "Q2":
        return month >= 4 && month <= 6; // April to June
      case "Q3":
        return month >= 7 && month <= 9; // July to September
      case "Q4":
        return month >= 10 && month <= 12; // October to December
      default:
        return false;
    }
  };

  // Function to compute date ranges based on quick filters
  const computeDateRange = (quickFilter) => {
    const now = new Date();
    let startDate;

    switch (quickFilter) {
      case "Yesterday":
        startDate = new Date(now.setDate(now.getDate() - 1));
        break;
      case "Last 3 days":
        startDate = new Date(now.setDate(now.getDate() - 3));
        break;
      case "Last week":
        startDate = new Date(now.setDate(now.getDate() - 7));
        break;
      case "Last 2 weeks":
        startDate = new Date(now.setDate(now.getDate() - 14));
        break;
      case "Last 3 weeks":
        startDate = new Date(now.setDate(now.getDate() - 21));
        break;
      case "Last month":
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case "Last 3 months":
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case "Last 6 months":
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case "Last 9 months":
        startDate = new Date(now.setMonth(now.getMonth() - 9));
        break;
      case "Last year":
        startDate = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      default:
        startDate = null;
    }

    return startDate;
  };

  const filteredUsers = useMemo(() => {
    let filteredManagers = [];
    let filteredTeamLeads = [];
    let filteredEmployees = [];
    const userRole = role;

    // Role-based filtering for admin, manager, and team lead
    if (userRole === "admin") {
      filteredManagers = filteredData.managers.length
        ? userList.filter(
            (user) =>
              filteredData.managers.includes(user.user) &&
              user.role_name === "Manager"
          )
        : userList.filter((user) => user.role_name === "Manager");

      filteredTeamLeads = filteredData.teamleads.length
        ? userList.filter(
            (user) =>
              filteredData.teamleads.includes(user.user) &&
              user.role_name === "Teamlead" &&
              filteredManagers.some(
                (manager) => user.reporting_to === manager.user
              )
          )
        : userList.filter(
            (user) =>
              user.role_name === "Teamlead" &&
              filteredManagers.some(
                (manager) => user.reporting_to === manager.user
              )
          );

      filteredEmployees = filteredData.employees.length
        ? userList.filter(
            (user) =>
              filteredData.employees.includes(user.user) &&
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          )
        : userList.filter(
            (user) =>
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          );
    } else if (userRole === "Manager") {
      filteredTeamLeads = filteredData.teamleads.length
        ? userList.filter(
            (user) =>
              filteredData.teamleads.includes(user.user) &&
              user.role_name === "Teamlead"
          )
        : userList.filter((user) => user.role_name === "Teamlead");

      filteredEmployees = filteredData.employees.length
        ? userList.filter(
            (user) =>
              filteredData.employees.includes(user.user) &&
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          )
        : userList.filter(
            (user) =>
              user.role_name === "employee" &&
              filteredTeamLeads.some((tl) => user.reporting_to === tl.user)
          );
    } else if (userRole === "Teamlead") {
      filteredEmployees = filteredData.employees.length
        ? userList.filter(
            (user) =>
              filteredData.employees.includes(user.user) &&
              user.role_name === "employee"
          )
        : userList.filter((user) => user.role_name === "employee");
    }
    let filteredSalesData;
    const filteredEmployeeNames = filteredEmployees.map((emp) => emp.user);

    filteredSalesData = (users || []).filter((item) =>
      filteredEmployeeNames.includes(item.sales_person_name || "")
    );
    if (userList.length === 0) {
      filteredSalesData = users;
    }

    return (
      (filteredSalesData || []).filter((item) => {
        const emailDateUTC = item?.client_email_time_utc;

        // if (!emailDateUTC) {
        //   return false; // Exclude items with no email date
        // }

        // Convert UTC email date to local time (proper handling)
        const emailDate = new Date(emailDateUTC);
        const localEmailDate = new Date(
          emailDate.getFullYear(),
          emailDate.getMonth(),
          emailDate.getDate(),
          emailDate.getHours(),
          emailDate.getMinutes(),
          emailDate.getSeconds()
        );

        const yearOfEmail = localEmailDate.getFullYear();

        const isYearIncluded =
          filters.year.length > 0 ? filters.year.includes(yearOfEmail) : true;

        const isAfterStartDate = filters.start_date
          ? localEmailDate >= new Date(filters.start_date).setHours(0, 0, 0, 0)
          : true;

        const isBeforeLastDate = filters.end_date
          ? localEmailDate <=
            new Date(filters.end_date).setHours(23, 59, 59, 999)
          : true;

        const isInQuarter =
          filters.quarter.length > 0
            ? filters.quarter.some((q) => isWithinQuarter(localEmailDate, q))
            : true;

        const branch = filters.location
          ? filters.location.includes(item?.location)
          : true;

        // Currency, branch, and quick filter checks
        const isCurrencyMatched = filters.currency
          ? filters.currency.includes(item?.currency)
          : true;
        // Quick filter handling
        let quickFilterStartDate = null;
        if (filters.quick_filter) {
          quickFilterStartDate = computeDateRange(filters.quick_filter);
        }
        const isWithinQuickFilter = quickFilterStartDate
          ? localEmailDate >= quickFilterStartDate
          : true;
        const isSalesTeamMember = filters?.sales_team
          ? filters.sales_team?.includes(item?.sales_person_name)
          : true;
        return (
          (filters.year.length === 0 || isYearIncluded) &&
          isAfterStartDate &&
          isBeforeLastDate &&
          isInQuarter &&
          branch &&
          isCurrencyMatched &&
          isWithinQuickFilter &&
          isSalesTeamMember
        );
      }) || []
    );
  }, [filters, users, filteredData, userList, role]);

  useEffect(() => {
    let employeeNames = [];
    if (userList.length > 0) {
      employeeNames = userList
        .filter(
          (user) =>
            user?.role_name.includes("employee") &&
            (!filters.location || user?.location === filters.location) // Filter by location
        )
        .map((user) => user.user);
    } else {
      employeeNames = [access?.name];
    }

    // Compute the count of all employee names in filteredUsers
    const employeeCounts = employeeNames.map((name) =>
      countOccurrences(name, filteredUsers, "sales_person_name")
    );

    const filteredNames = employeeNames.filter(
      (_, index) => employeeCounts[index] !== 0
    );
    const filteredCounts = employeeCounts.filter((count) => count !== 0);

    // Update the state with all employee names and their counts
    setEmployeesArray1({
      name: filteredNames,
      count: filteredCounts,
      totalSalesPerson: employeeNames,
    });
    if (filters.sales_team && !filteredNames.includes(filters.sales_team)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sales_team: "",
      }));
    }
  }, [userList, access, filters, filteredUsers]);
  // Dependencies for useMemo

  const fetchData2 = useCallback(async () => {
    // setLoading(true);
    if (access.role_name === "employee") {
      return;
    }
    try {
      const response = await _http.get("/api/get_users");
      const userLists = response.data.Output.record.flat();
      setUserList(userLists.flat());
      setRole(response?.data?.Output?.Role);
    } catch (error) {
      // setToast({ error: true });
      // setError("Error fetching data:", error.message);
    }
    // setLoading(false);
  }, [access]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);
  useEffect(() => {
    fetchData2();
  }, [fetchData2]);
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Convert to billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num?.toString(); // If less than a thousand, return as is
    }
  };
  //revenue graph

  //sales range code
  const calculateOrderValueSumByCurrency1 = (data) => {
    const currencySums = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };
    const quoteSums = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };
    const enquiries = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };
    const conversion = {
      ackCount: 0,
      orderCount: 0,
      quoteCount: 0,
    };
    const quoteEnquiry = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };
    // Filter data for the specific employee and sum up the order_value by currency
    data?.forEach((item) => {
      // Ensure that order_value and currency_value_resolved are numbers
      const orderValue =
        parseFloat(item.final_order_currency_resolved?.replace(/,/g, "")) || 0;
      const currencyValue =
        parseFloat(item.currency_value_resolved?.replace(/,/g, "")) || 0;

      // For USD
      if (item.currency === "USD") {
        currencySums.USD += orderValue;
        quoteSums.USD += currencyValue;
        enquiries.USD += 1; // Count the number of USD enquiries
        if (item.reminder_status !== "pending") {
          quoteEnquiry.USD += 1;
        }
      }

      // For AED
      else if (item.currency === "AED") {
        currencySums.AED += orderValue;
        quoteSums.AED += currencyValue;
        enquiries.AED += 1; // Count the number of AED enquiries
        if (item.reminder_status !== "pending") {
          quoteEnquiry.AED += 1;
        }
      }

      // For OMR
      else if (item.currency === "OMR") {
        currencySums.OMR += orderValue;
        quoteSums.OMR += currencyValue;
        enquiries.OMR += 1; // Count the number of OMR enquiries
        if (item.reminder_status !== "pending") {
          quoteEnquiry.OMR += 1;
        }
      }
    });

    // Count the number of orders and quotes across all data
    conversion.ackCount = data.length; // Total number of items (enquiries)
    conversion.orderCount = data.filter(
      (item) => item.reminder_status === "order_placed"
    ).length; // Count orders placed
    conversion.quoteCount = data.filter(
      (item) => item.reminder_status === "success"
    ).length; // Count quotes marked as success

    // Set the result
    setSalesRange({
      awarded: currencySums,
      quote: quoteSums,
      enquiries: enquiries,
      conversion: conversion,
      total_sales: data?.length,
      quoteEnquiry: quoteEnquiry,
    });
  };

  //sales table code

  useEffect(() => {
    calculateOrderValueSumByCurrency1(filteredUsers);

    let employeeNames = "";
    if (userList.length > 0) {
      employeeNames = userList
        .filter((user) => {
          if (
            filteredData?.employees?.length === 0 &&
            filterEmployeeName?.length > 0
          ) {
            // Filter for the specific sales team user
            return filterEmployeeName?.some((item) =>
              user?.user?.includes(item)
            );
          } else if (filteredData.employees.length > 0) {
            return filteredData?.employees?.some((item) =>
              user?.user?.includes(item)
            );
          } else {
            // General filter when sales_team is not specified
            return (
              user?.role_name.includes("employee") &&
              (!filters.location || user?.location === filters?.location)
            );
          }
        })
        .map((user) => user.user);
    } else {
      employeeNames = [access?.name];
    }

    const employeeCounts = employeeNames.map((name) =>
      countOccurrences(name, filteredUsers, "sales_person_name")
    );

    const orderCount = employeeNames.map((name) =>
      ordercount(name, filteredUsers, "order_placed")
    );
    const quoteCount = employeeNames.map((name) =>
      ordercount(name, filteredUsers, "success")
    );
    const orderValueSums = employeeNames.map((name) =>
      calculateOrderValueSumByCurrency(name, filteredUsers)
    );

    setEmployeesArray({
      name: employeeNames,
      count: employeeCounts,
      order_count: orderCount,
      quote_count: quoteCount,
      orderValueSums: orderValueSums,
    });
  }, [
    userList,
    filteredUsers,
    access,
    filters,
    filterEmployeeName,
    filteredData,
  ]);

  const countOccurrences = (name, data, item) => {
    return data.filter((items) => items[item] === name).length;
  };
  const ordercount = (name, data, statusKey) => {
    return data.filter(
      (item) =>
        item.sales_person_name === name && item.reminder_status === statusKey
    ).length;
  };
  const calculateOrderValueSumByCurrency = (name, data) => {
    // Initialize sums for each currency
    const currencySums = {
      USD: 0,
      AED: 0,
      OMR: 0,
    };

    // Filter data for the specific employee and sum up the order_value by currency
    data?.forEach((item) => {
      if (item.sales_person_name === name) {
        if (item.currency === "USD") {
          currencySums.USD +=
            parseFloat(
              item?.final_order_currency_resolved?.replace(/,/g, "")
            ) || 0;
        } else if (item.currency === "AED") {
          currencySums.AED +=
            parseFloat(
              item?.final_order_currency_resolved?.replace(/,/g, "")
            ) || 0;
        } else if (item.currency === "OMR") {
          currencySums.OMR +=
            parseFloat(
              item?.final_order_currency_resolved?.replace(/,/g, "")
            ) || 0;
        }
      }
    });

    return currencySums; // Return the sums for each currency
  };

  const checkIfFiltersAreApplied = (filters) => {
    return Object?.values(filters)?.some((value) => value.length > 0);
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);

  const filterComponent = (
    <div className="df" style={{ gap: rem(16) }}>
      {
        <Space>
          <span
            className="filter-head"
            data-tooltip-id="tooltip-arrow"
            data-tooltip-content="Filter"
            onClick={() => setOpenfilter(true)}
          >
            <img src={filterOn ? filteron : filter} alt="filter icon" />
            <img src={filterOn ? green_down : down} alt="arrow icon" />
          </span>
        </Space>
      }
    </div>
  );
  const conversationPercentAgainstEnquires =
    (salesrange.conversion.orderCount / salesrange.conversion.ackCount) * 100 ||
    0;

  const conversationPercentAgainstQuoted =
    (salesrange.conversion.orderCount /
      (salesrange.conversion.quoteCount + salesrange.conversion.orderCount)) *
      100 || 0;

  return (
    <section className="df sales-dashboard flexColumn">
      <div className="container1 df flexColumn align-start">
        <div className="df container-head1 w100">
          <span className="sales">Sales Tracker /</span>
          <span className="sales-title">Dashboard</span>
        </div>
        <div className="container-head df w100">
          <div style={{ width: "20%" }}>
            <p className="title">Sales Dashboard</p>
          </div>
        </div>
        <div className="w100" style={{ paddingBottom: rem(12) }}>
          <SalesHeader
            userList={userList}
            filteredData={filteredData}
            setFilters={setFilters}
            filters={filters}
            filterOn={filterOn}
            setFilteredData={setFilteredData}
            DashBoard={true}
            employeesArray={employeesArray1}
            filterComponent={filterComponent}
          />
        </div>
        <div className="script-screenshot">
          <div className="df" style={{ gap: rem(28) }}>
            <SalesRange salesrange={salesrange} formatNumber={formatNumber} />
            <Conversion
              labelName={"Enquiries"}
              percentage={conversationPercentAgainstEnquires?.toFixed(1)}
              outerValue={salesrange?.conversion?.ackCount}
              innerValue={salesrange.conversion.orderCount}
            />
          </div>

          <div>
            <p className="conv-title sales-table-title">Sales Details</p>
            <div className="df conv-nd-table">
              <SalesTable
                employeesArray={employeesArray}
                formatNumber={formatNumber}
              />
              <Conversion
                labelName={"Quoted"}
                percentage={conversationPercentAgainstQuoted?.toFixed(1)}
                outerValue={
                  salesrange?.conversion?.quoteCount +
                  salesrange.conversion.orderCount
                }
                innerValue={salesrange.conversion.orderCount}
              />
            </div>
          </div>
        </div>

        <div className="df graph-lvl-3">
          <QuotedResponse users={filteredUsers} />

          <BranchBarChart formatNumber={formatNumber} users={filteredUsers} />
        </div>
        <div className="df graph-lvl-2">
          {/* <BranchBarChart formatNumber={formatNumber} users={filteredUsers} /> */}
          <div className="left-side df" style={{ gap: rem(23) }}>
            <SalesBieChart users={filteredUsers} />
            <RevenueChart formatNumber={formatNumber} users={filteredUsers} />
          </div>
          <DeclineReason formatNumber={formatNumber} users={filteredUsers} />
        </div>
      </div>
      {openfilter && (
        <FilterSlider
          setOpenfilter={setOpenfilter}
          width={rem(354)}
          openfilter={openfilter}
          handleOpenChange={handleOpenChange}
          hide={hide}
          DashBoardFilter={DashBoardFilter}
          userList={userList}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          setFilters={setFilters}
          setFilterEmployeeName={setFilterEmployeeName}
        />
      )}
    </section>
  );
};

export default DashBoard;
