import React, { useMemo, useEffect } from "react";
import { rem } from "../../Components/Rem_func";
import DraggableTable from "../../Components/TableDrag_func";
import "./Email.scss";

const EmailTable = ({
  handleOpen,
  users,
  user,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
  DateSorter,
  searchData,
}) => {
  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        // Filter based on filteredData
        const matchesFilteredData = filteredData
          ? (user?.from_email || "")
              .toLowerCase()
              .includes((filteredData?.from_email || "").toLowerCase()) &&
            (user?.from_email_time || "")
              .toLowerCase()
              .includes((filteredData?.from_email_time || "").toLowerCase()) &&
            (user?.from_subject || "")
              .toLowerCase()
              .includes((filteredData?.from_subject || "").toLowerCase())
          : true; // Allow all users if no filteredData is provided
        const matchesSearchData = searchData
          ? (user?.from_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.from_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.from_email || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.llm_label || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.llm_reason || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;
        return matchesSearchData && matchesFilteredData;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchClientPersonMail =
          searchData &&
          user?.from_email?.toLowerCase().includes(searchData?.toLowerCase());

        const matchClientSubject =
          searchData &&
          user?.from_subject?.toLowerCase().includes(searchData?.toLowerCase());

        const matchLlmLabel =
          searchData &&
          user?.llm_label?.toLowerCase().includes(searchData?.toLowerCase());

        const matchLlmReason =
          searchData &&
          user?.llm_reason?.toLowerCase().includes(searchData?.toLowerCase());
        return {
          ...user, // Return all original user data
          client_person_mail_class: matchClientPersonMail
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
          llm_label_class: matchLlmLabel ? "highlighted-text" : "",
          llm_reason_class: matchLlmReason ? "highlighted-text" : "",
        };
      });
  }, [users, searchData, filteredData]);
  // Configuration for table columns
  const columns = [
    {
      title: "Sender Name",
      dataIndex: "from_person_name",
      key: "from_person_name",
      width: rem(134),
      className: "noPadding tl",

      render: (text, record) =>
        renderEmptyOrValue(
          record.from_person_name,
          false,
          record?.client_person_mail_class
        ),
      sorter: (a, b) =>
        (a.from_person_name || "").localeCompare(b.from_person_name || ""),
    },
    {
      title: "Sender Email ID",
      dataIndex: "from_email",
      key: "from_email",
      width: rem(134),
      className: "noPadding tl",

      render: (text, record) =>
        renderEmptyOrValue(
          record.from_email,
          false,
          record?.client_person_mail_class
        ),
      sorter: (a, b) => (a.from_email || "").localeCompare(b.from_email || ""),
    },
    {
      title: "Email Subject",
      dataIndex: "from_subject",
      key: "from_subject",
      width: rem(164),
      className: "noPadding tl",
      render: (text, record) =>
        renderEmptyOrValue(text, "", record?.client_subject_class),
      sorter: (a, b) =>
        (a.from_subject || "").localeCompare(b.from_subject || ""),
    },
    {
      title: `Email Received Date & Time`,
      dataIndex: "from_email_time",
      key: "from_email_time",
      width: rem(140),
      className: "noPadding",
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      sorter: DateSorter("from_email_time"),
    },
    {
      title: `Tag`,
      dataIndex: "llm_label",
      key: "llm_label",
      width: rem(96),
      className: "",
      render: (text, record) =>
        renderEmptyOrValue(
          text === "error" ? "--" : text,
          "",
          "llm_label_class"
        ),
      sorter: (a, b) => (a?.llm_label || "").localeCompare(b?.llm_label || ""),
    },
    {
      title: `Reason`,
      dataIndex: "llm_reason",
      key: "llm_reason",
      width: rem(204),
      className: "tl",
      render: (text, record) =>
        renderEmptyOrValue(
          text === "error" ? "--" : text,
          "",
          "llm_reason_class"
        ),
      sorter: (a, b) => (a.llm_reason || "").localeCompare(b.llm_reason || ""),
    },
  ];

  useEffect(() => {
    totalCount({ page_count: filteredUsers?.length }); // Set total count directly
  }, [filteredUsers, totalCount]);

  return (
    <>
      <div className="emailtable">
        <DraggableTable
          handleOpen={handleOpen}
          filteredUsers={filteredUsers}
          loading={loading}
          baseColumns={columns}
          drag={true}
          defaultPageSize={defaultPageSize}
          email={true}
          scroll={257}
        />
        <div className="email-body">
          {user?.from_email && user?.from_person_name ? (
            <>
              <div className="email-head">
                <div className="email-left">
                  <h1>{user?.from_person_name}</h1>
                  <span>{user?.from_email}</span>
                </div>
                <div className="email-right">
                  <span>
                    {user?.from_email_time
                      ? new Date(user?.from_email_time)?.toLocaleDateString()
                      : ""}
                  </span>
                  <span>
                    {user?.from_email_time
                      ? new Date(user?.from_email_time).toLocaleTimeString()
                      : ""}
                  </span>
                </div>
              </div>

              <div className="email-sub-body">
                <h1>{user?.from_subject && "Subject"}</h1>
                <span className="df">{user?.from_subject}</span>
              </div>
              <div className="email-sub-content">
                <span>{user?.plain_body}</span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default EmailTable;
