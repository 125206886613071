import React, { useState, useRef, useCallback, useEffect } from "react";
import Button from "../../Components/Button/Button";
import deleteicon from "../../Assets/HeatNumber/deleteIcon.svg";
import manufacturing from "../../Assets/Quality/manufacturing.svg";
import company from "../../Assets/Quality/company.svg";
import factory from "../../Assets/Quality/factory.svg";
// import img19 from "./../../Assets/Quality/image19.svg";
// import img20 from "./../../Assets/Quality/image20.svg";
// import gasLabel from "./../../Assets/Quality/image4.svg";
// import { Modal } from "antd";

import "./Quality.scss";
import SnackBar from "../../Components/SnackBar";
import _http from "../../Utils/Api/_http";
import SelectSearch from "../../Components/SelectFields/SelectField";
import InputField from "../../Components/InputField/InputField";
import TextField from "../../Components/TextField/TextField";
// import Qualityyes from "../../Components/Svg/Quality_yes";
// import Qualityno from "../../Components/Svg/Quality_no";
// import Qualityupload from "../../Components/Svg/Quality_upload";
import { rem } from "../../Components/Rem_func";
// import QualityPopUp from "./QualityPopUp";
// import QualityOpen from "./QualityOpen";
import { useSelector } from "react-redux";
import { selectUserData } from "../../Redux/Reducers";
const HeatNumber = () => {
  const [heatNumbers, setHeatNumbers] = useState([""]);
  const [result, setResult] = useState([]);
  const [files, setFiles] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [MTCdata, setMTCdata] = useState({
    gasket: "",
    company_name: "",
    po_no: "",
    Cert_no: "",
    doc_no: "",
    mtc_date: "",
    desp: "",
  });
  // const [modalVisible, setModalVisible] = useState(false);
  // const [modalContent, setModalContent] = useState(null);
  const [erring, setErring] = useState({});
  const [mtc, setMtc] = useState(false);
  const [heat, setHeat] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const access = useSelector(selectUserData);
  const [selectedBranch, setSelectedBranch] = useState();
  const [inputFields, setInputFields] = useState([
    { item_no: "", size: "", quant: "" },
  ]);
  const [GasketOptions, SetGasketOptions] = useState("");
  const [gaskets, setGaskets] = useState();
  const [apiInProgress, setApiInProgress] = useState(false);

  useEffect(() => {
    const fetchGasketOptions = async () => {
      try {
        const response = await _http.get("/api/fetch-keys-mtc-page");

        const templates = response?.data?.templates;
        setGaskets(templates); // Store full templates for future use

        // Determine gasket options based on access.location
        if (access?.location === "all") {
          SetGasketOptions(templates?.abu_dhabi); // Default to "abu_dhabi"
        } else {
          if (access?.location === "Abu Dhabi") {
            SetGasketOptions(templates?.abu_dhabi);
            setSelectedIndex(0);
          } else if (access?.location === "Dubai") {
            SetGasketOptions(templates?.dubai);
            setSelectedIndex(1);
          } else if (access?.location === "Oman") {
            SetGasketOptions(templates?.oman);
            setSelectedIndex(2);
          }
        }
      } catch (error) {
        setToast({ error: true });
        setError("Error fetching Gasket options");
      }
    };

    fetchGasketOptions();
  }, [access?.location]); // Re-fetch only when access.location changes

  // Call this when API starts
  const startApiCall = () => {
    setApiInProgress(true);
  };
  // Call this when API ends
  const endApiCall = () => {
    setApiInProgress(false);
  };

  const addHeatBox = useCallback(() => {
    setHeatNumbers([...heatNumbers, ""]);
  }, [heatNumbers]);

  const additemBox = () => {
    setInputFields([...inputFields, { item_no: "", size: "", quant: "" }]);
  };
  const handleHeatChange = (index, value) => {
    const alphanumericOnly = value
      ?.replace(/\s+/g, " ")
      ?.trimStart()
      ?.toUpperCase();

    const newHeatNumbers = [...heatNumbers];
    newHeatNumbers[index] = alphanumericOnly;
    setHeatNumbers(newHeatNumbers);
  };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const values = [...inputFields];
    values[index][name] = value;
    setInputFields(values);
  };

  const deleteRow = useCallback(
    (index) => {
      const newHeatNumbers = [...heatNumbers];
      newHeatNumbers.splice(index, 1);
      setHeatNumbers(newHeatNumbers);
    },
    [heatNumbers]
  );

  const deleteItem = useCallback(
    (index) => {
      const newItems = [...inputFields];
      newItems.splice(index, 1);
      setInputFields(newItems);
    },
    [inputFields]
  );

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const fetchData = useCallback(async () => {
    const nonEmptyHeatNumbers = heatNumbers.filter(
      (heat) => heat.trim() !== ""
    );

    if (nonEmptyHeatNumbers.length === 0) {
      setError("Please add Heat Number");
      setToast({ error: true });
      setHeat(false);
      return;
    } else {
      setHeatNumbers(nonEmptyHeatNumbers);
      setHeat(true);
      startApiCall();

      try {
        const heatNumbersParam = nonEmptyHeatNumbers?.join(","); // Convert array to comma-separated string

        const response = await _http.get(`api/get-detail-heat-number`, {
          params: {
            heat_number: heatNumbersParam,

            heat_no_type: MTCdata?.gasket
              ? MTCdata?.gasket.includes("Fasteners")
                ? "fastener"
                : "other"
              : "",
          },
        });
        setResult(response.data);
      } catch (error) {
        setToast({ error: true });
        if (error?.response?.data?.error.includes("heat_no_type")) {
          setError("Select Gasket Material ❌");
        } else {
          setError(error.message);
        }
      }
    }
    setHeat(false);
    endApiCall();
  }, [heatNumbers, MTCdata?.gasket]);

  const uploadPdf = async (e) => {
    if (!MTCdata?.gasket) {
      setToast({ error: true });
      setError("Select Gasket Material ❌");
      fileInputRef.current.value = "";
    } else {
       const selectedFile = e.target.files[0]; // Get the first file directly
    if (!selectedFile) return;
      setFiles(true);
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const response = await _http.post(
          MTCdata?.gasket.includes("Fasteners")
            ? `api/upload_pdf_fasteners`
            : `/api/upload_pdf`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle successful response
        if (response?.data?.message) {
          setToast({ submit: true });
          setError(response.data.message);
        } else if (response?.data?.error) {
          setToast({ error: true });
          setError(response.data.error);
        }
      } catch (error) {
        // Handle error cases
        setToast({ error: true });
        if (error?.response?.data?.error === "Invalid file format") {
          setError("Invalid File Format");
        } else {
          setError("Error Uploading File");
        }
      } finally {
        setFiles(false); // Re-enable the button
      }
    }
  };

  const generateMTC = async () => {
    const requiredFields = [
      "company_name",
      "po_no",
      "Cert_no",
      "doc_no",
      "mtc_date",
      "desp",
      "gasket",
    ];

    const emptyFields = [];

    // Check the required fields in MTCdata
    const newErring = { ...erring };
    requiredFields.forEach((field) => {
      if (!MTCdata[field] || MTCdata[field].toString().trim() === "") {
        newErring[`${field}_error`] = true;
        emptyFields.push(field);
      } else {
        newErring[`${field}_error`] = false;
      }
    });

    // Check each item in inputFields
    inputFields.forEach((item, index) => {
      if (!item.item_no.trim()) {
        newErring[`item_no_error_${index}`] = true;
        emptyFields.push(`item_no_${index}`);
      } else {
        newErring[`item_no_error_${index}`] = false;
      }

      if (!item.size.trim()) {
        newErring[`size_error_${index}`] = true;
        emptyFields.push(`size_${index}`);
      } else {
        newErring[`size_error_${index}`] = false;
      }

      if (!item.quant.trim()) {
        newErring[`quant_error_${index}`] = true;
        emptyFields.push(`quant_${index}`);
      } else {
        newErring[`quant_error_${index}`] = false;
      }
    });

    setErring(newErring);

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });

    if (emptyFields.length > 0) {
      setError("Please fill the input fields");
      setToast({ error: true });
      return;
    }

    const nonEmptyHeatNumbers = heatNumbers.filter(
      (heat) => heat.trim() !== ""
    );

    if (nonEmptyHeatNumbers.length === 0) {
      setError("Please add Heat Number");
      setToast({ error: true });
      setHeat(false);
      return;
    }

    // Check if any heat number has a status of 'no'
    const noHeatNumbers = heatNumbers.filter((heat) => {
      const resultIndex = result.findIndex(
        (item) => item.number.trim() === heat.trim()
      );
      return resultIndex !== -1 && result[resultIndex].status === "no";
    });

    // If there are heat numbers with status 'no', set toast and error
    if (noHeatNumbers.length > 0) {
      setError("Please upload a PDF for the heat number(s) with status 'no'.");
      setToast({ error: true });
      return;
    }

    setMtc(true);
    startApiCall();
    try {
      const response = await _http.post(
        MTCdata?.gasket.includes("Fasteners")
          ? "/api/FasterMtc"
          : "/api/mtc_doc",
        {
          company_name: MTCdata.company_name,
          gasket_material: MTCdata.gasket,
          heat_no: nonEmptyHeatNumbers,
          po_no: MTCdata.po_no,
          cert_no: MTCdata.Cert_no,
          doc_no: MTCdata.doc_no,
          mtc_date: MTCdata.mtc_date,
          desp: MTCdata.desp,
          items: inputFields,
          ...(access.role_name === "admin" && {
            chosen_location: selectedBranch,
          }),
        },
        {
          responseType: "blob",
        }
      );

      // const blob = new Blob([response.data], {
      //   type: "application/pdf",
      // });
      // setModalContent(
      //   <embed
      //     width={"100%"}
      //     height={"100%"}
      //     src={URL.createObjectURL(blob)}
      //     type="application/pdf"
      //   />
      // );
      // setModalVisible(true);
      // Create a blob URL for the downloaded file
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${MTCdata.company_name}.docx`); // Set the file name
      document.body.appendChild(link);

      link.click(); // Trigger the download
      link.remove(); // Clean up after the download

      // Revoke the blob URL to free up memory
      window.URL.revokeObjectURL(url);
      // const url = window.URL.createObjectURL(blob);
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `${MTCdata.company_name}.docx`);
      // document.body.appendChild(link);
      // link.click();
      // window.URL.revokeObjectURL(url);
      // document.body.removeChild(link);

      // const url = URL.createObjectURL(blob);

      // sessionStorage.setItem("url", url);
      // // Open the generated document in a new tab/window
      // const newTab = window.open("/fileviewer", "_blank");

      // // Optionally focus the new tab
      // if (newTab) {
      //   newTab.focus();
      // }
      setHeatNumbers([""]);
      setMTCdata({
        gasket: "",
        company_name: "",
        po_no: "",
        Cert_no: "",
        doc_no: "",
        mtc_date: "",
        desp: "",
      });
      setInputFields([{ item_no: "", size: "", quant: "" }]);
      setError("File generated successfully");
      setToast({ submit: true });
    } catch (error) {
      console.log("Error Data:", error.response.data);
      setToast({ error: true }); // Show an error toast
      setMtc(false);
      endApiCall();

      if (error.response) {
        if (error.response.status === 404) {
          // Read the Blob data
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const responseData = JSON.parse(reader.result);
              const errorMessage =
                responseData.error || "Heat number is incorrect or not found.";
              setError(errorMessage);
            } catch (parseError) {
              setError("An error occurred while processing the response.");
            }
          };
          reader.readAsText(error.response.data);
        } else {
          setError("Internal Server Error. Please try again later.");
        }
      } else {
        setError("Unable to process request. Check your network connection.");
      }
    }

    setMtc(false);
    endApiCall();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      if (!MTCdata?.gasket) {
        setToast({ error: true });
        setError("Select Gasket Material ❌");
      } else {
        setFiles(droppedFiles[0]);
        uploadPdf({ target: { files: droppedFiles } });
      }
    }
  };

  // array to map images
  const companyName = {
    company: [company, manufacturing, factory],
    branch: ["Abu Dhabi", "Dubai", "Oman"],
    location: ["Abu Dhabi, UAE", "Dubai, UAE", "Sohar, Oman"],
  };

  const inputDatas = [
    {
      subHead: "Customer",
      subValue: "company_name",
      placeholder: "Enter Company Name",
      pattern: "onlyAlpha",
    },
    {
      subHead: "PO Number",
      subValue: "po_no",
      placeholder: "Enter PO Number",
      // pattern: "numberOnly",
    },
    {
      subHead: "Certificate Number",
      subValue: "Cert_no",
      placeholder: "Enter Certificate Number",
      // pattern: "numberOnly",
    },
    {
      subHead: "Document Number",
      subValue: "doc_no",
      placeholder: "Enter Document Number",
      // pattern: "numberOnly",
    },
    {
      subHead: "Date",
      subValue: "mtc_date",
      // placeholder: "DD/MM/YYYY",
      type: "date",
      maxDate: maxDate,
    },
  ];

  const handleSelectChange = (name, value) => {
    setMTCdata({ ...MTCdata, [name]: value });
  };
  const imgHandler = (index) => {
    // Mapping indexes to branches and gasket options
    const branchMapping = {
      0: { branch: "abu_dhabi", options: gaskets?.abu_dhabi },
      1: { branch: "dubai", options: gaskets?.dubai },
      2: { branch: "Oman", options: gaskets?.oman },
    };
    const selected = branchMapping[index];
    if (selected) {
      setSelectedIndex(index);
      setSelectedBranch(selected.branch);
      SetGasketOptions(selected.options);

      // Reset the gasket if it's not in the new options
      if (!selected?.options?.includes(MTCdata?.gasket)) {
        setMTCdata((prevData) => ({
          ...prevData,
          gasket: "",
        }));
      }
    }
  };

  const handleReset = () => {
    setMTCdata({
      gasket: "",
      company_name: "",
      po_no: "",
      Cert_no: "",
      doc_no: "",
      mtc_date: "",
      desp: "",
    });
    setInputFields([{ item_no: "", size: "", quant: "" }]);
    setHeatNumbers([""]);
    setResult([]);
  };
  return (
    <section className="quality">
      <p className="quality_title">Quality</p>
      <div className="container1">
        <div className="df flexColumn align-start header w100">
          {/* {access.role_name === "admin" && ( */}
          <div className="company_logos">
            {companyName?.company?.map((company, index) => {
              const branch = companyName.branch[index];
              const location = companyName.location[index];

              // Check if the item matches the condition
              if (
                !(access?.location === "all" || access?.location === branch)
              ) {
                return null; // Skip this item
              }

              return (
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  key={index}
                >
                  <p
                    className={
                      selectedIndex === index
                        ? "branch_location_sty_active"
                        : "branch_location_sty"
                    }
                  >
                    {location}
                  </p>

                  <div
                    key={index}
                    className={`img_sec df flexColumn ${
                      selectedIndex === index ? "active" : ""
                    }`}
                    onClick={() => imgHandler(index)}
                  >
                    <button
                    // style={
                    //   index === companyName.company.length - 1
                    //     ? { opacity: 0.3, cursor: "no-drop" }
                    //     : {}
                    // }
                    >
                      <img src={company} alt="error" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="certificate-section df">
          <div>
            <div className="material_title sub-head1">
              Material Test Certificate
            </div>
            <div className="heat-search ">
              {/* flexBox */}
              <div style={{ width: rem(282) }}>
                <SelectSearch
                  label="Gasket Material"
                  name="item"
                  Placeholder="Select option"
                  options={GasketOptions}
                  value={MTCdata.gasket}
                  onChange={(value) => handleSelectChange("gasket", value)}
                  hasError={erring.gasket_error}
                  readOnly={mtc}
                />
              </div>
              {inputDatas.map((x, index) => {
                return (
                  <div key={index} style={{ width: rem(282) }}>
                    <InputField
                      label={x.subValue}
                      labelName={x.subHead}
                      type={x.type || "text"}
                      placeholder={x.placeholder}
                      fieldData={MTCdata[x.subValue]}
                      setFormData={setMTCdata}
                      maxLength={x.maxDate ? x.maxDate : ""}
                      hasError={erring[`${x.subValue}_error`]}
                      readOnly={mtc}
                      pattern={x.pattern ? x.pattern : null}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="vertical-line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(1)}
              height={"100%"}
              viewBox="0 0 1 290"
              fill="none"
            >
              <path d="M0.498333 0L0.499987 289.5" stroke="#D7DADE" />
            </svg>
          </div>
          <div>
            <p className="sub-head1">Upload Heat Number File to DB</p>
            <div
              className="upload_heat"
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleFileUpload}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(24)}
                height={rem(24)}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11 16V7.85L8.4 10.45L7 9L12 4L17 9L15.6 10.45L13 7.85V16H11ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"
                  fill="#878787"
                />
              </svg>

              <p className="drag">
                Drag and drop or select a file to upload in DB
              </p>

              <div className="btn_upload">
                <Button
                  btnname={files ? "Uploading..." : "Upload"}
                  height={38}
                  fs={15}
                  fw={500}
                  width={100}
                  disabled={files}
                  br={5}
                  cl="#1e6641"
                  bg="#fff"
                  border={`${rem(1)} solid #1e6641`}
                  box_s={"none"}
                />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => uploadPdf(e)}
                accept=".pdf"
                disabled={files}
              />
            </div>
          </div>
        </div>
        <div className="heat-description-section">
          <div className="parent_heat">
            <div className="sub-head1">Heat Number & Verification</div>
            <div className="heat_styles">
              <p className="heatTitle">Heat Number</p>
              <div className="heat_adding flexBox df">
                {heatNumbers?.map((heat, index) => {
                  const resultIndex = result.findIndex(
                    (item) => item.number.trim() === heat.trim()
                  );
                  const resultStatus =
                    resultIndex !== -1 && result[resultIndex].status;

                  return (
                    <div className="flexBox align-start flexColumn" key={index}>
                      <div
                        className={`input-filter heat df ${
                          resultStatus === "yes"
                            ? "true-heat"
                            : resultStatus === "no"
                            ? "false-heat"
                            : ""
                        }`}
                        style={{
                          width: rem(264),
                          position: "relative",
                          paddingRight: rem(10),
                        }}
                      >
                        <input
                          style={{ border: "none", width: rem(264) }}
                          placeholder="Search Heat Number"
                          autoSave="true"
                          value={heat}
                          onChange={(e) =>
                            handleHeatChange(index, e.target.value)
                          }
                          // maxLength={20}
                          readOnly={apiInProgress || mtc === heat}
                        />

                        {/* {resultStatus === "yes" && (
                          <div style={{ width: "10%" }}>
                            <Qualityyes />
                          </div>
                        )}

                        {resultStatus === "no" && (
                          <div style={{ width: "10%" }}>
                            <Qualityno />
                          </div>
                        )} */}
                        {heatNumbers.length > 1 && (
                          <img
                            src={deleteicon}
                            alt="img"
                            onClick={() => deleteRow(index)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>

                      {resultStatus === "no" && (
                        <span className="heat-comment">
                          Heat number unavailable, upload the PDF
                        </span>
                      )}
                    </div>
                  );
                })}
                {
                  <button
                    onClick={addHeatBox}
                    className="addBtn"
                    style={{ border: "none", paddingTop: rem(7) }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={rem(20)}
                      height={rem(20)}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <rect
                        x="0.714286"
                        y="0.714286"
                        width="18.5714"
                        height="18.5714"
                        rx="9.28571"
                        stroke="#A2B7AE"
                        strokeWidth="1.42857"
                      />
                      <path
                        d="M9.38734 10.6123H5.71387V9.38783H9.38734V5.71436H10.6118V9.38783H14.2853V10.6123H10.6118V14.2858H9.38734V10.6123Z"
                        fill="#1E6641"
                      />
                    </svg>
                    {/* <img src={Add} alt="icon" /> */}
                  </button>
                }
              </div>

              <div className="verify_btn">
                <Button
                  onclick={fetchData}
                  btnname={heat ? "Checking..." : "Verify"}
                  cl="#1e6641"
                  bg="#fff"
                  border={`${rem(1)} solid #1e6641`}
                  box_s={"none"}
                  disabled={heat}
                  br={5}
                  fs={15}
                  width={100}
                  height={38}
                />
              </div>
            </div>
          </div>

          <div className="quality_items">
            <p className="sub-head1">PO Details</p>
            <div className="description-input">
              <TextField
                label={`desp`}
                labelName="Description"
                type="text"
                fieldData={MTCdata.desp}
                setFormData={setMTCdata}
                hasError={erring[`desp_error`]}
                readOnly={mtc}
                height={rem(20)}
                placeholder={"Enter Description"}
              />
            </div>

            <div className="item_container">
              {inputFields.map((inputField, index) => (
                <div className="df quality-desp-inp" key={index}>
                  <div className="inp_sec">
                    <div style={{ width: rem(168) }}>
                      <InputField
                        label={index === 0 ? `item_no_${index}` : ""}
                        labelName={index === 0 ? "Item Number" : ""}
                        type="text"
                        fieldData={inputField.item_no}
                        setFormData={(data) =>
                          handleItemChange(index, {
                            target: { name: "item_no", value: data },
                          })
                        }
                        // pattern={"numberOnly"}
                        hasError={erring[`item_no_error_${index}`]}
                        onChange={true}
                        // maxLength={10}
                        placeholder="Enter Item Number"
                        readOnly={mtc}
                      />
                    </div>
                    <div style={{ width: rem(168) }}>
                      <InputField
                        label={index === 0 ? `size_${index}` : ""}
                        labelName={index === 0 ? "Size" : ""}
                        fieldData={inputField.size}
                        setFormData={(data) =>
                          handleItemChange(index, {
                            target: { name: "size", value: data },
                          })
                        }
                        hasError={erring[`size_error_${index}`]}
                        onChange={true}
                        // pattern={"text"}
                        // maxLength={10}
                        placeholder="Enter Size"
                        readOnly={mtc}
                      />
                    </div>
                    <div style={{ width: rem(168) }}>
                      <InputField
                        label={index === 0 ? `quant_${index}` : ""}
                        labelName={index === 0 ? "Quantity" : ""}
                        fieldData={inputField.quant}
                        setFormData={(data) =>
                          handleItemChange(index, {
                            target: { name: "quant", value: data },
                          })
                        }
                        hasError={erring[`quant_error_${index}`]}
                        onChange={true}
                        // pattern={"numberOnly"}
                        // maxLength={10}
                        placeholder="Enter Quantity"
                        readOnly={mtc}
                      />
                    </div>
                  </div>
                  <div
                    className="flexBox  justify-end"
                    style={{ gap: rem(10) }}
                  >
                    {inputFields.length > 1 && (
                      <div
                        className="addBtn df justify-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteItem(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={rem(10)}
                          height={rem(12)}
                          viewBox="0 0 10 12"
                          fill="none"
                        >
                          <path
                            d="M2.41656 11.2501C2.09572 11.2501 1.82116 11.136 1.59287 10.9077C1.36458 10.6794 1.25025 10.4046 1.24986 10.0834V2.49982H0.666504V1.33311H3.58327V0.749756H7.08339V1.33311H10.0002V2.49982H9.41681V10.0834C9.41681 10.4043 9.30267 10.679 9.07438 10.9077C8.8461 11.1364 8.57134 11.2505 8.2501 11.2501H2.41656ZM8.2501 2.49982H2.41656V10.0834H8.2501V2.49982ZM3.58327 8.91671H4.74998V3.66652H3.58327V8.91671ZM5.91669 8.91671H7.08339V3.66652H5.91669V8.91671Z"
                            fill="#1E6641"
                          />
                        </svg>
                      </div>
                    )}
                    {index === inputFields.length - 1 && (
                      <button
                        onClick={additemBox}
                        className="addBtn bt2"
                        style={{ border: "none" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={rem(20)}
                          height={rem(20)}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <rect
                            x="0.714286"
                            y="0.714286"
                            width="18.5714"
                            height="18.5714"
                            rx="9.28571"
                            stroke="#A2B7AE"
                            strokeWidth="1.42857"
                          />
                          <path
                            d="M9.38734 10.6123H5.71387V9.38783H9.38734V5.71436H10.6118V9.38783H14.2853V10.6123H10.6118V14.2858H9.38734V10.6123Z"
                            fill="#1E6641"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="quality_btns">
              <Button
                btnname={"Reset"}
                onclick={handleReset}
                height={38}
                width={119}
                fs={15}
                br={5}
                disabled={mtc}
                bg="#fff"
                cl="#1E6641"
                border={`${rem(1)} solid #1e6641`}
              />
              <Button
                btnname={mtc ? "Generating..." : "Generate MTC"}
                onclick={generateMTC}
                height={38}
                // width={131}
                fs={15}
                br={5}
                disabled={mtc}
              />
            </div>
          </div>
        </div>
        {/* <Modal
          className="feedback-pdf"
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
        >
          {modalContent}
        </Modal> */}
        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handletoast}
          Open={toast?.error}
        />
        <SnackBar
          message={errors}
          severity={"success"}
          handleClose={handletoast}
          Open={toast?.submit}
        />
      </div>
    </section>
  );
};

export default HeatNumber;
