import React from "react";
import CurrencyBieChart from "./CurrencyBieChart";

const SalesRange = ({ salesrange, formatNumber }) => {
  const salesData = [
    {
      currency: "AED",
      color: "#009739",
      data: {
        enquiries: salesrange?.enquiries?.AED || 0,
        quote: salesrange?.quote?.AED || 0,
        awarded: salesrange?.awarded?.AED || 0,
        totalSales: salesrange?.total_sales,
        quoteEnquiry: salesrange?.quoteEnquiry?.AED || 0,
      },
      otherQuotes: salesrange?.quote?.OMR + salesrange?.quote?.USD,
      otherAwarded: salesrange?.awarded?.OMR + salesrange?.awarded?.USD,
    },
    {
      currency: "USD",
      color: "#002147",
      data: {
        enquiries: salesrange?.enquiries?.USD || 0,
        quote: salesrange?.quote?.USD || 0,
        awarded: salesrange?.awarded?.USD || 0,
        totalSales: salesrange?.total_sales,
        quoteEnquiry: salesrange?.quoteEnquiry?.USD || 0,
      },
      otherQuotes: salesrange?.quote?.AED + salesrange?.quote?.OMR,
      otherAwarded: salesrange?.awarded?.AED + salesrange?.awarded?.OMR,
    },
    {
      currency: "OMR",
      color: "#C8102E",
      data: {
        enquiries: salesrange?.enquiries?.OMR || 0,
        quote: salesrange?.quote?.OMR || 0,
        awarded: salesrange?.awarded?.OMR || 0,
        totalSales: salesrange?.total_sales,
        quoteEnquiry: salesrange?.quoteEnquiry?.OMR || 0,
      },
      otherQuotes: salesrange?.quote?.AED + salesrange?.quote?.USD,
      otherAwarded: salesrange?.awarded?.AED + salesrange?.awarded?.USD,
    },
  ];

  return (
    <div className="sales-range df">
      <div className="range-box df">
        {salesData.map((item) => (
          <div key={item.currency} className="df boxes">
            <span className="currency-type">{item.currency}</span>

            {/* Enquiries */}
            <div className="box df">
              <div className="df flexColumn align-start">
                <span className="label"># of Enquiries</span>
                <span className="value">0</span>
              </div>
              <CurrencyBieChart
                colour1={item.color}
                colour2={"#FFFFFF"}
                data1={item.data.enquiries}
                data2={item.data.totalSales - item.data.enquiries}
                label1={`${item.currency} sales`}
                label2={"Others"}
              />
            </div>

            {/* Enquiries Quoted */}
            <div className="box df">
              <div className="df flexColumn align-start">
                <span className="label">Enquiries Quoted</span>
                <span className="value">{item.data.enquiries}</span>
              </div>
              <CurrencyBieChart
                colour1={item.color}
                colour2={"#FFFFFF"}
                data1={item.data.enquiries}
                data2={item.data.enquiries - item.data.quoteEnquiry}
                label1={`${item.currency} Quoted`}
                label2={"Others"}
              />
            </div>

            {/* Quoted */}
            <div className="box df">
              <div className="df flexColumn align-start">
                <span className="label">Quoted ({item.currency})</span>
                <span className="value">
                  {formatNumber(item.data.quote?.toFixed(1)) || 0}
                </span>
              </div>
              <div>
                <CurrencyBieChart
                  colour1={item.color}
                  colour2={"#FFFFFF"}
                  data1={item.data.quote}
                  data2={item.otherQuotes}
                  label1={"Quoted value"}
                  label2={"Others"}
                />
                <div
                  className="curreny-type"
                  style={{ color: item.currency === "OMR" ? "#1e6641" : "" }}
                >
                  {item.currency}
                </div>
              </div>
            </div>

            {/* Awarded */}
            <div className="box df">
              <div className="df flexColumn align-start">
                <span className="label">Awarded ({item.currency})</span>
                <span className="value">
                  {formatNumber(item.data.awarded?.toFixed(1)) || 0}
                </span>
              </div>
              <div>
                <CurrencyBieChart
                  colour1={item.color}
                  colour2={"#FFFFFF"}
                  data1={item.data.awarded}
                  data2={item.otherAwarded}
                  label1={"Awarded value"}
                  label2={"Others"}
                />
                <div
                  className="curreny-type"
                  style={{ color: item.currency === "OMR" ? "#1e6641" : "" }}
                >
                  {item.currency}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalesRange;
