import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import searchIcon from "./../../../../Assets/UserIcon/usersearchicon.svg";
import Delete from "./../../../../Assets/Quiz/Delete.svg";
// import Pdf from "./../../Assets/Quiz/PDF.svg";
import document from "./../../../../Assets/Quiz/Manual.svg";
import csv from "./../../../../Assets/Quiz/csv.svg";
import xlsx from "./../../../../Assets/Quiz/xlsx.svg";
import { useNavigate } from "react-router-dom";
import _http from "../../../../Utils/Api/_http";
import SnackBar from "../../../../Components/SnackBar";
import Button from "../../../../Components/Button/Button";
import { rem } from "../../../../Components/Rem_func";
// import Animation from "../../Assets/Quiz/Animation.gif";
import QRbutton from "../../../../Assets/Quiz/QRbutton.svg";
import QuizModel from "./QuizPopup";
import QrModel from "./Qr_PopUp";

const QuizRecent = () => {
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrpng, setQrPng] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [searchValue, SetSearchValue] = useState("");
  const [apidata, setApidata] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [poolname, setPoolname] = useState("");
  //getting the pool data from the admin questions api
  const [Pools, setPools] = useState();
  const [data, setData] = useState([
    {
      title: "",
      question_text: "",
      choices: [
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
        { choice_text: "", is_correct: false },
      ],
    },
  ]);

  const calculateTime = (registeredTime) => {
    const registeredDate = new Date(registeredTime);
    const currentDate = new Date();

    const diffMs = currentDate - registeredDate; // Difference in milliseconds
    const totalSeconds = Math.floor(diffMs / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);

    if (totalDays > 0) {
      return `${totalDays} day${totalDays > 1 ? "s" : ""} ago`;
    } else if (totalHours > 0) {
      return `${totalHours} hour${totalHours > 1 ? "s" : ""} ago`;
    } else if (totalMinutes > 0) {
      return `${totalMinutes} minute${totalMinutes > 1 ? "s" : ""} ago`;
    } else {
      return `${totalSeconds} second${totalSeconds > 1 ? "s" : ""} ago`;
    }
  };

  // fetching api

  const RecentQuestion = async () => {
    setLoading(true);
    try {
      const response = await _http.get("/quiz/Get-question-pools");
      setApidata(response.data);
      setFilteredData(response.data);

      setToast(
        (response.data.message && { submit: true }) ||
          (response.data.error && { error: true })
      );
      setError(response.data.message || response.data.error);
      setLoading(false);
    } catch (er) {
      setError("Error Fetching  Data");
      setToast({ error: true });
    }
  };
  useEffect(() => {
    RecentQuestion();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const showModal = async (e, id, name) => {
    e.stopPropagation();
    setIsModalOpen(true);
    setLoading(true);
    try {
      const response = await _http.post(
        "/quiz/generate-qr-code",
        {
          id: id,
        },
        {
          responseType: "blob",
        }
      );
      sessionStorage.setItem("QuizId", id);

      const url = URL.createObjectURL(response.data);
      setQrPng(url);
      setLoading(false);
    } catch (err) {
      setToast({ error: true });
      setError("Error Generating Qr");
      setLoading(false);
    }
    setPoolname(name);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const deleteQuestionPool = async (e, id) => {
    e.stopPropagation();

    setLoading(true);
    try {
      await _http.delete("quiz/question-pools", {
        data: {
          id: id,
        },
      });
      setLoading(false);
      setTimeout(() => {
        setError("Question Pool Deleted");
        setToast({ submit: true });
      }, 1000);

      RecentQuestion();
    } catch (er) {
      setLoading(false);
    }
  };
  const Get_Recent_Question = async (id) => {
    try {
      const response = await _http.post("quiz/Admin_Questions", {
        id: id,
      });
      // title = response.data.pool.pool_name;
      setPools(response.data.pool);
      setData(response.data.questions);
    } catch (err) {
      setApidata("");
      setToast({ error: true });
      setError("❌ Something Went Wrong! Please Try Again");
    }
  };

  useEffect(() => {
    const filtered = (apidata || [])?.filter((data) =>
      data?.pool_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchValue, apidata]);
  return (
    <div className="up-31">
      <span>Recent</span>
      <div className="data1">
        <div className="btn1">
          <div className="quiz-sh-fl">
            {apidata?.length > 0 && (
              <div className="user-search df">
                <input
                  type="text"
                  placeholder="Search"
                  name="user"
                  value={searchValue}
                  onChange={(e) => SetSearchValue(e.target.value)}
                />
                <img src={searchIcon} alt="icon" />
              </div>
            )}

            {/* <Popover
              trigger="click"
              open={true}
              placement="bottomRight"
              // onOpenChange={handleOpenChange}
              // content={
              //   <FilterUser
              //     // hide={hide}
              //     // roles={roles}
              //     // names={names}
              //     // userList={userList}
              //     // filteredData={filteredData}
              //     // setFilteredData={setFilteredData}
              //   />
              // }
            >
              <Space>
                <span
                  className="filter-head"
                  data-tooltip-id="tooltip-arrow"
                  data-tooltip-content="Filter"
                >
                  <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                  <img src={filterOn ? green_down : down} alt="icon" />
                </span>
              </Space>
            </Popover> */}
          </div>
          <div className="RecentQuestionsHeader">
            <div className="lftrecent">
              <span>Quiz Title</span>
            </div>
            <div className="rgtrecent">
              <span className="schedule">Scheduled On</span>
              <span>Duration</span>
              <span className="questions">No of Questions</span>
              <span>QR Code</span>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="spinners"></div>
        ) : (
          <>
            {filteredData?.length > 0 ? (
              <div className="up-box1">
                {/* <QuizDrag Comp={clicked} /> */}

                {filteredData?.map((item, index) => (
                  <div
                    className="q-details"
                    key={item.id}
                    onClick={() => {
                      Get_Recent_Question(item.id);
                      setOpen(true);
                      setPoolname(item.pool_name);
                    }}
                  >
                    <div className="q-title">
                      <div className="q-pooler">
                        <div className="quizrecentpng">
                          <img
                            src={
                              item.source ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ? xlsx
                                : item.source === "text/csv"
                                ? csv
                                : document
                            }
                            alt=""
                          />
                        </div>
                        <span>
                          {item?.pool_name}{" "}
                          <p>{`${calculateTime(item?.registered_time)}`}</p>
                        </span>
                      </div>

                      <div className="q-para">
                        <p className="schedule">
                          <span className="schsvg">
                            {item?.schedule_time && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={rem(18)}
                                height={rem(18)}
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M14.25 3H12.75V2.25C12.75 2.05109 12.671 1.86032 12.5303 1.71967C12.3897 1.57902 12.1989 1.5 12 1.5C11.8011 1.5 11.6103 1.57902 11.4697 1.71967C11.329 1.86032 11.25 2.05109 11.25 2.25V3H6.75V2.25C6.75 2.05109 6.67098 1.86032 6.53033 1.71967C6.38968 1.57902 6.19891 1.5 6 1.5C5.80109 1.5 5.61032 1.57902 5.46967 1.71967C5.32902 1.86032 5.25 2.05109 5.25 2.25V3H3.75C3.15326 3 2.58097 3.23705 2.15901 3.65901C1.73705 4.08097 1.5 4.65326 1.5 5.25V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.841C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.841C16.2629 15.419 16.5 14.8467 16.5 14.25V5.25C16.5 4.65326 16.2629 4.08097 15.841 3.65901C15.419 3.23705 14.8467 3 14.25 3ZM15 14.25C15 14.4489 14.921 14.6397 14.7803 14.7803C14.6397 14.921 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.921 3.21967 14.7803C3.07902 14.6397 3 14.4489 3 14.25V9H15V14.25ZM15 7.5H3V5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H5.25V5.25C5.25 5.44891 5.32902 5.63968 5.46967 5.78033C5.61032 5.92098 5.80109 6 6 6C6.19891 6 6.38968 5.92098 6.53033 5.78033C6.67098 5.63968 6.75 5.44891 6.75 5.25V4.5H11.25V5.25C11.25 5.44891 11.329 5.63968 11.4697 5.78033C11.6103 5.92098 11.8011 6 12 6C12.1989 6 12.3897 5.92098 12.5303 5.78033C12.671 5.63968 12.75 5.44891 12.75 5.25V4.5H14.25C14.4489 4.5 14.6397 4.57902 14.7803 4.71967C14.921 4.86032 15 5.05109 15 5.25V7.5Z"
                                  fill="#6B6B6B"
                                />
                              </svg>
                            )}
                          </span>
                          {item?.schedule_time ? (
                            <span className="scheduleTime">
                              {new Date(item?.schedule_time)?.toLocaleString()}
                            </span>
                          ) : (
                            <span className="notSch"> "Not Scheduled"</span>
                          )}
                        </p>

                        <p className="duration">
                          <span>{item?.duration_in_minutes} mins</span>
                        </p>

                        <p className="questions">
                          <span>{item?.question_count}</span>
                        </p>

                        <img
                          src={QRbutton}
                          alt="qrbtn"
                          className="recentqrbtn"
                          onClick={(e) => {
                            showModal(e, item.id, item.pool_name);
                          }}
                        />

                        <img
                          src={Delete}
                          alt="delete"
                          className="deleteqrbtn"
                          onClick={(e) => deleteQuestionPool(e, item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Empty>
                <Button
                  btnname="Create One"
                  width={130}
                  height={50}
                  fs={14}
                  fw={500}
                  border={`${rem(2)} solid #1E6641`}
                  onclick={() => navigate("/SpiraQuiz")}
                />
              </Empty>
            )}
          </>
        )}
      </div>

      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      {open && (
        <QuizModel
          recent={true}
          formdata={data}
          setFormdata={setData}
          poolname={poolname}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          Get_Recent_Question={Get_Recent_Question}
          RecentQuestion={RecentQuestion}
          Pools={Pools}
        />
      )}
      <QrModel
        png={qrpng}
        poolname={poolname}
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={closeModal}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
};

export default QuizRecent;
