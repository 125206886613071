import React from "react";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { rem } from "../../../Components/Rem_func";

import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";

const SalesHeader = ({
  userList,
  filteredData,
  setFilters,
  filterOn,
  setFilteredData,
  DashBoard,
  filters,
  title,
  filterComponent,
  employeesArray,
}) => {
  const option = ["Abu Dhabi", "Dubai", "Oman"];

  const access = useSelector(selectUserData);
  const currentYear = new Date().getFullYear();

  const handleSelectChange = (name, value) => {
    setFilters((prevFilters) => {
      let updatedFilters = {
        ...prevFilters,
        [name]: prevFilters[name] === value ? "" : value,
      };
      const resetData = {
        client_email_from: "",
        client_email_to: "",
        sales_email_from: "",
        sales_email_to: "",
        ack_email_from: "",
        ack_email_to: "",
        quotation_email_from: "",
        quotation_email_to: "",
        order_date_from: "",
        order_date_to: "",
      };
      if (name === "quick_filter") {
        updatedFilters = {
          ...updatedFilters,
          start_date: "",
          end_date: "",
          year: "",
          quarter: "",
        };
        setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
      } else if (name === "year") {
        updatedFilters = {
          ...updatedFilters,
          quick_filter: "",
          start_date: "",
          end_date: "",
        };
        setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
      } else if (name === "quarter") {
        updatedFilters = {
          ...updatedFilters,
          quick_filter: "",
          start_date: "",
          end_date: "",
        };
        setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
      } else if (name === "start_date" || name === "end_date") {
        updatedFilters = {
          ...updatedFilters,
          quick_filter: "",
          year: "",
          quarter: "",
        };
      } else if (name === "location") {
        const resetData = {
          managers: [],
          teamleads: [],
          employees: [],
        };
        if (DashBoard) {
          setFilteredData((prevFilter) => ({ ...prevFilter, ...resetData }));
        }
      }
      // else if (name === "sales_team") {
      //   setFilters((prevFilter) => ({
      //     ...prevFilter,
      //     sales_team: value,
      //   }));
      // }

      return updatedFilters;
    });
  };

  const years = Array.from({ length: currentYear - 2018 }, (_, i) => 2019 + i);
  const timeRanges = [
    "Yesterday",
    "Last 3 days",
    "Last week",
    "Last 2 weeks",
    "Last 3 weeks",
    "Last month",
    "Last 3 months",
    "Last 6 months",
    "Last 9 months",
    "Last year",
  ];
  const currency = ["AED", "USD", "OMR"];
  const handleCurrency = (prop) => {
    if (filters.currency === prop) {
      setFilters({ ...filters, currency: "" });
      return;
    }
    setFilters({ ...filters, currency: prop });
  };
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();
  return (
    <div className="sales-header df flexBox">
      <div className="df" style={{ gap: rem(8) }}>
        <div className="df sales-dash-filter sales-year">
          {DashBoard && (
            <div
              onClick={(e) => document.getElementById("dateInput").showPicker()}
              style={{ cursor: "pointer" }}
              className="df sales-date"
            >
              <input
                type="date"
                id="dateInput"
                // min={minDate} // Set min date based on quarter and year
                max={filters.end_date ? filters.end_date : maxDate} // Set max date if endDate is not set
                value={filters?.start_date}
                onChange={(e) =>
                  handleSelectChange("start_date", e.target.value)
                }
                style={{
                  pointerEvents: "none",
                  visibility: "hidden",
                  position: "absolute",
                }}
              />
              <input
                type="text"
                value={filters?.start_date}
                placeholder="Date: From"
                readOnly
                style={{ cursor: "pointer" }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(12)}
                height={rem(12)}
                viewBox="0 0 10 12"
                fill="none"
                style={{ position: "absolute", right: rem(8) }}
              >
                <path
                  d="M3.33333 5.4H2.22222V6.6H3.33333V5.4ZM5.55556 5.4H4.44444V6.6H5.55556V5.4ZM7.77778 5.4H6.66667V6.6H7.77778V5.4ZM8.88889 1.2H8.33333V0H7.22222V1.2H2.77778V0H1.66667V1.2H1.11111C0.816426 1.2 0.533811 1.32643 0.325437 1.55147C0.117063 1.77652 0 2.08174 0 2.4V10.8C0 11.1183 0.117063 11.4235 0.325437 11.6485C0.533811 11.8736 0.816426 12 1.11111 12H8.88889C9.18357 12 9.46619 11.8736 9.67456 11.6485C9.88294 11.4235 10 11.1183 10 10.8V2.4C10 2.08174 9.88294 1.77652 9.67456 1.55147C9.46619 1.32643 9.18357 1.2 8.88889 1.2ZM8.88889 10.8H1.11111V4.2H8.88889V10.8Z"
                  fill="#454545"
                />
              </svg>
            </div>
          )}
          {DashBoard && (
            <div
              onClick={(e) =>
                document.getElementById("dateInput1").showPicker()
              }
              style={{ cursor: "pointer" }}
              className="df sales-date"
            >
              <input
                type="date"
                id="dateInput1"
                min={filters.start_date ? filters.start_date : ""} // Set min date for endDate based on selected startDate
                max={maxDate} // Set max date based on quarter and year
                value={filters?.end_date}
                onChange={(e) => handleSelectChange("end_date", e.target.value)}
                style={{
                  pointerEvents: "none",
                  visibility: "hidden",
                  position: "absolute",
                }}
              />
              <input
                type="text"
                value={filters?.end_date}
                placeholder="Date: To"
                readOnly
                style={{ cursor: "pointer" }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(12)}
                height={rem(12)}
                viewBox="0 0 10 12"
                fill="none"
                style={{ position: "absolute", right: rem(8) }}
              >
                <path
                  d="M3.33333 5.4H2.22222V6.6H3.33333V5.4ZM5.55556 5.4H4.44444V6.6H5.55556V5.4ZM7.77778 5.4H6.66667V6.6H7.77778V5.4ZM8.88889 1.2H8.33333V0H7.22222V1.2H2.77778V0H1.66667V1.2H1.11111C0.816426 1.2 0.533811 1.32643 0.325437 1.55147C0.117063 1.77652 0 2.08174 0 2.4V10.8C0 11.1183 0.117063 11.4235 0.325437 11.6485C0.533811 11.8736 0.816426 12 1.11111 12H8.88889C9.18357 12 9.46619 11.8736 9.67456 11.6485C9.88294 11.4235 10 11.1183 10 10.8V2.4C10 2.08174 9.88294 1.77652 9.67456 1.55147C9.46619 1.32643 9.18357 1.2 8.88889 1.2ZM8.88889 10.8H1.11111V4.2H8.88889V10.8Z"
                  fill="#454545"
                />
              </svg>
            </div>
          )}

          <div className="df">
            {/* <span className="sales-label">Quick Filter</span> */}
            <div style={{ width: !DashBoard ? rem(122) : rem(122) }}>
              <SelectSearch
                options={timeRanges}
                name="quick_filter"
                Placeholder="Quick Filter"
                value={filters.quick_filter}
                onChange={(value) => handleSelectChange("quick_filter", value)}
                height={32}
                dropHeight={true}
                radio={true}
                logo={
                  !DashBoard ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={rem(12)}
                      height={rem(12)}
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M10.5 1.2H8.7V0.3C8.7 0.220435 8.66839 0.144129 8.61213 0.087868C8.55587 0.0316071 8.47957 0 8.4 0C8.32044 0 8.24413 0.0316071 8.18787 0.087868C8.13161 0.144129 8.1 0.220435 8.1 0.3V1.2H3.9V0.3C3.9 0.220435 3.86839 0.144129 3.81213 0.087868C3.75587 0.0316071 3.67957 0 3.6 0C3.52044 0 3.44413 0.0316071 3.38787 0.087868C3.33161 0.144129 3.3 0.220435 3.3 0.3V1.2H1.5C1.10232 1.20048 0.721067 1.35866 0.439866 1.63987C0.158665 1.92107 0.000476357 2.30232 0 2.7V10.5C0.000476357 10.8977 0.158665 11.2789 0.439866 11.5601C0.721067 11.8413 1.10232 11.9995 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5V2.7C12 2.30218 11.842 1.92064 11.5607 1.63934C11.2794 1.35804 10.8978 1.2 10.5 1.2ZM11.4 10.5C11.4 10.7387 11.3052 10.9676 11.1364 11.1364C10.9676 11.3052 10.7387 11.4 10.5 11.4H1.5C1.26131 11.4 1.03239 11.3052 0.863604 11.1364C0.694821 10.9676 0.6 10.7387 0.6 10.5V5.4H11.4V10.5ZM11.4 4.8H0.6V2.7C0.6 2.2032 1.002 1.8 1.5 1.8H3.3V2.7C3.3 2.77957 3.33161 2.85587 3.38787 2.91213C3.44413 2.96839 3.52044 3 3.6 3C3.67957 3 3.75587 2.96839 3.81213 2.91213C3.86839 2.85587 3.9 2.77957 3.9 2.7V1.8H8.1V2.7C8.1 2.77957 8.13161 2.85587 8.18787 2.91213C8.24413 2.96839 8.32044 3 8.4 3C8.47957 3 8.55587 2.96839 8.61213 2.91213C8.66839 2.85587 8.7 2.77957 8.7 2.7V1.8H10.5C10.7387 1.8 10.9676 1.89482 11.1364 2.0636C11.3052 2.23239 11.4 2.46131 11.4 2.7V4.8Z"
                        fill="#454545"
                      />
                    </svg>
                  ) : (
                    ""
                  )
                }
              />
            </div>
          </div>
          <div className="df">
            {/* <span className="sales-label">Year</span> */}
            <div style={{ width: rem(97) }}>
              <SelectSearch
                options={years.reverse()}
                name="year"
                Placeholder="year"
                value={filters.year}
                onChange={(value) => handleSelectChange("year", value)}
                height={32}
                multiple={true}
                optionCount={true}
                dropHeight={true}
                checkbox={true}
              />
            </div>
          </div>
          <div className="df">
            {/* <span className="sales-label">Quarter</span> */}
            <div style={{ width: rem(108) }}>
              <SelectSearch
                options={["Q1", "Q2", "Q3", "Q4"]}
                name="quarter"
                Placeholder={"Quarter"}
                value={filters.quarter}
                onChange={(value) => handleSelectChange("quarter", value)}
                height={32}
                multiple={true}
                optionCount={true}
                dropHeight={true}
                checkbox={true}
              />
            </div>
          </div>
        </div>

        {access.role_name === "admin" && (
          <div className="sales-location df">
            {/* <span className="sales-label">Location</span> */}
            <div style={{ width: rem(126) }}>
              <SelectSearch
                options={option}
                name="location"
                Placeholder="Location"
                value={filters?.location}
                onChange={(value) => handleSelectChange("location", value)}
                height={32}
                all={true}
                dropHeight={true}
                radio={true}
                // multiple={true}
                logo={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(11)}
                    height={rem(12)}
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <path
                      d="M5.77704 12C5.60689 12.0005 5.43898 11.9631 5.28675 11.8907C5.13452 11.8183 5.00221 11.713 4.90042 11.5832L1.69483 7.51626C1.16443 6.80393 0.849931 5.96641 0.785716 5.0953C0.721501 4.22419 0.910046 3.35304 1.33073 2.57712C1.75141 1.80119 2.38804 1.15038 3.17098 0.695863C3.95391 0.241341 4.85301 0.000611118 5.76995 1.162e-06C6.68688 -0.000608794 7.58633 0.238924 8.36993 0.692404C9.15354 1.14588 9.79111 1.79585 10.2129 2.57121C10.6348 3.34657 10.8246 4.21747 10.7616 5.08866C10.6987 5.95986 10.3854 6.79779 9.85609 7.51083L6.65429 11.582C6.55257 11.7121 6.42022 11.8177 6.26786 11.8903C6.11551 11.9629 5.9474 12.0005 5.77704 12ZM5.77197 0.619044C4.70132 0.615263 3.66757 0.991388 2.8723 1.67408C2.07436 2.35244 1.56579 3.28627 1.44319 4.29822C1.32059 5.31018 1.59248 6.32977 2.20724 7.16338L5.40651 11.2212C5.44957 11.2759 5.50542 11.3203 5.56963 11.3509C5.63383 11.3815 5.70462 11.3974 5.77641 11.3974C5.8484 11.3976 5.91945 11.3817 5.98389 11.3512C6.04833 11.3206 6.10438 11.2761 6.14757 11.2212L9.3424 7.15915C9.77831 6.5717 10.0462 5.88635 10.1199 5.17045C10.1935 4.45454 10.0704 3.7327 9.76248 3.07584C9.45459 2.41899 8.97256 1.84971 8.36376 1.42394C7.75497 0.998173 7.04034 0.730561 6.29009 0.647395C6.11811 0.628318 5.94511 0.618852 5.77197 0.619044Z"
                      fill="#454545"
                    />
                    <path
                      d="M5.77344 6C5.37788 6 4.9912 5.91203 4.6623 5.7472C4.3334 5.58238 4.07705 5.34811 3.92568 5.07403C3.7743 4.79994 3.7347 4.49834 3.81187 4.20736C3.88904 3.91639 4.07952 3.64912 4.35922 3.43934C4.63893 3.22956 4.9953 3.0867 5.38326 3.02882C5.77122 2.97094 6.17335 3.00065 6.53881 3.11418C6.90426 3.22771 7.21661 3.41997 7.43638 3.66665C7.65614 3.91332 7.77344 4.20333 7.77344 4.5C7.77344 4.89783 7.56272 5.27936 7.18765 5.56066C6.81258 5.84196 6.30387 6 5.77344 6ZM5.77344 3.6C5.5361 3.6 5.30409 3.65278 5.10675 3.75168C4.90942 3.85057 4.75561 3.99113 4.66478 4.15559C4.57396 4.32004 4.55019 4.501 4.5965 4.67558C4.6428 4.85016 4.75709 5.01053 4.92491 5.1364C5.09273 5.26226 5.30655 5.34798 5.53933 5.38271C5.77211 5.41743 6.01339 5.39961 6.23266 5.33149C6.45193 5.26337 6.63934 5.14802 6.7712 5.00001C6.90306 4.85201 6.97344 4.678 6.97344 4.5C6.97344 4.26131 6.84701 4.03239 6.62197 3.8636C6.39692 3.69482 6.0917 3.6 5.77344 3.6Z"
                      fill="#454545"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        )}
        {!["Email"].includes(title) && !DashBoard && (
          <div className="sales-year df" style={{ paddingRight: rem(8) }}>
            {["admin", "Manager", "Teamlead"].includes(access?.role_name) && (
              <div style={{ width: rem(164) }} className="df">
                <SelectSearch
                  options={employeesArray?.name}
                  name="sales_team"
                  Placeholder="Sales Members"
                  value={filters?.sales_team}
                  onChange={(value) => handleSelectChange("sales_team", value)}
                  checkbox={true}
                  height={32}
                  dropHeight={true}
                  multiple={true}
                  all={true}
                  optionCount={true}
                  logo={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={rem(13)}
                      height={rem(12)}
                      viewBox="0 0 13 12"
                      fill="none"
                    >
                      <path
                        d="M2.54492 9C2.54492 8.46957 2.75564 7.96086 3.13071 7.58579C3.50578 7.21071 4.01449 7 4.54492 7H8.54492C9.07535 7 9.58406 7.21071 9.95914 7.58579C10.3342 7.96086 10.5449 8.46957 10.5449 9C10.5449 9.26522 10.4396 9.51957 10.252 9.70711C10.0645 9.89464 9.81014 10 9.54492 10H3.54492C3.27971 10 3.02535 9.89464 2.83782 9.70711C2.65028 9.51957 2.54492 9.26522 2.54492 9Z"
                        stroke="#454545"
                        strokeWidth="0.5"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.54492 5C7.37335 5 8.04492 4.32843 8.04492 3.5C8.04492 2.67157 7.37335 2 6.54492 2C5.71649 2 5.04492 2.67157 5.04492 3.5C5.04492 4.32843 5.71649 5 6.54492 5Z"
                        stroke="#454545"
                        strokeWidth="0.5"
                      />
                    </svg>
                  }
                  // multiple={true}
                />
              </div>
            )}
            <label className="df emp-count">{`${employeesArray?.count?.length}/${employeesArray?.totalSalesPerson?.length}`}</label>
          </div>
        )}
        {
          <div className="df" style={{ gap: rem(2) }}>
            {!["Awaiting Quote", "Email"].includes(title) &&
              currency.map((item, id) => (
                <div
                  key={id}
                  className={`${
                    filters.currency === item
                      ? "currency-select"
                      : "currency-notSelect"
                  } currency df`}
                  onClick={() => handleCurrency(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </div>
              ))}
          </div>
        }
      </div>
      {DashBoard ? (
        access.role_name !== "employee" ? (
          <div>{filterComponent}</div>
        ) : (
          ""
        )
      ) : (
        <div>{filterComponent}</div>
      )}
    </div>
  );
};

export default SalesHeader;
