import Quality from "../Pages/Quality/Quality";
import QuoteGenerator from "../Pages/QuoteGenerator/QuoteGenerator";
import AwaitingOrder from "../Pages/SalesTracker/AwaitingOrder/AwaitingOrder";
import AwaitingQuotes from "../Pages/SalesTracker/AwaitingQuotes/AwaitingQuotes";
import DashBoard from "../Pages/SalesTracker/DashBoard/DashBoard";
import Report from "../Pages/SalesTracker/Report/Report";
import Enquires from "../Pages/SalesTracker/Enquires/Enquires";
import OrderPlaced from "../Pages/SalesTracker/OrderPlaced/OrderPlaced";
import User from "../Pages/User/Users/User";
import Feedback from "../Pages/Feedback/Feedback";
import Email from "../Pages/Email/Email";
import RejectedOrder from "../Pages/SalesTracker/RejectedOrder/RejectedOrder";

const dynamicRoutes = [
  {
    path: "Salestracker/Dashboard",
    prop: "sales_tracker",
    component: <DashBoard />,
  },
  {
    path: "awaiting-quotes",
    prop: "sales_tracker",
    component: <AwaitingQuotes />,
  },
  {
    path: "awaiting-order",
    prop: "sales_tracker",
    component: <AwaitingOrder />,
  },
  {
    path: "rejected-order",
    prop: "sales_tracker",
    component: <RejectedOrder />,
  },
  {
    path: "order-placed",
    prop: "sales_tracker",
    component: <OrderPlaced />,
  },
  {
    path: "enquires",
    prop: "sales_tracker",
    component: <Enquires />,
  },
  {
    path: "Salestracker/report",
    prop: "sales_tracker",
    component: <Report />,
  },
  {
    path: "Salestracker/email",
    prop: "sales_tracker",
    component: <Email />,
  },
  {
    path: "user",
    prop: "user_management",
    component: <User />,
  },
  {
    path: "QualityTeam.MTCgenerator",
    prop: "quality",
    component: <Quality />,
  },
  {
    path: "QuoteGenerator",
    prop: "quote_generator",
    component: <QuoteGenerator />,
  },
  {
    path: "feedbackdata",
    prop: "feedback_form",
    component: <Feedback />,
  },
];
export default dynamicRoutes;
