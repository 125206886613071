import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import "./Quotegenerator.scss";
import Button from "../../Components/Button/Button";
import _http from "../../Utils/Api/_http";
// import QuotePopup from "./QuotePopup";
import SelectSearch from "../../Components/SelectFields/SelectField";
import InputField from "../../Components/InputField/InputField";
import { rem } from "../../Components/Rem_func";
import SnackBar from "../../Components/SnackBar";
import manufacturing from "../../Assets/Quality/manufacturing.svg";
import company from "../../Assets/Quality/company.svg";
import factory from "../../Assets/Quality/factory.svg";
import { useSelector } from "react-redux";
import { selectUserData } from "../../Redux/Reducers";

const QuoteGenerator = () => {
  const [furtherError, setFurtherErrors] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [add, setAdd] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [formdata, setFormdata] = useState({
    item: "",
    winding_material: "",
    filler_material: "",
    inner_ring_material: "",
    outer_ring_material: "",
    material_size: "",
    rating: "",
    // price: "",
    part_number: "",
  });
  const access = useSelector(selectUserData);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [location, setLocation] = useState();
  const [tableData, setTableData] = useState({
    quantity: 10,
    currency: "AED",
    local_tax: 10,
    margin: 10,
  });
  const [quotation, setQuotation] = useState("");
  const [isQuote, setIsQuote] = useState(false);
  const [quote, setQuote] = useState();
  const [data_1, setCombinedTableData] = useState([]);
  const [data_2, setFurtherDetail] = useState({
    c_name: "",
    att: "",
    add: "",
    date: "",
    dely: "",
    ship: "",
  });
  const Products = ["SWG", "Adnoc Fastners", "Adnoc RTJ", "Adnoc Prices"];
  const [adnoc, setAdnoc] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  const [dataOptions, SetOptions] = useState();
  const quoteOptions = [
    "Technical",
    "Service",
    "Technical_Commercial",
    "Commercial",
  ];

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  // Function to handle toast closing
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const FetchOptions = async () => {
    try {
      const response = await _http.get("api/quote-generator-api");
      SetOptions(response.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching Options");
    }
  };
  useEffect(() => {
    FetchOptions();
  }, []);
  // add api call
  const handleQuote = async () => {
    const requiredFields = adnoc
      ? [
          "item",
          "part_number",
          "material_size",
          "quantity",
          "currency",
          "local_tax",
          "margin",
        ]
      : [
          "item",
          "winding_material",
          "filler_material",
          // "inner_ring_material",
          "outer_ring_material",
          "material_size",
          "rating",
          "quantity",
          "currency",
          "local_tax",
          "margin",
        ];

    const emptyFields = [];

    setFurtherErrors((prevData) => {
      const newData = { ...prevData };
      const err_data = { ...formdata, ...tableData };
      requiredFields.forEach((field) => {
        if (!err_data[field] || err_data[field].toString().trim() === "") {
          newData[`${field}_error`] = true;
          emptyFields.push(field);
          console.log(field);
        } else {
          newData[`${field}_error`] = false;
        }
      });
      return newData;
    });

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });

    // If there are empty fields, exit the function
    if (emptyFields.length > 0) {
      setToast({ error: true });
      setError("Please fill the required fields");
      return;
    }

    const getValidPromptInput = () => {
      let input;
      do {
        input = prompt("Please enter the amount");
        if (input === null) {
          alert("Input is required. Please enter a value.");
          continue;
        }
        if (/^\d*\.?\d+$/.test(input)) {
          return parseFloat(input);
        } else {
          alert("Please enter valid price.");
        }
      } while (true);
    };

    setAdd(true);
    try {
      const response =
        formdata.item === "SWG"
          ? await _http.post(
              "/api/price_list",
              formdata, // Send formdata directly, not wrapped in an object
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
          : ["Adnoc Fastners"].includes(formdata.item)
          ? await _http.get(
              `/api/get-adnoc-fastners?part_number=${formdata.part_number}`
            )
          : ["Adnoc Prices"].includes(formdata.item)
          ? await _http.get(
              `/api/get-adnoc-prices?part_number_swg=${formdata.part_number}`
            )
          : await _http.get(
              `/api/get-adnoc-rtj?part_number=${formdata.part_number}`
            );
      if (response.status === 404) {
        setError("Please change the material combination");
        setToast({ error: true });
        return;
      }

      const fetchedQuote = adnoc
        ? response?.data?.price !== undefined && response?.data?.price !== null
          ? response?.data?.price
          : getValidPromptInput()
        : response?.data?.[0]?.price ?? 0;

      // Retrieve the actual quote value

      setQuote(fetchedQuote);
      const newEntry = {
        slNo: data_1.length + 1,
        desp: !adnoc
          ? Object.values(formdata).join(", ")
          : response?.data?.short_description?.join(", ") ||
            response?.data?.short_description_swg?.join(", "),
        qty: tableData.quantity,
        rate: `${parseFloat(fetchedQuote)?.toFixed(2)} ${tableData.currency}`,

        cost: `${(
          parseFloat(tableData.quantity) * parseFloat(fetchedQuote) || 0
        ).toFixed(2)} ${tableData.currency}`,

        margin: `${tableData.margin}%`,

        quote: `${(
          (parseFloat(tableData.quantity) * parseFloat(fetchedQuote) || 0) *
          (1 + parseFloat(tableData.margin) / 100)
        ).toFixed(2)} ${tableData.currency}`,
      };

      // Append new data to combinedTableData
      setCombinedTableData((prevData) => [...prevData, newEntry]);
      setFormdata({
        item: "",
        winding_material: "",
        filler_material: "",
        inner_ring_material: "",
        outer_ring_material: "",
        material_size: "",
        rating: "",
        // price: "",
        part_number: "",
      });
      setTableData({ ...tableData, quantity: "", margin: "" });
    } catch (error) {
      console.log("Error Object:", error);

      if (error.response && error.response.status === 404) {
        setError("Please change the material combination");
        setToast({ error: true });
      } else {
        setToast({ error: true });
        setError("Internal server error");
      }
    }
    setAdd(false);
  };

  // const handleChangedata = (value, field) => {
  //   setTableData((prevTableData) => ({
  //     ...prevTableData,
  //     [field]: value,
  //   }));
  // };

  const handleReset = () => {
    setFormdata({
      item: "",
      winding_material: "",
      filler_material: "",
      inner_ring_material: "",
      outer_ring_material: "",
      material_size: "",
      rating: "",
      // price: "",
      part_number: "",
    });
    setTableData({ quantity: "", margin: "", currency: "", tax: "" });
    setQuote("");
    setCombinedTableData([]);
    setFurtherDetail({
      c_name: "",
      att: "",
      add: "",
      date: "",
      dely: "",
      ship: "",
    });
  };

  const handleGenerate = async () => {
    const requiredFields = ["c_name", "att", "add", "date", "dely", "ship"];

    const emptyFields = [];
    if (!data_1[0]) {
      setError("Please add product data");
      setToast({ error: true });
      return;
    }
    setFurtherErrors((prevData) => {
      const newData = { ...prevData };
      const err_data = { ...formdata, ...data_2, ...tableData };

      requiredFields.forEach((field) => {
        if (!err_data[field] || err_data[field].toString().trim() === "") {
          newData[`${field}_error`] = true;
          emptyFields.push(field);
        } else {
          newData[`${field}_error`] = false;
        }
      });
      return newData;
    });

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });

    // If there are empty fields, exit the function
    if (emptyFields.length > 0) {
      setError("Please fill the required fields");
      setToast({ error: true });
      return;
    }

    setGenerate(true);
    try {
      const response = await _http.post(
        "/api/quote_file",
        {
          data_1,
          data_2,
          Tcost: `${data_1
            .reduce(
              (total, item) => total + parseFloat(item.quote.split(" ")[0]),
              0
            )
            .toFixed(2)} ${tableData.currency}`,
          tax: `${parseFloat(Tcost * (tableData.local_tax / 100))?.toFixed(
            2
          )} ${tableData.currency}`,
          f_tax: `${parseFloat(tax + Tcost).toFixed(2)} ${tableData.currency}`,
          ...((access.location === "Oman" || isQuote) && {
            quote_type: quotation || "Service",
          }),
          ...(access.role_name === "admin" && {
            location: location,
          }),
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      setModalContent(
        <embed
          width={"100%"}
          height={"100%"}
          src={URL.createObjectURL(blob)}
          type="application/pdf"
        />
      );
      setModalVisible(true);
      // Append new data to combinedTableData
      setFurtherDetail({
        c_name: "",
        att: "",
        add: "",
        date: "",
        dely: "",
        ship: "",
      });
      setCombinedTableData([]);
      setFormdata({
        item: "",
        winding_material: "",
        filler_material: "",
        inner_ring_material: "",
        outer_ring_material: "",
        material_size: "",
        rating: "",
        // price: "",
        part_number: "",
      });
      setTableData({ quantity: "", margin: "", currency: "", tax: "" });
      setQuotation("");
      setQuote("");
      setSelectedIndex(0);
    } catch (error) {
      setError("Error Fetching Data");
      setToast({ error: true });
    }
    setGenerate(false);
  };

  const handleSelectChange = (name, value, setFurtherDetail, data_2) => {
    if (Products.includes(value)) {
      if (value === "SWG") {
        setAdnoc(false);
      } else {
        setAdnoc(true);
      }
    }
    console.log(name + " " + value);

    setFurtherDetail({
      ...data_2,
      [name]: value,
    });

    setFormdata({
      ...formdata,
      [name]: value,
    });
  };

  const columns = [
    {
      title: "Sl.No",
      dataIndex: "slNo",
      key: "slNo",
      className: "",
    },
    {
      title: "Product Description",
      dataIndex: "desp",
      key: "desp",
      className: "",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      className: "",
    },
    {
      title: "Unit Rate",
      dataIndex: "rate",
      key: "rate",
      className: "",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      className: "",
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
      className: "",
    },
    {
      title: "Quote",
      dataIndex: "quote",
      key: "quote",
      className: "",
    },
  ];

  const currency = ["USD", "AED", "OMR"];

  // Calculate totals
  const Tcost = data_1.reduce(
    (total, item) => total + parseFloat(item.quote.split(" ")[0]),
    0
  );
  const tax = Tcost * (tableData.local_tax / 100);
  const ftax = Tcost + tax;

  const summaryData = [
    {
      key: "Tcost",
      margin: <span className="quote-text"> Total Cost</span>,
      quote: (
        <span className="quote-text">{`${Tcost.toFixed(2)} ${
          tableData.currency
        }`}</span>
      ),
    },
    {
      key: "tax",
      margin: <span className="quote-text">Tax Value</span>,
      quote: (
        <span className="quote-text">{`${tax.toFixed(2)} ${
          tableData.currency
        }`}</span>
      ),
    },
    {
      key: "ftax",
      margin: (
        <span className="quote-text" style={{ fontWeight: "600" }}>
          Total Cost Including Tax
        </span>
      ),
      quote: (
        <span
          className="quote-text"
          style={{ fontWeight: "600" }}
        >{`${ftax.toFixed(2)} ${tableData.currency}`}</span>
      ),
    },
  ];

  const companyName = React.useMemo(
    () => ({
      company: [company, manufacturing, factory],
      branch: ["Abu Dhabi", "Dubai", "Oman"],
      location: ["Abu Dhabi, UAE", "Dubai, UAE", "Sohar, Oman"],
    }),
    []
  );

  const imgHandler = (index) => {
    // Mapping indexes to branches and gasket options
    const branchMapping = {
      0: { branch: "Abu Dhabi" },
      1: { branch: "Dubai" },
      2: { branch: "Oman" },
    };
    const selected = branchMapping[index];
    if (selected) {
      setSelectedIndex(index);
      setLocation(selected.branch);
    }
    if (selected.branch === "Oman") {
      setIsQuote(true);
    } else {
      setIsQuote(false);
    }
  };

  useEffect(() => {
    if (access?.location !== "all") {
      const filteredBranches = companyName.branch.filter(
        (branch) => access.location === branch
      );
      if (filteredBranches.length === 1) {
        const index = companyName.branch.findIndex(
          (branch) => branch === filteredBranches[0]
        );
        setSelectedIndex(index);
      }
    } else if (access?.location === "all") {
      setLocation(companyName?.branch[0]);
    }
  }, [access, companyName]);

  return (
    <section className="quote">
      <div className="main_container">
        <div className="title" style={{ paddingBottom: rem(9) }}>
          Quote Generator
        </div>
        <div className="flexbox quote-containers">
          <div className="company_logos">
            {companyName?.company?.map((company, index) => {
              const branch = companyName.branch[index];
              const location = companyName.location[index];
              // Check if the item matches the condition
              if (
                !(access?.location === "all" || access?.location === branch)
              ) {
                return null; // Skip this item
              }

              return (
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  key={index}
                >
                  <p
                    className={
                      selectedIndex === index
                        ? "branch_location_sty_active"
                        : "branch_location_sty"
                    }
                  >
                    {location}
                  </p>

                  <div
                    key={index}
                    className={`img_sec df flexColumn ${
                      selectedIndex === index ? "active" : ""
                    }`}
                    onClick={() => imgHandler(index)}
                  >
                    <button
                    // style={
                    //   index === companyName.company.length - 1
                    //     ? { opacity: 0.3, cursor: "no-drop" }
                    //     : {}
                    // }
                    >
                      <img src={company} alt="error" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flexBox Quote-input">
            {(access?.location === "Oman" || isQuote) && (
              <div style={{ width: rem(264) }}>
                <SelectSearch
                  label="Quotation Type"
                  name="quotation"
                  Placeholder="Choose option"
                  options={quoteOptions.map((option) =>
                    option.replace(/_/g, " ")
                  )}
                  value={quotation}
                  onChange={(value) => setQuotation(value)}
                  // hasError={furtherError.quotation}
                  readOnly={generate}
                />
              </div>
            )}
            <div style={{ width: rem(264) }}>
              <SelectSearch
                label="Spira Products"
                name="item"
                Placeholder="Choose option"
                options={Products}
                value={formdata?.item}
                onChange={(value) =>
                  handleSelectChange("item", value, setFormdata, formdata)
                }
                hasError={furtherError.item_error}
                readOnly={add}
              />
            </div>
            {adnoc ? (
              <>
                <div style={{ width: rem(264) }}>
                  <InputField
                    label={"part_number"}
                    labelName={"Part Number"}
                    type={"text"}
                    placeholder={"Enter Part Number"}
                    fieldData={formdata.part_number}
                    setFormData={setFormdata}
                    maxLength={20}
                    hasError={furtherError.part_number_error}
                    // pattern={
                    //   ["Adnoc Fastners", "Adnoc Prices"].includes(
                    //     formdata.item
                    //   ) && "numberOnly"
                    // }
                    readOnly={add}
                  />
                </div>
                {/* <div style={{ width: rem(264) }}>
                  <InputField
                    label={"price"}
                    labelName={"Price"}
                    type={"text"}
                    placeholder={"Enter Price"}
                    fieldData={formdata.price}
                    setFormData={setFormdata}
                    maxLength={20}
                    hasError={furtherError.item_error}
                    pattern={"numberWithFloat"}
                    readOnly={add}
                  />
                </div> */}
              </>
            ) : (
              <>
                <div style={{ width: rem(264) }}>
                  <SelectSearch
                    label="Winding Material"
                    name="winding_material"
                    Placeholder="Choose option"
                    options={dataOptions?.winding_materials}
                    value={formdata.winding_material}
                    onChange={(value) =>
                      handleSelectChange(
                        "winding_material",
                        value,
                        setFormdata,
                        formdata
                      )
                    }
                    hasError={furtherError.winding_material_error}
                    readOnly={add}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <SelectSearch
                    label="Filler Material"
                    name="filler_material"
                    Placeholder="Choose option"
                    options={dataOptions?.filler_materials}
                    value={formdata.filler_material}
                    onChange={(value) =>
                      handleSelectChange(
                        "filler_material",
                        value,
                        setFormdata,
                        formdata
                      )
                    }
                    hasError={furtherError.filler_material_error}
                    readOnly={add}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <SelectSearch
                    label="Inner Ring Material"
                    name="inner_ring_material"
                    Placeholder="Choose option"
                    options={dataOptions?.inner_ring_materials}
                    value={formdata.inner_ring_material}
                    onChange={(value) =>
                      handleSelectChange(
                        "inner_ring_material",
                        value,
                        setFormdata,
                        formdata
                      )
                    }
                    hasError={furtherError.inner_ring_material_error}
                    readOnly={add}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <SelectSearch
                    label="Outer Ring Material"
                    name="outer_ring_material"
                    Placeholder="Choose option"
                    options={dataOptions?.outer_ring_materials}
                    value={formdata.outer_ring_material}
                    onChange={(value) =>
                      handleSelectChange(
                        "outer_ring_material",
                        value,
                        setFormdata,
                        formdata
                      )
                    }
                    hasError={furtherError.outer_ring_material_error}
                    readOnly={add}
                  />
                </div>
                <div style={{ width: rem(264) }}>
                  <SelectSearch
                    label="Rating"
                    name="rating"
                    Placeholder="Choose option"
                    options={dataOptions?.ratings}
                    value={formdata.rating ?? ""}
                    onChange={(value) =>
                      handleSelectChange("rating", value, setFormdata, formdata)
                    }
                    hasError={furtherError.rating_error}
                    readOnly={add}
                  />
                </div>
              </>
            )}
            <div style={{ width: rem(264) }}>
              <SelectSearch
                label="Size"
                name="material_size"
                Placeholder="Choose option"
                options={dataOptions?.material_sizes}
                value={formdata.material_size ?? ""}
                onChange={(value) =>
                  handleSelectChange(
                    "material_size",
                    value,
                    setFormdata,
                    formdata
                  )
                }
                hasError={furtherError.material_size_error}
                readOnly={add}
              />
            </div>
            <div style={{ width: rem(264) }}>
              <InputField
                label={"quantity"}
                labelName={"Quantity"}
                // type={"number"}
                placeholder={""}
                fieldData={tableData.quantity}
                setFormData={setTableData}
                // maxLength={10}
                hasError={furtherError.quantity_error}
                pattern={"numberOnly"}
                readOnly={add}
              />
            </div>{" "}
            <div style={{ width: rem(264) }}>
              <SelectSearch
                label="Currency"
                name="currency"
                Placeholder="Choose option"
                options={currency}
                value={tableData.currency}
                onChange={(value) =>
                  handleSelectChange("currency", value, setTableData, tableData)
                }
                hasError={furtherError.currency_error}
                readOnly={add}
              />
            </div>{" "}
            <div style={{ width: rem(264) }}>
              <InputField
                label={"local_tax"}
                labelName={"Local Tax ( In Percentage % )"}
                // type={"number"}
                placeholder={""}
                fieldData={tableData.local_tax}
                setFormData={setTableData}
                // maxLength={5}
                hasError={furtherError.local_tax_error}
                pattern={"numberWithFloat"}
                readOnly={add}
              />
            </div>{" "}
            <div style={{ width: rem(264) }}>
              <InputField
                label={"margin"}
                labelName={"Margin ( In Percentage % )"}
                // type={"number"}
                placeholder={""}
                fieldData={tableData.margin}
                setFormData={setTableData}
                // maxLength={20}
                hasError={furtherError.margin_error}
                pattern={"numberWithFloat"}
                readOnly={add}
              />
            </div>{" "}
          </div>
          <div className="quote-btn">
            <Button
              btnname={"Clear"}
              onclick={handleReset}
              br={4}
              bg={"#fff"}
              border={`${rem(1)} solid #1e6641`}
              cl={"#1e6641"}
              width={119}
              height={38}
              fs={15}
              fw={500}
            />

            <Button
              btnname={add ? "Adding..." : "Add Item"}
              onclick={handleQuote}
              disabled={add}
              width={119}
              height={38}
              fs={15}
              br={4}
              // width={100}
            />
          </div>
        </div>
        <div className="flexbox quote-containers">
          <div className="table-wrap quoted-table">
            <Table
              columns={columns}
              dataSource={quote && [...data_1, ...summaryData]}
              pagination={false}
              loading={{
                spinning: add,
                indicator: <div className="spinners"></div>,
              }}
            />
          </div>
          <div className="quote-bottom flexBox">
            <div style={{ width: rem(309) }}>
              <InputField
                label={"c_name"}
                labelName={"Client Org. Name"}
                type={"text"}
                placeholder={"Enter Client Org. Name"}
                fieldData={data_2.c_name}
                setFormData={setFurtherDetail}
                // maxLength={50}
                hasError={furtherError.c_name_error}
                readOnly={generate}
                pattern={"onlyAlpha"}
              />
            </div>
            <div style={{ width: rem(309) }}>
              <InputField
                label={"att"}
                labelName={"Attention to"}
                type={"text"}
                placeholder={"Enter Point of Contact"}
                fieldData={data_2.att}
                setFormData={setFurtherDetail}
                // maxLength={50}
                hasError={furtherError.att_error}
                readOnly={generate}
              />
            </div>
            <div style={{ width: rem(309) }}>
              <InputField
                label={"add"}
                labelName={"Client Org. Address"}
                type={"text"}
                placeholder={"Enter Client Org. Address"}
                fieldData={data_2.add}
                setFormData={setFurtherDetail}
                // maxLength={50}
                hasError={furtherError.add_error}
                readOnly={generate}
              />
            </div>{" "}
            <div style={{ width: rem(309) }}>
              <InputField
                label={"date"}
                labelName={"Date"}
                type={"date"}
                placeholder={""}
                fieldData={data_2.date}
                setFormData={setFurtherDetail}
                maxLength={maxDate}
                hasError={furtherError.date_error}
                readOnly={generate}
              />
            </div>{" "}
            <div style={{ width: rem(309) }}>
              <InputField
                label={"dely"}
                labelName={"Delivery Timelines"}
                type={"text"}
                placeholder={"Enter Delivery Timelines"}
                fieldData={data_2.dely}
                setFormData={setFurtherDetail}
                // maxLength={50}
                hasError={furtherError.dely_error}
                readOnly={generate}
              />
            </div>
            <div style={{ width: rem(309) }}>
              <InputField
                label={"ship"}
                labelName={"Mode of Shipment"}
                type={"text"}
                placeholder={"Enter Mode of Shipment"}
                fieldData={data_2.ship}
                setFormData={setFurtherDetail}
                // maxLength={50}
                hasError={furtherError.ship_error}
                readOnly={generate}
              />
            </div>
          </div>
          <div className="quote-btn">
            <Button
              btnname={generate ? "Generating..." : "Generate Quote"}
              onclick={handleGenerate}
              disabled={generate}
              height={38}
              fs={15}
              fw={500}
              br={4}
            />
          </div>
        </div>
      </div>

      <Modal
        className="feedback-pdf"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        {modalContent}
      </Modal>

      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
    </section>
  );
};

export default QuoteGenerator;
