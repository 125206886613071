import React, { createContext, useContext, useEffect, useState } from "react";
// import _http from "./Api/_http";
import { selectIsImageOn } from "../Redux/Reducers";
import { useSelector } from "react-redux";
import _http from "../Utils/Api/_http";
const UserImageContext = createContext();

export const useUserImage = () => useContext(UserImageContext);

export const UserImageProvider = ({ children }) => {
  const [userImage, setUserImage] = useState(null);
  const isImageOn = useSelector(selectIsImageOn);
  const [isImg, setIsImg] = useState(null);

  useEffect(() => {
    _http
      .get("/api/fetch-profile-image")
      .then((response) => {
        if (response.data.image_url) {
          const arrayBuffer = response.data.image_url;
          setUserImage(arrayBuffer);
        }
        setIsImg(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching user image: ", error);
      });

    // Clean up function to revoke the object URL
    return () => {
      if (userImage) {
        URL.revokeObjectURL(userImage);
      }
    };
  }, [isImageOn, userImage]);

  return (
    <UserImageContext.Provider value={{ userImage, isImg }}>
      {children}
    </UserImageContext.Provider>
  );
};
