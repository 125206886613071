import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./SalesHome.scss";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Filler,
  BarElement,
} from "chart.js";
import { Dropdown, Space, Menu } from "antd";
import _http from "../../../Utils/Api/_http";
import { rem } from "../../../Components/Rem_func";
import SnackBar from "../../../Components/SnackBar";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Filler
);
// Helper to get the start date for 6 months back

// Helper to get the month abbreviation (e.g., "JAN", "FEB")
const getMonthAbbreviation = (date) => {
  if (!(date instanceof Date) || isNaN(date)) {
    console.error("Invalid date provided:", date);
    return "Invalid Month";
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[date.getMonth()];
};

const SalesTracker = ({ access }) => {
  const [users, setUsers] = useState([]);
  const [branch, setBranch] = useState("");
  const [teamleads, setTeamleads] = useState("");
  const [userList, setUserList] = useState([]);
  const [graph, setgraph] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };
  const filteredTeamLeads = useMemo(() => {
    if (!branch)
      return userList.filter((user) => user.role_name === "Teamlead");

    return userList.filter(
      (user) => user.location === branch && user.role_name === "Teamlead"
    );
  }, [branch, userList]);
  // Fetch data logic
  const fetchData1 = useCallback(async () => {
    try {
      const response = await _http.get("/api/sales_tracker_homepage");
      setUsers(response?.data);
    } catch (error) {
      setToast({ error: true });
      setError("Error Fetching Sales Data");
    }
  }, []);

  const fetchData2 = useCallback(async () => {
    if (access?.role_name && access?.role_name !== "employee") {
      try {
        const response = await _http.get("/api/get_users");
        const userLists = response.data.Output.record.flat();
        setUserList(userLists.flat());
      } catch (error) {
        setToast({ error: true });
        setError("Error Fetching Users Data");
      }
    } else {
      return;
    }
  }, [access]);

  useEffect(() => {
    fetchData1();
    fetchData2();
  }, [fetchData2, fetchData1]);
  const filterUsersByBranch = (users, branch, teamLead) => {
    // If neither branch nor teamLead is selected, return all users
    if (!branch && !teamLead) return users;

    // Filter by branch if a branch is selected
    let filteredUsers = branch
      ? users.filter((user) => user.location === branch)
      : users;
    // console.log(filteredUsers.filter((user) => user.sales_person_name===));
    const filteredemployees = userList
      .filter((user) => user.reporting_to === teamLead)
      .map((name) => name.user);

    if (teamLead) {
      filteredUsers = filteredUsers.filter((user) =>
        filteredemployees.includes(user.sales_person_name)
      );
    }

    return filteredUsers;
  };

  const filteredUsers = filterUsersByBranch(users, branch, teamleads);
  // Function to filter data for the last six months and count quotes, orders, and order value
  const getLastTwelveMonthsData = () => {
    const today = new Date();

    const twelveMonthsAgo = new Date(today);
    twelveMonthsAgo.setMonth(today.getMonth() - 12);

    const TwentyfourMonthsAgo = new Date(today);
    TwentyfourMonthsAgo.setMonth(today.getMonth() - 24);

    // Initialize an object to hold the counts and total order value
    const monthlyData = {};
    let totalOrderValueAEDTwelvemonths = 0;
    let totalOrderValueUSDTwelvemonths = 0;
    let totalOrderValueOMRTwelvemonths = 0;
    let totalOrderValueAEDTwentyfourmonths = 0;
    let totalOrderValueUSDTwentyfourmonths = 0;
    let totalOrderValueOMRTwentyfourmonths = 0;
    // Filter users with client_email_time_utc within the last six months
    const filteredUsersByClientEmailTime = filteredUsers.filter((user) => {
      const clientEmailTimeUTC = new Date(user.client_email_time_utc);
      const clientEmailLocalTime = new Date(
        clientEmailTimeUTC.getTime() -
          clientEmailTimeUTC.getTimezoneOffset() * 60000
      );

      // Check if the client email time falls within the last six months
      return (
        clientEmailLocalTime >= twelveMonthsAgo && clientEmailLocalTime <= today
      );
    });

    const filteredUsersByClientEmailTimeby12 = filteredUsers.filter((user) => {
      const clientEmailTimeUTC = new Date(user.client_email_time_utc);
      const clientEmailLocalTime = new Date(
        clientEmailTimeUTC.getTime() -
          clientEmailTimeUTC.getTimezoneOffset() * 60000
      );

      // Check if the client email time falls within the last six months
      return (
        clientEmailLocalTime >= TwentyfourMonthsAgo &&
        clientEmailLocalTime <= twelveMonthsAgo
      );
    });
    // Initialize total counters for each currency

    filteredUsersByClientEmailTimeby12.forEach((user) => {
      const orderDateTime = new Date(user.client_email_time_utc);
      const orderValue = parseFloat(
        user.final_order_currency_resolved?.replace(/[^0-9.-]+/g, "")
      );
      const currency = user.currency; // Assuming this field holds the currency type

      // Check if order value is a valid number and the order date is within the last 12 months
      if (
        !isNaN(orderValue) &&
        orderDateTime >= TwentyfourMonthsAgo
        // orderDateTime <= today
      ) {
        // Add to the appropriate currency total
        if (currency === "AED") {
          totalOrderValueAEDTwentyfourmonths += orderValue;
        } else if (currency === "USD") {
          totalOrderValueUSDTwentyfourmonths += orderValue;
        } else if (currency === "OMR") {
          totalOrderValueOMRTwentyfourmonths += orderValue;
        }
      }
    });

    // Process each user in the filtered list
    filteredUsersByClientEmailTime.forEach((user) => {
      const clientEmailTime = new Date(user.client_email_time_utc);
      const monthKey = getMonthAbbreviation(clientEmailTime);

      // Initialize the data object for the month if it doesn't exist
      monthlyData[monthKey] = monthlyData[monthKey] || {
        totalCount: 0,
        pending: 0,
        success: 0,
        orders: 0,
        rejected: 0,
      };

      // Increment total count for client emails
      monthlyData[monthKey].totalCount += 1;

      // Increment count based on reminder_status
      if (user.reminder_status === "pending") {
        monthlyData[monthKey].pending += 1;
      } else if (user.reminder_status === "success") {
        monthlyData[monthKey].success += 1;
      } else if (user.reminder_status === "order_placed") {
        monthlyData[monthKey].orders += 1;
      } else if (user.reminder_status === "rejected") {
        monthlyData[monthKey].rejected += 1;
      }
      const orderDateTime = new Date(user.client_email_time_utc);
      const orderValue = parseFloat(
        user.final_order_currency_resolved?.replace(/[^0-9.-]+/g, "")
      );
      const currency = user.currency;

      if (!isNaN(orderValue)) {
        if (orderDateTime >= twelveMonthsAgo && orderDateTime <= today) {
          if (currency === "AED") {
            totalOrderValueAEDTwelvemonths += orderValue;
          } else if (currency === "USD") {
            totalOrderValueUSDTwelvemonths += orderValue;
          } else if (currency === "OMR") {
            totalOrderValueOMRTwelvemonths += orderValue;
          }
        }
      }
    });
    let percentageChangeAED = 0;

    if (totalOrderValueAEDTwentyfourmonths > 0) {
      percentageChangeAED =
        ((totalOrderValueAEDTwelvemonths - totalOrderValueAEDTwentyfourmonths) /
          totalOrderValueAEDTwentyfourmonths) *
        100;
    }

    let percentageChangeUSD = 0;
    if (totalOrderValueUSDTwentyfourmonths > 0) {
      percentageChangeUSD =
        ((totalOrderValueUSDTwelvemonths - totalOrderValueUSDTwentyfourmonths) /
          totalOrderValueUSDTwentyfourmonths) *
        100;
    }

    let percentageChangeOMR = 0;
    if (totalOrderValueOMRTwentyfourmonths > 0) {
      percentageChangeOMR =
        ((totalOrderValueOMRTwelvemonths - totalOrderValueOMRTwentyfourmonths) /
          totalOrderValueOMRTwentyfourmonths) *
        100;
    }
    const percentageChange = {
      percentageChangeAED,
      percentageChangeOMR,
      percentageChangeUSD,
    };
    const totalOrderValue = {
      totalOrderValueAEDTwelvemonths,
      totalOrderValueOMRTwelvemonths,
      totalOrderValueUSDTwelvemonths,
    };
    return { monthlyData, percentageChange, totalOrderValue };
  };

  const {
    monthlyData: filteredData,
    totalOrderValue,
    percentageChange,
  } = getLastTwelveMonthsData();

  // console.log(getLastTwelveMonthsData());

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B";
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M";
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K";
    } else {
      return num?.toString();
    }
  };
  // Month order array
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Generate last 12 months in "MMM" format
  const last12Months = [];
  for (let i = 11; i >= 0; i--) {
    const date = new Date(currentYear, currentMonthIndex - i, 1); // Move backwards month by month
    last12Months.push(date.toLocaleString("default", { month: "short" }));
  }
  // Sort the months and map to their respective counts
  const availableMonths = last12Months;

  const pendingData = availableMonths?.map(
    (month) => filteredData[month]?.pending || 0
  );

  const successData = availableMonths?.map(
    (month) => filteredData[month]?.success || 0
  );
  const orderPlacedData = availableMonths.map(
    (month) => filteredData[month]?.orders || 0
  );
  const rejectedData = availableMonths.map(
    (month) => filteredData[month]?.rejected || 0
  );
  const clientEmailsData = availableMonths.map(
    (month) => filteredData[month]?.totalCount || 0
  );
  // Calculate total sums for each data set
  // const totalPending = pendingData.reduce((sum, count) => sum + count, 0);
  // const totalSuccess = successData.reduce((sum, count) => sum + count, 0);
  // const totalOrderPlaced = orderPlacedData.reduce(
  //   (sum, count) => sum + count,
  //   0
  // );
  // const totalClientEmails = availableMonths.reduce(
  //   (sum, month) => sum + (filteredData[month]?.totalCount || 0),
  //   0
  // );

  // const awaiting_quote = (totalPending / totalClientEmails) * 100 || 0;
  // const awaiting_order = (totalSuccess / totalClientEmails) * 100 || 0;
  // const orderPlaced = (totalOrderPlaced / totalClientEmails) * 100 || 0;

  // const createGradient = (ctx, chartArea) => {
  //   const gradient = ctx.createLinearGradient(
  //     0,
  //     chartArea.top,
  //     0,
  //     chartArea.bottom
  //   );

  //   gradient.addColorStop(0.6, "#D9D9D9"); // Start color
  //   gradient.addColorStop(0.009, "#1E6641"); // 9.9% color

  //   return gradient;
  // };
  const data = {
    labels: availableMonths,
    datasets: [
      {
        type: "line",
        // label: "Sales",
        data: clientEmailsData || [0],
        fill: true,
        // backgroundColor: (context) => {
        //   const { chart } = context;
        //   const { ctx, chartArea } = chart;

        //   // Return the gradient only when chartArea is available
        //   if (!chartArea) {
        //     return null;
        //   }
        //   return createGradient(ctx, chartArea);
        // },
        borderColor: "#1E6641",
        borderWidth: 1,
        pointBorderColor: "#1E6641",
        pointBackgroundColor: "#1E6641",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#1E6641",
        pointRadius: 3, // Increase the size of the pointer
        pointHoverRadius: 3,
        order: 1,
        // Increase size on hover
      },
      {
        type: "line",
        // label: "Revenue",
        data: orderPlacedData || 0,
        // fill: true,
        backgroundColor: "#000",
        borderColor: "#97D3B5",
        borderWidth: 1,
        pointBorderColor: "#97D3B5",
        pointBackgroundColor: "#97D3B5",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#97D3B5",
        pointRadius: 3, // Increase the size of the pointer
        pointHoverRadius: 3, // Increase size on hover
      },
      {
        type: "line",
        // label: "Revenue",
        data: successData || 0,
        // fill: true,
        backgroundColor: "transparent",
        borderColor: "#FFBC10",
        borderWidth: 1,
        pointBorderColor: "#FFBC10",
        pointBackgroundColor: "#FFBC10",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#FFBC10",
        pointRadius: 3, // Increase the size of the pointer
        pointHoverRadius: 3, // Increase size on hover
      },
      {
        type: "line",
        // label: "Revenue",
        data: pendingData || 0,
        // fill: true,
        backgroundColor: "transparent",
        borderColor: "#EB5757",
        borderWidth: 1,
        pointBorderColor: "#EB5757",
        pointBackgroundColor: "#EB5757",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#EB5757",
        pointRadius: 3, // Increase the size of the pointer
        pointHoverRadius: 3, // Increase size on hover
      },
      {
        type: "line",
        // label: "Revenue",
        data: rejectedData || 0,
        // fill: true,
        backgroundColor: "transparent",
        borderColor: "#CB6A6A",
        borderWidth: 1,
        pointBorderColor: "#CB6A6A",
        pointBackgroundColor: "#CB6A6A",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#CB6A6A",
        pointRadius: 3, // Increase the size of the pointer
        pointHoverRadius: 3, // Increase size on hover
      },
    ],
  };
  const BarData = {
    labels: availableMonths,

    datasets: [
      // {
      //   yAxisID: "y",
      //   type: "bar",
      //   data: clientEmailsData || [0],
      //   backgroundColor: "#1E6641", // Dark green
      //   borderWidth: 1.5, // Border width
      //   borderColor: "#1E6641",
      //   barPercentage: 0.8, // Control the width of bars
      // },

      {
        type: "bar",
        data: pendingData || 0,
        backgroundColor: "#EB5757",
        barPercentage: 0.2,
        borderColor: "#EB5757",
        yAxisID: "y",
      },
      {
        type: "bar",
        data: successData || 0,
        backgroundColor: "#FFBC10",
        barPercentage: 0.2,
        borderColor: "#FFBC10",
        yAxisID: "y",
      },
      {
        type: "bar",
        data: orderPlacedData || 0,
        backgroundColor: "#97D3B5",
        barPercentage: 0.2,
        borderColor: "#97D3B5",
        yAxisID: "y",
      },
      {
        type: "bar",
        data: rejectedData || 0,
        backgroundColor: "#CB6A6A",
        barPercentage: 0.2,
        borderColor: "#CB6A6A",
        yAxisID: "y",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return ` ${context.raw}`;
          },
        },
      },
      legend: {
        display: false, // Remove the legend labels
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          maxTicksLimit: 7,
          display: true,
          color: "#808080",
          font: { size: 10 }, // Remove tick marks on the Y-axis
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      x: {
        grid: {
          display: true,
          // Remove grid lines on the X-axis
        },
        ticks: {
          color: "#808080",
          font: { size: 10 }, // Color of tick labels on the X-axis
        },
        border: {
          display: false, // Hide the x-axis line
        },
      },
    },
  };
  const BarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => ` ${context.raw}`,
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      y: {
        stacked: true,

        // beginAtZero: true,
        grid: {
          display: true, // Hide grid lines
        },
        ticks: {
          display: true,
          maxTicksLimit: 7,
          font: { size: 10 }, // Hide tick marks
        },
        border: {
          display: true, // Hide axis line
        },
      },
      x: {
        stacked: true,
        grid: {
          display: false, // Hide grid lines
        },
        ticks: {
          display: true,
          color: "#454545",
          font: { size: 10 }, // Color of x-axis labels
        },
        border: {
          display: false, // Hide axis line
        },
      },
    },
  };
  const handleSelectBranch = ({ key }) => {
    // If "All" is selected, clear the branch selection
    if (key === "All" || key === branch) {
      setBranch("");
      setTeamleads("");
    } else {
      setBranch(key);
      setTeamleads("");
    }
  };
  // Handle team lead selection
  const handleSelectTeamLead = ({ key }) => {
    if (key === teamleads) {
      setTeamleads("");
    } else {
      setTeamleads(key);
    }
  };
  // Define team lead menu dynamically based on the filtered team leads
  const teamLeadMenu = (
    <Menu
      onClick={handleSelectTeamLead}
      items={
        filteredTeamLeads.length > 0
          ? filteredTeamLeads.map((teamLead) => ({
              key: teamLead.user,
              label: teamLead.user,
            }))
          : [{ key: "none", label: "No TeamLeads", disabled: true }]
      }
    />
  );

  // Define your branch menu items with Ant Design's Menu component
  const branchMenu = (
    <Menu
      onClick={handleSelectBranch}
      items={[
        { key: "All", label: "All" },
        { key: "Dubai", label: "Dubai" },
        { key: "Abu Dhabi", label: "Abu Dhabi" },
        { key: "Oman", label: "Oman" },
      ]}
    />
  );

  const salesData = [
    {
      currency: "AED",
      // value: "3753.4k",
      value: totalOrderValue?.totalOrderValueAEDTwelvemonths,
      percentageChange: percentageChange?.percentageChangeAED,
      // percentageChange: -10, //to show demo if the value is negative
    },
    {
      currency: "USD",
      // value: "37.5k",
      value: totalOrderValue?.totalOrderValueUSDTwelvemonths,
      percentageChange: percentageChange?.percentageChangeUSD,
    },
    {
      currency: "OMR",
      // value: "3753.5k",
      value: totalOrderValue?.totalOrderValueOMRTwelvemonths,
      percentageChange: percentageChange?.percentageChangeOMR,
    },
  ];

  return (
    <div className="sales-home">
      <div className="df space-between" style={{ paddingBottom: rem(10) }}>
        <span className="userM" style={{ paddingLeft: rem(7) }}>
          Sales Tracker
        </span>
        <div className="df" style={{ gap: rem(19) }}>
          {["admin", "Manager"].includes(access?.role_name) && (
            <div className="branch">
              <Dropdown overlay={teamLeadMenu}>
                <Space>
                  <span className="branch"> {teamleads || "Teamlead"}</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(7)}
                    height={rem(7)}
                    viewBox="0 0 6 4"
                    fill="none"
                  >
                    <path d="M0 0.5H6L2.99996 3.49992L0 0.5Z" fill="#666666" />
                  </svg>
                </Space>
              </Dropdown>
            </div>
          )}
          {access.role_name === "admin" && (
            <div className="branch">
              <Dropdown overlay={branchMenu}>
                <Space>
                  <span className="branch">{branch || "Branch"}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(7)}
                    height={rem(7)}
                    viewBox="0 0 6 4"
                    fill="none"
                  >
                    <path d="M0 0.5H6L2.99996 3.49992L0 0.5Z" fill="#666666" />
                  </svg>
                </Space>
              </Dropdown>
            </div>
          )}
          <div
            className="bar-icon"
            onClick={() => {
              setgraph(!graph);
            }}
          >
            {!graph ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(24)}
                height={rem(24)}
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_931_4705)">
                  <path
                    d="M6.4 9.2H6.6C7.37 9.2 8 9.83 8 10.6V17.6C8 18.37 7.37 19 6.6 19H6.4C5.63 19 5 18.37 5 17.6V10.6C5 9.83 5.63 9.2 6.4 9.2ZM12 5C12.77 5 13.4 5.63 13.4 6.4V17.6C13.4 18.37 12.77 19 12 19C11.23 19 10.6 18.37 10.6 17.6V6.4C10.6 5.63 11.23 5 12 5ZM17.6 13C18.37 13 19 13.63 19 14.4V17.6C19 18.37 18.37 19 17.6 19C16.83 19 16.2 18.37 16.2 17.6V14.4C16.2 13.63 16.83 13 17.6 13Z"
                    fill="#1E6641"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_931_4705">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(24)}
                height={rem(24)}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 16.5L9 10L13 16L21 6.5"
                  stroke="#1E6641"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      </div>

      <div className="df space-between">
        <div
          style={{
            // width: rem(564),
            width: "72%",
            height: rem(230),
          }}
        >
          {graph ? (
            <Bar data={BarData} options={BarOptions} height={200} />
          ) : (
            <Line data={data} options={options} height={200} />
          )}
        </div>

        <div className="sales_data">
          <p className="sales_title">Total Sales</p>
          <div className="sales_alignData">
            {salesData.map((data, index) => (
              <div className="sales_stat df space-between" key={index}>
                <div className="df gapSty">
                  <p
                    className="sales_name"
                    style={{
                      color: data?.percentageChange < 0 ? "#E56553" : "#1e6641",
                    }}
                  >
                    {data.currency}
                    {/* {data.currency} */}
                  </p>
                  <p className="sales_val">{formatNumber(data.value)}</p>
                </div>
                <div className="df rightGapSty">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(6)}
                    height={rem(10)}
                    viewBox="0 0 6 10"
                    fill="none"
                    style={{
                      rotate: data?.percentageChange < 0 ? "180deg" : "",
                    }}
                  >
                    {/* Static arrow path */}
                    <path
                      d="M2.73027 0.984939L0.105267 3.60994C0.0369576 3.68066 -0.000840465 3.77539 1.39464e-05 3.87371C0.000868358 3.97204 0.0403068 4.06609 0.109835 4.13562C0.179363 4.20515 0.273417 4.24459 0.371741 4.24544C0.470065 4.24629 0.564791 4.2085 0.635517 4.14019L2.62039 2.15531L2.62039 8.75006C2.62039 8.84952 2.6599 8.9449 2.73023 9.01523C2.80055 9.08555 2.89594 9.12506 2.99539 9.12506C3.09485 9.12506 3.19023 9.08555 3.26056 9.01523C3.33088 8.9449 3.37039 8.84952 3.37039 8.75006L3.37039 2.15531L5.35527 4.14019C5.38986 4.176 5.43124 4.20457 5.47699 4.22423C5.52274 4.24388 5.57195 4.25422 5.62174 4.25466C5.67153 4.25509 5.72091 4.2456 5.767 4.22675C5.81308 4.20789 5.85495 4.18005 5.89016 4.14484C5.92537 4.10963 5.95322 4.06776 5.97207 4.02167C5.99093 3.97558 6.00042 3.9262 5.99998 3.87641C5.99955 3.82662 5.98921 3.77741 5.96955 3.73166C5.9499 3.68591 5.92133 3.64453 5.88552 3.60994L3.26052 0.984938C3.19019 0.914636 3.09483 0.875144 2.99539 0.875144C2.89595 0.875144 2.80059 0.914636 2.73027 0.984939Z"
                      fill={data?.percentageChange < 0 ? "#E56553" : "#8FDBB5"}
                    />
                  </svg>
                  <p
                    className="sales_per_change"
                    style={{
                      color: data?.percentageChange < 0 ? "#E56553" : "#1e6641",
                    }}
                  >
                    {data?.percentageChange < 0
                      ? `-${parseFloat(data.percentageChange)?.toFixed(2)}%`
                      : `+${parseFloat(data.percentageChange)?.toFixed(2)}%`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="df justify-center">
        <div className="flexBox tracker">
          {!graph && (
            <div className="com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(8)}
                height={rem(8)}
                viewBox="0 0 8 8"
                fill="none"
                style={{ marginTop: rem(5), marginRight: rem(8) }}
              >
                <circle cx="4" cy="4" r="4" fill="#1E6641" />
              </svg>
              <div>
                <p className="enq">Enquiries</p>
                {/* <p className="per">100%</p> */}
              </div>
            </div>
          )}
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#EB5757" />
            </svg>
            <div>
              <p className="enq">Awaiting Quote</p>
              {/* <p className="per">{`${awaiting_quote?.toFixed(2)}%` || 0}</p> */}
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#FFBC10" />
            </svg>
            <div>
              <p className="enq">Awaiting Order</p>
              {/* <p className="per">{`${awaiting_order?.toFixed(2)}%`}</p> */}
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#63C89B" />
            </svg>
            <div>
              <p className="enq">Order Placed</p>
              {/* <p className="per">{`${orderPlaced?.toFixed(2)}%`}</p> */}
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#CB6A6A" />
            </svg>
            <div>
              <p className="enq">Rejected Order</p>
              {/* <p className="per">{`${orderPlaced?.toFixed(2)}%`}</p> */}
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast?.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast?.submit}
      />
    </div>
  );
};

export default SalesTracker;
