import { Modal, Tooltip } from "antd";
import React from "react";
import { rem } from "../../../../Components/Rem_func";
import QuizShare from "./QuizSharePopup";
import share from "./../../../../Assets/Quiz/Share.svg";
import download from "./../../../../Assets/Quiz/Download.svg";

const Qr_PopUp = ({ png, poolname, open, handleClose }) => {
  return (
    <Modal
      width={rem(669)}
      height={rem(512)}
      centered
      className="edit-popup"
      open={open}
      onCancel={handleClose}
    >
      <div className="qrmodal">
        <h1>{poolname}</h1>
        <div className="qrimg">
          <img
            src={png}
            alt="QR Code"
            className="qrpngsclass1"
            draggable="false"
          />
        </div>

        <div className="quizsocialModal">
          <Tooltip title={<QuizShare />} placement={"bottom"} color="#fff">
            <img src={share} alt="" className="sharepng" draggable="false" />
          </Tooltip>
          <a href={png} download={`QRCode_${poolname}.png`}>
            <img
              src={download}
              alt=""
              draggable="false"
              className="downloadpng"
            />
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default Qr_PopUp;
