import React, { useEffect, useState, useMemo } from "react";
import { Space } from "antd";
import "../../SalesTracker/table.scss";
import _http from "../../../Utils/Api/_http";
import UserCards from "../UserCards/UserCards";
import searchIcon from "../../../Assets/UserIcon/usersearchicon.svg";
import down from "../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../Assets/EnquiresIcon/filteron.svg";
import green_down from "../../../Assets/EnquiresIcon/green_down.svg";
import "./user.scss";
import Button from "../../../Components/Button/Button";
import FilterUser from "../FilterUser/FilterUser";
import UserActions from "../UserPopup/UserActions";
import SnackBar from "../../../Components/SnackBar";
import { rem } from "../../../Components/Rem_func";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
import DraggableTable from "../../../Components/TableDrag_func";
import FilterSlider from "../../../Components/FilterSlider/FilterSlider";
import SizeChanger from "../../../Components/PageSizeChanger/SizeChanger";
const User = () => {
  // State variables
  const user_data = useSelector(selectUserData);
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const roles = user_data?.role_name;
  const names = user_data?.name;
  const [tableData, setTableData] = useState(userList);
  const [openfilter, setOpenfilter] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [filteredData, setFilteredData] = useState({
    user: "",
    role_name: "",
    reporting_to: "",
    created_date_from: "",
    created_date_to: "",
    last_login_from: "",
    last_login_to: "",
  });
  const [count, setCount] = useState("50");
  const PageCount = ["10", "20", "50", "100"];
  const [filterOn, setFilterOn] = useState(false);
  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  // state to update and delete
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [saveBtn, setSaveBtn] = useState(false);

  // Function to handle toast closing
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    const pattern = /^[A-Za-z\s]*$/;

    if (pattern.test(value)) {
      const normalizedValue = value.replace(/\s+/g, " ");
      setFilteredData({ ...filteredData, user: normalizedValue });
    }
  };

  // Function to handle user deletion
  const handleDelete = async (user, email, index) => {
    setDeleting(true);
    try {
      const formData = new FormData();
      formData.append("operation", "delete");
      formData.append("username", user);
      formData.append("email", email);
      await _http.post(`user-management`, formData);
      const updatedData = [...userList];
      updatedData.splice(index, 1);
      setUserList(updatedData);
      console.log(updatedData);

      handleClose();
      fetchData();
      setError("Successfully deleted");
      setToast({ submit: true });
    } catch (error) {
      console.log(error);
      setToast({ error: true });
      setError(error.message);
    }
    setDeleting(false);
  };
  // Function to handle user update
  const handleUpdate = async (updatedData) => {
    if (!updatedData) {
      setToast({ error: true });
      setError("Please fill all the fields");
      return;
    }
    updatedData.email = updatedData?.email?.toLowerCase();
    updatedData.operation = "update";
    // updatedData.user = updatedData?.data?.user?.toLowerCase();

    setUpdating(true);
    try {
      await _http.post(`user-management`, updatedData, {});
      fetchData();
      setToast({ submit: true });
      setError("Successfully updated");
    } catch (error) {
      if (error.request.status === 403) {
        setToast({ error: true });
        setError("Only managers can update");
      } else {
        setToast({ error: true });
        setError(error.message);
      }
    }
    handleClose();
    setUpdating(false);
  };
  // Effect to update table data when userList changes
  useEffect(() => {
    setTableData(userList);
  }, [userList, tableData]);
  // Memoized filter for user list
  const filteredUsers = useMemo(() => {
    const convertToLocalDate = (utcDateString) => {
      if (!utcDateString) return null;
      const utcDate = new Date(utcDateString);
      return new Date(
        utcDate.getFullYear(),
        utcDate.getMonth(),
        utcDate.getDate(),
        utcDate.getHours(),
        utcDate.getMinutes(),
        utcDate.getSeconds()
      );
    };
    return (userList || []).filter(
      (user) =>
        (user.user || "")
          .toLowerCase()
          .includes((filteredData.user.trim() || "").toLowerCase()) &&
        (user.role_name || "")
          .toLowerCase()
          .includes((filteredData.role_name || "").toLowerCase()) &&
        (user.reporting_to || "")
          .toLowerCase()
          .includes((filteredData.reporting_to || "").toLowerCase()) &&
        // Check if created_date is within the specified range
        (filteredData.created_date_from
          ? convertToLocalDate(user.created_date) >=
            new Date(filteredData.created_date_from).setHours(0, 0, 0, 0)
          : true) &&
        (filteredData.created_date_to
          ? convertToLocalDate(user.created_date) <=
            new Date(filteredData.created_date_to).setHours(23, 59, 59, 999)
          : true) &&
        (filteredData.last_login_from
          ? convertToLocalDate(user?.last_login) >=
            new Date(filteredData.last_login_from).setHours(0, 0, 0, 0)
          : true) &&
        (filteredData.last_login_to
          ? convertToLocalDate(user?.last_login) <=
            new Date(filteredData.last_login_to).setHours(23, 59, 59, 999)
          : true)
    );
  }, [userList, filteredData]);

  // Function to fetch user data
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await _http.get("/api/get_users");
      const userLists = response.data.Output.record.flat();
      setUserList(userLists.flat());
    } catch (error) {
      setToast({ error: true });
      setError("Error fetching data:", error.message);
    }
    setLoading(false);
  };

  const Submit = async (formData, setFormData) => {
    setSaveBtn(true);
    try {
      formData.username = formData.user.trim().toLowerCase();
      formData.email = formData.email.toLowerCase();
      // Making a POST request to register the user
      await _http.post("/Register", formData);

      setToast({ submit: true });
      setError("Successfully registered!");

      setFormData({
        user: "",
        password: "",
        email: "",
        role_name: "",
        reporting_to: "",
      });

      // Fetching data after successful registration
      fetchData();

      handleClose();
    } catch (error) {
      // Handling errors and displaying appropriate messages
      setToast({ error: true });
      setError(error.response.data || "Server error please try again");
    }
    setSaveBtn(false);
  };

  // Effect to fetch user data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // to render the datas in the table
  const renderEmptyOrValue = (text, TT, cln, Type) => {
    const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    // Check if text matches the date-time format
    const isValidDateTime = dateTimePattern.test(text);

    // Return 'DataNR' if text is falsy
    if (!text) {
      return <span className="empty">--</span>;
    }

    // Handle date or time types
    if (Type === "date" || Type === "time") {
      if (isValidDateTime) {
        const [datePart, timePart] = new Date(text)
          ?.toLocaleString()
          ?.split(", ");
        return <span>{Type === "date" ? datePart : timePart}</span>;
      } else {
        return <span>MismatchF</span>; // Return this if the format does not match
      }
    }

    // Default case: Return the text with optional class and capitalization
    return (
      <span
        className={`${cln} w100 overflowData`}
        style={{ textTransform: TT ? "capitalize" : "none" }}
      >
        {text}
      </span>
    );
  };
  // Table columns configuration
  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      className: "tl",

      render: (text, record) =>
        renderEmptyOrValue(text.toLowerCase(), false, "client-name"),
      sorter: (a, b) => a.user.localeCompare(b.user),
      width: rem(164),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "tl",

      render: (text, record) => renderEmptyOrValue(text, false, "email"),
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: rem(164),
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      className: "",
      render: (text) => (
        <span
          style={{ textTransform: "capitalize" }}
          className={`role ${
            (text.toLowerCase() === "manager" && "manager") ||
            (text.toLowerCase() === "teamlead" && "teamlead") ||
            (text.toLowerCase() === "employee" && "employee") ||
            (text.toLowerCase() === "admin" && "admin")
          }`}
        >
          {text}
        </span>
      ),
      // sorter: (a, b) => a.role_name.localeCompare(b.role_name),

      width: rem(134),
    },
    {
      title: "Status",
      dataIndex: "is_active_user",
      key: "is_active_user",
      className: "",

      render: (text) => (
        <span className="">{text ? "Active" : "Inactive"}</span>
      ),
      sorter: (a, b) =>
        a.is_active_user === b.is_active_user ? 0 : a.is_active_user ? -1 : 1,
      width: rem(104),
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      className: "",
      sorter: (a, b) => new Date(a.last_login) - new Date(b.last_login),
      render: (text) => {
        if (text) {
          const date = new Date(text);
          const formattedDateTime = `${date.getDate()}-${(date.getMonth() + 1)
            .toString()
            .padStart(
              2,
              "0"
            )}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
          return <p>{formattedDateTime}</p>;
        }
        return <span className="empty">--</span>;
      },
      width: rem(134), // Adjust the width to accommodate the time
    },
    {
      title: "Joined Date",
      dataIndex: "created_date",
      key: "created_date",
      className: "",
      sorter: (a, b) => new Date(a.created_date) - new Date(b.created_date),

      render: (text) => {
        if (text) {
          const date = new Date(text);
          const formattedDateTime = `${date.getDate()}-${(date.getMonth() + 1)
            .toString()
            .padStart(
              2,
              "0"
            )}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
          return <p>{formattedDateTime}</p>;
        }
        return <span className="empty">--</span>;
      },
      width: rem(134), // Adjust the width to accommodate the time
    },
  ];

  // Function to handle modal close
  const handleClose = () => setOpen(false);

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to handle filter popover visibility change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  const handleOpen = (record) => {
    setSelectedUser(record);
    setOpen(true);
  };

  const handleSaveToast = (submit, error, message) => {
    setToast({ Submit: submit, error: error });
    setError(message);
  };

  // Function to check if any filters are applied
  const checkIfFiltersAreApplied = (filters) => {
    return Object.values(filters).some((value) => value !== "");
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);

  return (
    <section>
      <div className="container1" style={{ paddingTop: rem(47.5) }}>
        <div className="container-head df">
          <div className="df" style={{ gap: rem(8) }}>
            <p className="title">User Management</p>
            <div className="total-count-box">{filteredUsers?.length || 0}</div>
          </div>
          <div className="user-search df">
            <img src={searchIcon} alt="icon" />
            <input
              // type="text"
              placeholder="Search Users"
              value={filteredData?.user || ""}
              onChange={handleInputChange}
              name="user"
            />
          </div>
        </div>
        <div className="container-head2 df">
          <SizeChanger count={count} items={items} />
          <UserCards userList={userList} />

          <div className="df add-user">
            <Space>
              <span
                className="filter-head"
                data-tooltip-id="tooltip-arrow"
                data-tooltip-content="Filter"
                onClick={() => setOpenfilter(true)}
              >
                <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                <img src={filterOn ? green_down : down} alt="icon" />
              </span>
            </Space>
            {/* </Popover> */}
            <Button
              btnname={
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(14)}
                    height={rem(14)}
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M4.28571 5.71429H0V4.28571H4.28571V0H5.71429V4.28571H10V5.71429H5.71429V10H4.28571V5.71429Z"
                      fill="white"
                    />
                  </svg>{" "}
                  New User
                </>
              }
              onclick={() => {
                setSelectedUser("");
                // setNewUser(true);
                setOpen(true);
              }}
              width={143}
              br={5}
              height={36}
              fs={15}
              fw={600}
            />
          </div>
        </div>

        <DraggableTable
          handleOpen={handleOpen}
          filteredUsers={filteredUsers}
          loading={loading}
          baseColumns={columns}
          drag={false}
          scroll={280}
          defaultPageSize={count}
        />

        {open && (
          <UserActions
            open={open}
            handleClose={handleClose}
            users={selectedUser}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            roles={roles}
            names={names}
            userList={userList}
            deletingFun={deleting}
            updatingFun={updating}
            saveBtn={saveBtn}
            Submit={Submit}
            handleSaveToast={handleSaveToast}
          />
        )}
        {openfilter && (
          <FilterSlider
            filterOn={filterOn}
            setOpenfilter={setOpenfilter}
            width={rem(608)}
            openfilter={openfilter}
            handleOpenChange={handleOpenChange}
            hide={hide}
            DashBoardFilter={FilterUser}
            userList={userList}
            setFilteredData={setFilteredData}
            filteredData={filteredData}
            roles={roles}
            names={names}
          />
        )}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.submit}
      />
    </section>
  );
};

export default User;
